import axios from "axios";
import { CLIENT_PORTAL, MYM_WEB } from "../constants/rbuConstants";
import { getAllRequiredHeaders } from "./appUtils";


const client = axios.create({
	headers: {
		'content-type': 'application/json',
		accept: 'application/json',
	},
})

// Update base URL interceptor
client.interceptors.request.use(
	(config) => {
		switch(config.headers.rbu){
			case MYM_WEB:
				config['baseURL'] = process.env.REACT_APP_MYM_API;
				break;
			case CLIENT_PORTAL:
				config['baseURL'] = process.env.REACT_APP_PORTAL_URL;
				break;
			default:
				config['baseURL'] = "";
				break;
		}

		delete config.headers['rbu']

		return config;
	}, 
	(error) => {
		console.log('error :>> ', error);
		return Promise.reject(error);
	}
)

// Error handling interceptor
client.interceptors.response.use(
	(response) => {
		return response;
	}, 
	(error) => {
		if (error.response.status > 400 && !error.config.url.includes(`api/logout`)) {
			localStorage.clear();
			window.location.href = `/`;
		}
	}
)

export const getRequest = async ({ url, rbu = "" }) => {
	return new Promise( async (resolve, reject) => {
		try {
			const res = await client.get(`${url}`, {
				headers: { ...getAllRequiredHeaders({ url, rbu }) },
			});
			const { data, status } = res;
			// if (status === 204) {
			// 	return {
			// 		response: {
			// 			success: false,
			// 			message: 'Something Went Wrong!',
			// 			data: [],
			// 		},
			// 		error: true,
			// 	};
			// }
			return resolve(res);
		} catch (error) {
			return { response: { isLogout: true }, error: true };
		}
	})
};

export const postRequest = async ({ url, payload = {}, rbu = "" }) => {
	return new Promise( async (resolve, reject) => {
		try {
			const res = await client.post(`${url}`, payload, {
				headers: { ...getAllRequiredHeaders({ url, rbu }) },
			});
	
			const { data, status } = res;
			// if (status === 204) {
			// 	return {
			// 		response: {
			// 			success: false,
			// 			message: 'Something Went Wrong!',
			// 			data: [],
			// 		},
			// 		error: true,
			// 	};
			// }
			return resolve(res);
		} catch (error) {
			console.log('error :>> ', error);
			// return reject(error);
			// return { response: { isLogout: true }, error: true };
		}

	})
};

export const putRequest = async ({ url, payload = {}, rbu = "" }) => {
	return new Promise( async (resolve, reject) => {
		try {
			const res = await client.put(`${url}`, payload, {
				headers: { ...getAllRequiredHeaders({ url, rbu }) },
			});
			const { data, status } = res;
			// if (status === 204) {
			// 	return {
			// 		response: {
			// 			success: false,
			// 			message: 'Something Went Wrong!',
			// 			data: [],
			// 		},
			// 		error: true,
			// 	};
			// }
			return resolve(res);
		} catch (error) {
			console.log('error :>> ', error);
		}
	})
};

export const patchRequest = async ({ url, payload = {}, rbu }) => {
	try {
		const res = await client.patch(`${url}`, payload, {
			headers: { ...getAllRequiredHeaders({ rbu }) },
		});
		const { data, status } = res;
		if (status === 204) {
			return {
				response: {
					success: false,
					message: 'Something Went Wrong!',
					data: [],
				},
				error: true,
			};
		}
		return { response: data, error: false };
	} catch (error) {
		return { response: { isLogout: true }, error: true };
	}
};

export const deleteRequest = async ({ url, rbu }) => {
	return new Promise( async (resolve, reject) => {
		try {
			const res = await client.delete(`${url}`, {
				headers: { ...getAllRequiredHeaders({ url, rbu }) },
			});
			const { data, status } = res;
			// if (status === 204) {
			// 	return {
			// 		response: {
			// 			success: false,
			// 			message: 'Something Went Wrong!',
			// 			data: [],
			// 		},
			// 		error: true,
			// 	};
			// }
			return resolve(res);;
		} catch (error) {
			console.log('error :>> ', error);
		}
	})
};