import React from "react";
import { Link, IndexLink } from "react-router";

import { Collapse } from "reactstrap";

// icons
import IconDashboard from "react-icons/lib/md/dashboard";
import IconDown from "react-icons/lib/md/chevron-right";
import ScrollArea from "react-scrollbar";

import IconReport from "react-icons/lib/md/insert-chart";
import IconQuestions from "react-icons/lib/md/view-list";

import appicon from "../../../assets/images/caLogo.png";

import "./style.css";
import { getRequest } from "../../../utils/apiUtils";
var axios = require("axios");

const NavHead = (props) => (
  <header className="nav-head">
    <Link to="">
      <strong className="h4 text-uppercase">
        <img src={appicon} width="42" alt="logo" /> Majestic
      </strong>
    </Link>
    <div
      className={`toggle-dot ${props.mini ? "active" : ""}`}
      onClick={props.toggleNav}
    ></div>
  </header>
);

class NavList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      role_name: "",
    };
  }

  componentDidMount() {
    var that = this;
    getRequest({ url: "api/get_user_data/"})
      .then(function (response) {
        if (response.data != "") {
          // const queryParams = new URLSearchParams(window.location.search);
          // const id = queryParams.get("token");
          // console.log(id, "id");
          // var ck_name = "connect.sid";
          // document.cookie = ck_name + "=" + id + "; path=/";

          that.setState({
            role_name: response.data.role_name,
          });
        }
        //console.log(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
          } else {
            console.log(error);
          }
        } else {
          console.log(error);
        }
      });
  }

  handleClick = (index, e) => {
    let c = e.currentTarget.className;
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      selected: c.indexOf("selected") >= 0 ? "" : index,
    });
  };
  handleOpen = (index, e) => {
    e.stopPropagation();
    this.setState({
      selected: index,
    });
  };

  render() {
    return (
      <ScrollArea
        className="nav-list-container"
        horizontal={false}
        verticalScrollbarStyle={{ width: "4px", marginLeft: "10px" }}
      >
        <ul className="list-unstyled nav-list clearfix">
          {/* <li><div className="nav-list-title">Views</div></li> */}
          <li
            onClick={this.handleClick.bind(this, 0)}
            className={this.state.selected === 0 ? "selected" : ""}
          >
            <IndexLink to="/dashboard" activeClassName="active">
              <IconDashboard
                size="18"
                color="#2962FF"
                className="icon-dashboard"
              />
              <span className="name">Dashboard</span>
            </IndexLink>
          </li>

          <li
            onClick={this.handleClick.bind(this, 1)}
            className={this.state.selected === 1 ? "selected" : ""}
            hidden={this.state.role_name == "client" ? true : false}
          >
            <Link to="/facilitysetup" activeClassName="active">
              <IconQuestions size="18" />
              <span className="name">Facility Setup</span>
            </Link>
          </li>

          <li
            onClick={this.handleClick.bind(this, 2)}
            className={this.state.selected === 2 ? "selected" : ""}
            hidden={this.state.role_name == "client" ? true : false}
          >
            <Link to="" activeClassName="active">
              <IconQuestions size="18" />
              <span className="name">Questions</span>
              <IconDown size="14" className="icon-down" />
            </Link>
            <Collapse
              style={{ marginLeft: "0px" }}
              isOpen={this.state.selected === 2 ? true : false}
              onClick={this.handleOpen.bind(this, 2)}
            >
              <ul className="inner-drop list-unstyled">
                <li>
                  <Link to="/state_questions" activeClassName="active">
                    State Wise Added Questions
                  </Link>
                </li>
                <li>
                  <Link to="/facility_questions" activeClassName="active">
                    Facility Wise Added Questions
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>

          <li
            onClick={this.handleClick.bind(this, 7)}
            className={this.state.selected === 7 ? "selected" : ""}
            hidden={this.state.role_name == "client" ? true : false}
          >
            <Link to="" activeClassName="active">
              <IconQuestions size="18" />
              <span className="name">Observation</span>
              <IconDown size="14" className="icon-down" />
            </Link>
            <Collapse
              style={{ marginLeft: "0px" }}
              isOpen={this.state.selected === 7 ? true : false}
              onClick={this.handleOpen.bind(this, 7)}
            >
              <ul className="inner-drop list-unstyled">
                <li>
                  <Link to="/observation_questions" activeClassName="active">
                    State Wise Observation Questions
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>

          <li
            onClick={this.handleClick.bind(this, 6)}
            className={this.state.selected === 6 ? "selected" : ""}
          >
            <Link to="/reports" activeClassName="active">
              <IconReport size="18" />
              <span className="name">Reports</span>
            </Link>
          </li>

          <li onClick={this.handleClick.bind(this, 3)}>
            <Link to="/correctivereport" activeClassName="active">
              <IconQuestions size="18" />
              <span className="name">Rectification</span>
              <span className="name">Reports</span>
            </Link>
          </li>

          {/* <li onClick={this.handleClick.bind(this, 4)}>
            <Link to="/auditreport" activeClassName="active">
              <IconReport size="18" />
              <span className="name">Audit Report</span>
            </Link>
          </li> */}

          <li
            onClick={this.handleClick.bind(this, 5)}
            className={this.state.selected === 5 ? "selected" : ""}
            hidden={this.state.role_name == "client" ? true : false}
          >
            <Link to="" activeClassName="active">
              <IconQuestions size="18" />
              <span className="name">Audit</span>
              <span className="name">Schedule</span>
              <IconDown size="14" className="icon-down" />
            </Link>
            <Collapse
              style={{ marginLeft: "0px" }}
              isOpen={this.state.selected === 5 ? true : false}
              onClick={this.handleOpen.bind(this, 5)}
            >
              <ul className="inner-drop list-unstyled">
                <li>
                  <Link to="/tracking-report" activeClassName="active">
                    Audit Reports Tracking
                  </Link>
                </li>
                <li>
                  <Link to="/audit-register" activeClassName="active">
                    Audit Register
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
        </ul>
        {/* end scroll-area */}
      </ScrollArea>
    );
  }
}

export default (props) => (
  <nav className={`site-nav ${props.mini ? "mini" : ""}`}>
    <NavHead {...props} />
    <NavList />
  </nav>
);
