import React, { Component } from 'react';
import {
    Input,
     
} from 'reactstrap';

class FloorList extends Component {
    render () {
        return (
            <Input
                type="select"
                name="drpFloor"
                id="drpFloor"
                value={this.props.floorValue}
                onChange={this.props.drpChangeFloor}>
                <option value="">--Select Floor--</option>
                {this
                    .props.floorFillData
                    .map((item, i) => <option key={item.id} value={item.id}>
                        {item.name}</option>)}
            </Input>
        )
    }
}
FloorList.defaultProps=
{

    floorFillData:[],
    floorValue:""

}

export default FloorList