import React, { Component, memo } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import LaddaButton, { S, EXPAND_RIGHT } from "react-ladda";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  fetchAuditAns,
  FinalRectification,
  fetchRectificationsReport,
} from "../../actions/actions";
import { Label } from "reactstrap";

import { connect } from "react-redux";
import AntDatepicker from "antd/es/date-picker";
import moment from "moment";
import { getReportData } from "../../utilities";
const { MonthPicker } = AntDatepicker;

class FTable extends Component {
  constructor() {
    super();
    this.state = {};
    this.cellActionButton = this.cellActionButton.bind(this);
  }

  cellActionButton(e) {
    let theButton;
    theButton = (
      <div>
        <button>
          <div onClick={() => getReportData(e)} className="btn btn-sm btn-primary" >
            Download Report
          </div>
        </button>
      </div>
    );
    return theButton;
  }

  yearFormate(e) {
    const yearDate = moment(e).format("YYYY");
    return yearDate;
  }
  monthFormate(e) {
    const monthDate = moment(e).format("DD-MMM");
    return monthDate;
  }

  render() {
    const { facilityFillData } = this.props;

    const options = {
      noDataText: "Records not found",
    };

    return (
      <div>
        <div>
          <div>
            <div className="row">
              <div className="col-md-12 ">
                <div>
                  <BootstrapTable
                    ref="table"
                    data={facilityFillData}
                    pagination={true}
                    options={options}
                  >
                    <TableHeaderColumn
                      dataField="facility_id"
                      isKey={true}
                      hidden={true}
                    >
                      Id
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="facility_name"
                      dataSort={true}
                    >
                      Facility
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="report_date"
                      dataFormat={this.monthFormate}
                      dataSort={true}
                    >
                      Month
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="report_date"
                      dataFormat={this.yearFormate}
                      dataSort={true}
                    >
                      Year
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="pdf_url"
                      dataFormat={this.cellActionButton}
                      dataSort={true}
                    >
                      Action
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { postsBySubreddit } = state;
  const {} = postsBySubreddit;
  return {};
}

const AppContainer = connect(mapStateToProps)(FTable);

export default memo(AppContainer);
