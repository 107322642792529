import React, { Component } from "react";
import { connect } from "react-redux";
import LaddaButton, { S, EXPAND_RIGHT } from "react-ladda";
import { Card, CardBlock, Button, Input, FormGroup, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import getBase64 from "../../components/common/getBase64";
import "./style.css";
import AlertMessageClass from "../../components/alert_message";
import StateListClass from "../../components/state_list";
import FacilityListClass from "../../components/facility_list";
import moment from "moment";
import FTable from "./table";
import LoaderClass from "../../components/loader";
// import Editable,{CustomComponent} from 'react-x-editable';
import {
  fetchStateList,
  fetchFacilityList,
  fetchRectificationsReport,
  featchUserData,
  fetchAreaList,
  commentByAuditor,
  fetchRoomList,
  addNewFloor,
  getFloorById,
  updateFloorById,
  getAreaById,
} from "../../actions/actions";
import AntDatepicker from "antd/es/date-picker";

const { MonthPicker } = AntDatepicker;

// let src = `https://majestic-dev.s3.amazonaws.com/sample_bulk_upload/MajesticCleaningAudit_Bulk_Upload_sample.csv`;
//import {Table, Th, Thead} from 'reactable';
//var axios = require('axios');
var simpleReactValidator = require("simple-react-validator");

const ViewContent = ({ children }) => (
  <div className="view-content view-components">{children}</div>
);

class CorrectiveReport extends Component {
  /*all event and state*/
  constructor(props) {
    super(props);
    this.validator = new simpleReactValidator(
      (this.rules = {
        custom_text: {
          message: "The :attribute must be a valid text.",
          rule: (val) =>
            !this._testRegex(
              val,
              /^[0-9@!#\$\^%&*()+=\-\[\]\\\';,\.\/\{\}\|\":<>\? ]+$/
            ),
        },
      })
    );
    this.state = {
      toDate: moment(),
      toMonth: moment(),
      data: [],
      state_data: [],
      facility_data: [],
      audit_ans_data: [],
      drpUser: "",
      drpFacilityValue: "",
      drpFacName: "",
      facilityId: "",
      drpStateValue: "",
      stateId: "",
      dispFac: true,
      modal: false,
      newQus: true,
      alertMessageText: "",
      alertMessageVisible: false,
      alertMessageColor: "",
      loading: false,
      btnAddloading: false,
      btnEditloading: false,
      qus_id: "",
      question_text: "",
      question_info: "",
      errorEmail: false,
      floorFlag: true,
      areaFlag: true,
      roomFlag: true,
      btnName: "Add",
      loaderStatus: true,
      setQusFocus: false,
      showFloorTable: true,
      showAreaTable: true,
      showRoomTable: true,
      showDefaultTable: false,
      showFacilitySetupTable: true,
      showBtn: true,
      fac_err: true,
      deleteModal: false,
      floor_id: "",
      floor_name: "",
      setFloorFocus: false,
      setAreaFocus: false,
      setRoomFocus: false,
      floor_details_by_id: [],
      userDataValue: "",
      area_name: "",
      area_details_by_id: [],
      room_id: "",
      room_name: "",
      room_details_by_id: [],
      del_modal_name: "",
      del_floor_id: "",
      del_area_id: "",
      del_room_id: "",
      globalFacId: "",
      globalStateId: "",
      is_valid_floor_text: false,
      is_valid_area_text: false,
      is_valid_room_text: false,
      undefined_msg: false,
      showBulkTable: true,
      selectedFiles: null,
      selectedCSVFiles: null,
      setFileErr: false,
      noSelectedFile: true,
      filterReportData: [],
      originalFData: [],
      isFilter: false,
      isSuccess: false,
    };

    this.drpClickFacility = this.drpClickFacility.bind(this);

    this.drpClickState = this.drpClickState.bind(this);

    this.drpChangeFloor = this.drpChangeFloor.bind(this);

    this.clickBtnAddArea = this.clickBtnAddArea.bind(this);

    this.commentArea = this.commentArea.bind(this);

    this.onDismiss = this.onDismiss.bind(this);

    this.toggle = this.toggle.bind(this);

    this.toDateChange = this.toDateChange.bind(this);
    this.toMonthChange = this.toMonthChange.bind(this);

    this.btnAddloading = this.btnAddloading.bind(this);

    this.btnEditloading = this.btnEditloading.bind(this);

    this.toogleFloorModelClose = this.toogleFloorModelClose.bind(this);

    this.toogleBulkModelClose = this.toogleBulkModelClose.bind(this);

    this.toogleAreaModelClose = this.toogleAreaModelClose.bind(this);

    this.toogleRoomModelClose = this.toogleRoomModelClose.bind(this);

    this.fileInput = React.createRef();
  }

  _testRegex(value, regex) {
    return value.toString().match(regex) !== null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.rectification_data != prevProps.rectification_data) {
      this.setState({
        loading: false,
        loaderStatus: true,
      });
    }

    if (this.props.comment_by_auditor != prevProps.comment_by_auditor) {
      if (
        this.props.comment_by_auditor != null &&
        this.props.comment_by_auditor != ""
      ) {
        // this.refreshFloor();
        if (this.props.comment_by_auditor.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: this.props.comment_by_auditor.message,
            floorFlag: true,
            areaFlag: true,
            showFloorTable: false,
            userDataValue: "",
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: "Something went wrong",
          });
        }
      }
    }
    if (this.props.final_report != prevProps.final_report) {
      if (this.props.final_report != null && this.props.final_report != "") {
        // this.refreshFloor();
        if (this.props.final_report.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: "Report Generated Successfully.",
            floorFlag: true,
            isFilter: false,
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: "All audit must be attempted before submitting.",
            floorFlag: true,
          });
        }
      }
    }
  }

  componentDidMount() {
    //var id="1"
    var id = sessionStorage.getItem("role_id");

    const { dispatch } = this.props;
    dispatch(fetchStateList(id));
    //dispatch(fetchFacilityList(id))
  }

  toggle() {
    this.setState({
      loading: !this.state.loading,
      progress: 0.5,
    });
  }
  btnAddloading() {
    this.setState({
      btnAddloading: !this.state.btnAddloading,
      progress: 0.5,
    });
  }

  btnEditloading() {
    this.setState({
      btnEditloading: !this.state.btnEditloading,
      progress: 0.5,
    });
  }
  toogleFloorModelClose() {
    this.setState({
      floorFlag: true,
    });
  }

  toogleBulkModelClose() {
    this.setState({
      showBulkTable: true,
    });
  }

  toogleAreaModelClose() {
    this.setState({
      areaFlag: true,
      showFloorTable: false,
    });
  }
  toogleRoomModelClose() {
    this.setState({
      roomFlag: true,
    });
  }

  commentArea = (e) => {
    var that = this;
    var val = e.target.value;
    var pattern = /^[0-9@!#\$\^%&*()+=\-\[\]\\\';,\.\/\{\}\|\":<>\? ]+$/;
    if (pattern.test(val)) {
      this.setState({
        is_valid_area_text: false,
      });
    } else {
      this.setState({
        is_valid_area_text: true,
      });
    }

    this.setState({
      area_name: e.target.value,
      setAreaFocus: true,
    });
  };

  showFloorTable = (e) => {
    localStorage.removeItem("f_date");
    var facility_id = this.state.drpFacilityValue;
    var state_id = this.state.drpStateValue;

    if (facility_id !== "" && state_id !== "") {
      this.setState({
        loading: !this.state.loading,
        progress: 0.5,
        loaderStatus: false,
        globalFacId: facility_id,
        globalStateId: state_id,
        isFilter: false,
      });
      this.props.dispatch(fetchRectificationsReport(facility_id));
      this.props.dispatch(featchUserData(facility_id));

      this.setState({
        showFloorTable: false,
        showBtn: false,
        fac_err: true,
        isFilter: false,
      });
    } else {
      this.setState({
        showFloorTable: true,
        showBtn: true,
        fac_err: false,
        showDefaultTable: false,
        floorFlag: true,
        areaFlag: true,
        roomFlag: true,
      });
      this.validator.showMessages();
    }
    this.setState({
      showAreaTable: true,
      showRoomTable: true,
      floorFlag: true,
      areaFlag: true,
      roomFlag: true,
      btnName: "Add",
      showDefaultTable: true,
      showFacilitySetupTable: true,
      alertMessageVisible: false,
      undefined_msg: false,
    });
  };

  showAreaTable = (row) => {
    var that = this;
    var floor_id = row.floor_id;
    if (floor_id !== "") {
      that.setState({
        drpFloorValue: floor_id,
        floor_id: floor_id,
      });
    } else {
      that.setState({
        drpFloorValue: "",
        floor_id: "",
      });
    }

    that.setState({
      showFloorTable: true,
      showAreaTable: false,
      showRoomTable: true,
      floorFlag: true,
      areaFlag: false,
      roomFlag: true,
      btnName: "Add",
      showDefaultTable: true,
      showFacilitySetupTable: true,
      alertMessageVisible: false,
      area_name: "",
      undefined_msg: false,
    });
    that.validator.hideMessages();
  };

  showFacilitySetupTable = (e) => {
    var facility_id = this.state.drpFacilityValue;
    var state_id = this.state.drpStateValue;

    if (facility_id !== "" && state_id !== "") {
      this.props.dispatch(fetchRectificationsReport(facility_id));
      this.setState({
        showFacilitySetupTable: false,
        showBtn: false,
        fac_err: true,
      });
    } else {
      this.setState({
        showFacilitySetupTable: true,
        showBtn: true,
        fac_err: false,
        showDefaultTable: false,
        floorFlag: true,
        areaFlag: true,
        roomFlag: true,
      });
      this.validator.showMessages();
    }
    this.setState({
      showAreaTable: true,
      showRoomTable: true,
      floorFlag: true,
      areaFlag: true,
      roomFlag: true,
      btnName: "Add",
      showDefaultTable: true,
      showFloorTable: true,
      alertMessageVisible: false,
      undefined_msg: false,
    });
  };

  drpClickFacility = (e) => {
    var facility_id = e.target.value;
    var facility_name = "";
    if (facility_id != "") {
      facility_name = e.target.options[e.target.selectedIndex].text;
    }

    this.setState({
      drpFacilityValue: facility_id,
      drpFacName: facility_name,
    });

    if (facility_id === "") {
      this.setState({
        facilityId: "",
        newQus: true,
        alertMessageVisible: false,
        data: [],
      });
    } else {
      if (facility_id > 0) {
        this.setState({
          facilityId: facility_id,
        });
      }
    }
  };

  drpClickState = (e) => {
    this.setState({
      drpStateValue: e.target.value,
      drpFacilityValue: "",
    });
    var state_id = e.target.value;
    if (state_id === "") {
      this.setState({
        stateId: "",
        newQus: true,
        alertMessageVisible: false,
        data: [],
      });
    } else {
      if (state_id > 0) {
        this.setState({
          stateId: state_id,
        });
        this.props.dispatch(fetchFacilityList(state_id));
      }
    }
  };

  drpChangeFloor = (e) => {
    var facility_user_id = e.target.value;
    this.setState({
      userDataValue: facility_user_id,
    });
  };

  clickBtnAddArea = (e) => {
    var that = this;
    if (that.validator.allValid()) {
    } else {
      that.validator.showMessages();
      that.forceUpdate();
    }
    var getdate = that.state.toDate;
    var date = moment(getdate).format("YYYY-MM-DD");
    var user_id = that.state.userDataValue;
    var comment_by_staff = that.state.area_name;

    if (user_id !== "" && that.state.area_name != "") {
      var que_ans_list = [
        {
          comment_by_staff: comment_by_staff,
          images_by_staff: null,
          audit_id: this.props.audit_ans_data[0].audit_id,
          que_id: this.props.audit_ans_data[0].que_id,
          user_id: user_id,
          date: date,
        },
      ];
      var post = {
        que_ans_list: que_ans_list,
      };

      const selDate = localStorage.getItem("f_date");
      let idsOld = localStorage.getItem("added_id");
      idsOld = JSON.parse(idsOld);
      const { audit_id } = Array.isArray(this.props.audit_ans_data)
        ? this.props.audit_ans_data[0]
        : { audit_id: null };

      if (Array.isArray(idsOld)) {
        idsOld.push(audit_id);
        localStorage.setItem("added_id", JSON.stringify(idsOld));
        this.props.dispatch(commentByAuditor(post)).then(() => {
          this.props.dispatch(
            fetchRectificationsReport(this.state.drpFacilityValue)
          );
        });
      } else {
        let ids = [audit_id];
        localStorage.setItem("added_id", JSON.stringify(ids));
        this.props.dispatch(commentByAuditor(post)).then(() => {
          this.props.dispatch(
            fetchRectificationsReport(this.state.drpFacilityValue)
          );
        });
      }

      let filterData = [];
      let added_ids = localStorage.getItem("added_id");
      added_ids = JSON.parse(added_ids);

      if (Array.isArray(added_ids) && added_ids.length > 0) {
        filterData = [
          ...this.state.filterReportData.map((d) => {
            if (added_ids.includes(d.audit_id)) {
              return { ...d, audit_status: 1 };
            }
            return { ...d };
          }),
        ];
      }
      // that.setState((prevState) => ({
      //   ...prevState,
      //   isSuccess: false,
      //   filterReportData: filterData,
      //   btnAddloading: false,
      // }));
      setTimeout(() => {
        that.setState((prevState) => ({
          ...prevState,
          isSuccess: false,
          filterReportData: filterData,
          btnAddloading: false,
        }));
      }, 1500);
    }
  };

  onDismiss() {
    var that = this;
    that.setState({
      alertMessageVisible: false,
    });
  }

  //------------date-------------------//
  toDateChange(date) {
    this.setState({ toDate: date });
  }
  toMonthChange(date) {
    this.setState({ toMonth: date });
  }

  handleUpdateMessage = () => {
    this.setState({
      alertMessageVisible: true,
      alertMessageColor: "danger",
      alertMessageText: "All audit must be attempted before submitting.",
    });
  };

  handleFilterReportData = (data, filter = true, success = true) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        filterReportData: data,
        isFilter: filter,
        isSuccess: success,
      };
    });
  };

  handleOriginalFData = (data) => {
    this.setState((prevState) => {
      return { ...prevState, originalFData: data };
    });
  };

  handleIsFilter = (value, success = this.state.isSuccess) => {
    this.setState((prevState) => {
      return { ...prevState, isFilter: value, isSuccess: success };
    });
  };

  render() {
    const { state_data, facility_data, rectification_data } = this.props;
    function ViewHeader() {
      return (
        <div className="view-header">
          <header className="title text-white">
            <h1 style={{ color: "white" }} className="h4 text-uppercase">
              {" "}
              Rectification Report{" "}
            </h1>
            <p className="mb-0">
              {" "}
              View and export audit and rectification reports here.....
            </p>
          </header>
        </div>
      );
    }

    const StateFacilityDropDownList = () => (
      <div>
        <div className="row">
          <div className="col-md-4 ">
            <h6 className="mb-4 text-uppercase"> Select State </h6>
            <StateListClass
              stateValue={this.state.drpStateValue}
              drpChangeState={this.drpClickState}
              stateFillData={state_data}
            />
            <span className="error-text">
              {this.validator.message(
                "State",
                this.state.drpStateValue,
                "required"
              )}
            </span>
          </div>
          <div className="col-md-4 ">
            <h6 className="mb-4 text-uppercase"> Select Facility</h6>
            <FacilityListClass
              facilityValue={this.state.drpFacilityValue}
              drpChangeFac={this.drpClickFacility}
              facilityFillData={facility_data}
            />
            <span className="error-text">
              {this.validator.message(
                "Facility",
                this.state.drpFacilityValue,
                "required"
              )}
            </span>
          </div>

          <div className="col-md-2 btnmarginTop">
            <LaddaButton
              className="btn btn-warning load-fac-btn marginRight_10"
              loading={this.state.loading}
              onClick={this.showFloorTable}
              data-color="#eee"
              data-size={S}
              data-style={EXPAND_RIGHT}
              data-spinner-size={30}
              data-spinner-color="#ddd"
              data-spinner-lines={12}
            >
              Load Item
            </LaddaButton>
          </div>
        </div>
      </div>
    );

    const AlertMessage = () => (
      <AlertMessageClass
        alertMessageColor={this.state.alertMessageColor}
        alertMessageVisible={this.state.alertMessageVisible}
        onDismiss={this.onDismiss}
        alertMessageText={this.state.alertMessageText}
      />
    );

    const FloorTable = () => (
      <FTable
        audit_ans_data={this.props.audit_ans_data}
        floorFillData={rectification_data}
        filterReportData={this.state.filterReportData}
        originalFData={rectification_data}
        handleOriginalFData={this.handleOriginalFData}
        isFilter={this.state.isFilter}
        isSuccess={this.state.isSuccess}
        handleIsFilter={this.handleIsFilter}
        handleFilterReportData={this.handleFilterReportData}
        openModal={(row) => this.showAreaTable(row)}
        facilityId={this.state.globalFacId}
        stateId={this.state.globalStateId}
        alertMessageVisible={this.state.alertMessageVisible}
        alertMessageColor={this.state.alertMessageColor}
        handleUpdateMessage={this.handleUpdateMessage}
      />
    );
    const DefaultTable = () => (
      <table className="table">
        <thead>
          <tr>
            <th> Id </th>
            <th> Report </th>
            <th> Action </th>
          </tr>
        </thead>

        <tbody></tbody>
      </table>
    );

    return (
      <div className="view">
        <ViewHeader />

        <ViewContent>
          <Card className="mb-4">
            <CardBlock className="table-responsive">
              <StateFacilityDropDownList />
            </CardBlock>
          </Card>

          <br />

          <Card className="mb-4 col-md-12" hidden={this.state.areaFlag}>
            <div className="row">
              <CardBlock className="table-responsive">
                <h4 className="mb-4 text-uppercase text-center">
                  {" "}
                  {this.state.btnName} Rectification
                  <br />
                </h4>

                <div className="row">
                  <div className="col" style={{ marginLeft: "500px" }}>
                    {this.props.audit_ans_data.map((item, i) => (
                      <p
                        style={{ margin: "10px" }}
                        key={item.audit_id}
                        value={item.audit_id}
                      >
                        <b>Question Information :</b> {item.que_text}
                      </p>
                    ))}
                  </div>

                  <div className="col" style={{ marginRight: "350px" }}>
                    {this.props.audit_ans_data.map((item, i) => (
                      <p
                        style={{ margin: "10px" }}
                        key={item.audit_id}
                        value={item.audit_id}
                      >
                        <b> Comment By Auditor :</b>{" "}
                        {item.why_comment_by_auditor}
                      </p>
                    ))}
                  </div>
                </div>
                <hr className="hr1" />

                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-6 offset-md-3">
                      <FormGroup>
                        <Label>Select User</Label>
                        <Input
                          type="select"
                          name="userDataValue"
                          id="userDataValue"
                          value={this.state.userDataValue}
                          onChange={this.drpChangeFloor}
                        >
                          <option value="">--Select User--</option>
                          {this.props.facility_wise_user_list.map((item, i) => (
                            <option key={item.user_id} value={item.user_id}>
                              {item.Name}
                            </option>
                          ))}
                        </Input>
                        <span className="error-text">
                          {this.validator.message(
                            "User",
                            this.state.userDataValue,
                            "required"
                          )}
                        </span>

                        <br />

                        <Label> Select Date </Label>
                        {/* <DatePicker
                          className="form-control"
                          selected={this.state.toDate}
                          selectsEnd
                          withPortal
                          dateFormat="DD-MM-YYYY"
                          onChange={this.toDateChange}
                        /> */}

                        <br />

                        <Label>Add Comment</Label>
                        <Input
                          type="textarea"
                          name="area_name"
                          value={this.state.area_name}
                          id="area_name"
                          className="form-control"
                          placeholder="Area Name"
                          onChange={this.commentArea}
                          autoFocus={this.state.setAreaFocus}
                          onBlur={this.removeFocus}
                          maxLength="100"
                        />
                        <span className="error-text">
                          {this.validator.message(
                            "Comment",
                            this.state.area_name,
                            "required|custom_text",
                            false,
                            {
                              custom_text:
                                "This field may only contain alphabetic characters and number.",
                              default: "This field is required.",
                            }
                          )}
                        </span>
                      </FormGroup>
                    </div>

                    <div className="col-md-12 text-center">
                      <LaddaButton
                        className="btn btn-primary"
                        onClick={this.clickBtnAddArea}
                        loading={this.state.btnAddloading}
                        data-color="#eee"
                        data-size={S}
                        data-style={EXPAND_RIGHT}
                        data-spinner-size={30}
                        data-spinner-color="#ddd"
                        data-spinner-lines={12}
                      >
                        {" "}
                        {this.state.btnName}{" "}
                      </LaddaButton>
                      &nbsp;
                      <Button
                        color="default"
                        className="pointerClass cancel_btn"
                        onClick={this.toogleAreaModelClose}
                      >
                        {" "}
                        Cancel{" "}
                      </Button>
                    </div>
                  </div>
                </div>
              </CardBlock>
            </div>
          </Card>

          <Card className="mb-4 col-md-12" hidden={this.state.showFloorTable}>
            <CardBlock className="table-responsive">
              <AlertMessage />{" "}
              <AlertMessageClass
                alertMessageColor="danger"
                alertMessageVisible={this.state.undefined_msg}
                onDismiss={this.onDismiss}
                alertMessageText="Something went wrong. Please try again."
              />
              <h6 className="mb-4 text-uppercase"> Audit Report </h6>
              <LoaderClass visibleStatus={this.state.loaderStatus} />
              <div>
                <FloorTable hidden={this.state.floorDisFlag} />
              </div>
            </CardBlock>
          </Card>

          <Card className="mb-4 col-md-12" hidden={this.state.showDefaultTable}>
            <CardBlock className="table-responsive">
              <h6 className="mb-4 text-uppercase"> Audit Report </h6>
              <DefaultTable />
            </CardBlock>
          </Card>
        </ViewContent>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { postsBySubreddit } = state;
  const {
    final_report: final_report,
    state_data: state_data,
    facility_data: facility_data,
    audit_ans_data: audit_ans_data,
    rectification_data: rectification_data,
    comment_by_auditor: comment_by_auditor,
    area_data: area_data,
    area_data_by_floor: area_data_by_floor,
    area_risk_type_data: area_risk_type_data,
    room_data: room_data,
    floor_details_by_id: floor_details_by_id,
    floor_add_data: floor_add_data,
    floor_update_data: floor_update_data,
    area_details_by_id: area_details_by_id,
    area_add_data: area_add_data,
    area_update_data: area_update_data,
    room_details_by_id: room_details_by_id,
    room_add_data: room_add_data,
    room_update_data: room_update_data,
    floor_delete_data: floor_delete_data,
    area_delete_data: area_delete_data,
    room_delete_data: room_delete_data,
    add_multiple_floor: add_multiple_floor,
    bulk_upload: bulk_upload,
    facility_wise_user_list: facility_wise_user_list,
  } = postsBySubreddit;
  return {
    final_report,
    state_data,
    facility_data,

    audit_ans_data,
    rectification_data,
    comment_by_auditor,
    area_data,
    area_data_by_floor,
    area_risk_type_data,
    room_data,
    floor_details_by_id,
    floor_add_data,
    floor_update_data,
    area_details_by_id,
    area_add_data,
    area_update_data,
    room_details_by_id,
    room_add_data,
    room_update_data,
    floor_delete_data,
    area_delete_data,
    room_delete_data,
    add_multiple_floor,
    bulk_upload,
    facility_wise_user_list,
  };
}

const AppContainer = connect(mapStateToProps)(CorrectiveReport);

export default AppContainer;
