import React, { Component } from "react";
import LaddaButton, { S, EXPAND_RIGHT } from "react-ladda";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

class FacQuestionTable extends Component {
  constructor() {
    super();
    this.cellActionButton = this.cellActionButton.bind(this);
  }
  cellActionButton(cell, row, enumObject, rowIndex) {
    let theButton;
    theButton = (
      <div>
        <button
          className="btn btn-sm btn-primary marginRight_10"
          style={{ cursor: "pointer" }}
          value={row.id}
          onClick={this.props.toggleModal}
        >
          <i className="fa fa-pencil-square-o"></i>&nbsp; Edit{" "}
        </button>
        <button
          className="btn btn-sm btn-danger marginRight_10"
          style={{ cursor: "pointer" }}
          value={row.id}
          onClick={this.props.toggleDeleteModal}
        >
          <i className="fa fa-trash"></i>&nbsp; Delete{" "}
        </button>
        <button
          className="btn btn-sm btn-primary marginRight_10"
          style={{ cursor: "pointer" }}
          value={row.id}
          onClick={this.props.toggleConfigureModal}
        >
          <i className="fa fa-pencil-square-o"></i>&nbsp; Configuration{" "}
        </button>
        <br />
        <button
          className="btn btn-sm btn-warning marTop_10"
          style={{ cursor: "pointer" }}
          value={row.id}
          onClick={this.props.toggleMaster}
        >
          <i className="fa fa-list"></i>&nbsp; Manage Why-Where Bank{" "}
        </button>
      </div>
    );
    return theButton;
  }

  render() {
    var cusorPointer = { cursor: "pointer" };
    var facFlag = false;
    var options = {
      noDataText: "Records not found",
    };
    var final_que_arr = [];
    {
      this.props.questionFillData.forEach((item, i) => {
        if (item.facility_id !== 0) {
          final_que_arr.push(item);
        }
      });
    }

    return (
      <div>
        <hr />
        <h6>Facility Wise Added Questions</h6>

        <div className="row">
          <div className="col-md-12 ">
            <div className="">
              {" "}
              {/*table-responsive tbl-backgroud*/}
              <BootstrapTable
                ref="table"
                data={final_que_arr}
                pagination={true}
                options={options}
                search={true}
              >
                <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn dataField="que_text" dataSort={true}>
                  Question Text
                </TableHeaderColumn>
                <TableHeaderColumn dataField="que_info" dataSort={true}>
                  Help Text
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="action"
                  dataFormat={this.cellActionButton}
                >
                  Action
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FacQuestionTable;
