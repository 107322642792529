import React, { Component } from 'react';
import {
    Input,
     
} from 'reactstrap';

class QuestionList extends Component {
    render () {
        return (
            <Input
                type="select"
                name="drpFac"
                id="drpFac"
                value={this.props.questionValue}
                onChange={this.props.drpChangeQue}>
                <option value="">--Select Question--</option>
                {/*{this
                    .props.facilityFillData
                    .map((item, i) => <option key={item.facility_id} value={item.facility_id}>
                        {item.facility_name}</option>)}*/}
                    <option value="1">Question1</option>
                    <option value="2">Question2</option>
                    <option value="3">Question3</option>
                    <option value="4">Question4</option>
                    <option value="5">Question5</option>
            </Input>
        )
    }
}
QuestionList.defaultProps=
{

    questionFillData:[],
    questionValue:""

}

export default QuestionList