import React, { Component } from "react";
import { Container } from "reactstrap";
import { connect } from "react-redux";

import LaddaButton, { S, EXPAND_RIGHT } from "react-ladda";
import {
  Card,
  CardBlock,
  Button,
  Input,
  Form,
  FormGroup,
  Alert,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { browserHistory } from "react-router";
import getBase64 from "../../components/common/getBase64";
import "./style.css";
import AlertMessageClass from "../../../shared/components/alert_message";
import StateListClass from "../../../shared/components/state_list";
import FacilityListClass from "../../../shared/components/facility_list";

import FloorTableClass from "../../../shared/components/facility_setup/floor_table";
import AreaTableClass from "../../../shared/components/facility_setup/area_table";
import RoomTableClass from "../../../shared/components/facility_setup/room_table";

import FacilitySetupTableClass from "./facilitysetup_table";

import LoaderClass from "../../../shared/components/loader";
// import Editable,{CustomComponent} from 'react-x-editable';
import {
  fetchStateList,
  fetchFacilityList,
  fetchFloorList,
  fetchAreaList,
  fetchAreaListByFloor,
  fetchAreaRiskTypeList,
  fetchRoomList,
  addNewFloor,
  getFloorById,
  updateFloorById,
  addNewArea,
  getAreaById,
  updateAreaById,
  addNewRoom,
  getRoomById,
  updateRoomById,
  removeFloor,
  removeArea,
  removeRoom,
  addMultipleFloor,
  bulkUploadFacility,
} from "../../actions/actions";

let src = process.env.REACT_APP_BULK_UPLOAD;
//import {Table, Th, Thead} from 'reactable';
//var axios = require('axios');
var simpleReactValidator = require("simple-react-validator");

const ViewContent = ({ children }) => (
  <div className="view-content view-components">{children}</div>
);

class FacilitySetup extends Component {
  /*all event and state*/
  constructor(props) {
    super(props);
    this.validator = new simpleReactValidator(
      (this.rules = {
        custom_text: {
          message: "The :attribute must be a valid text.",
          rule: (val) =>
            !this._testRegex(
              val,
              /^[0-9@!#\$\^%&*()+=\-\[\]\\\';,\.\/\{\}\|\":<>\? ]+$/
            ),
        },
      })
    );
    this.state = {
      data: [],
      state_data: [],
      facility_data: [],
      floor_data: [],
      drpFacilityValue: "",
      drpFacName: "",
      facilityId: "",
      drpStateValue: "",
      stateId: "",
      dispFac: true,
      modal: false,
      newQus: true,
      alertMessageText: "",
      alertMessageVisible: false,
      alertMessageColor: "",
      loading: false,
      btnAddloading: false,
      btnEditloading: false,
      qus_id: "",
      question_text: "",
      question_info: "",
      errorEmail: false,
      floorFlag: true,
      areaFlag: true,
      roomFlag: true,
      btnName: "Add",
      loaderStatus: true,
      setQusFocus: false,
      showFloorTable: true,
      showAreaTable: true,
      showRoomTable: true,
      showDefaultTable: false,
      showFacilitySetupTable: true,
      showBtn: true,
      fac_err: true,
      deleteModal: false,
      floor_id: "",
      floor_name: "",
      setFloorFocus: false,
      setAreaFocus: false,
      setRoomFocus: false,
      floor_details_by_id: [],
      drpFloorValue: "",
      drpAreaTypeValue: "",
      area_id: "",
      area_name: "",
      area_type: "",
      area_details_by_id: [],
      room_id: "",
      room_name: "",
      room_details_by_id: [],
      del_modal_name: "",
      del_floor_id: "",
      del_area_id: "",
      del_room_id: "",
      globalFacId: "",
      globalStateId: "",
      is_valid_floor_text: false,
      is_valid_area_text: false,
      is_valid_room_text: false,
      undefined_msg: false,
      showBulkTable: true,
      selectedFiles: null,
      selectedCSVFiles: null,
      setFileErr: false,
      noSelectedFile: true,
    };
    console.log(this.state.loading, "loadingloadingloadingloading");

    this.drpClickFacility = this.drpClickFacility.bind(this);

    this.drpClickState = this.drpClickState.bind(this);

    this.drpChangeFloor = this.drpChangeFloor.bind(this);

    this.drpChangeAreaType = this.drpChangeAreaType.bind(this);

    this.drpChangeArea = this.drpChangeArea.bind(this);

    this.clickBtnAddFloor = this.clickBtnAddFloor.bind(this);

    this.clickBtnAddArea = this.clickBtnAddArea.bind(this);

    this.clickBtnAddRoom = this.clickBtnAddRoom.bind(this);

    this.changeTxtFloor = this.changeTxtFloor.bind(this);

    this.changeTxtArea = this.changeTxtArea.bind(this);

    this.changeTxtRoom = this.changeTxtRoom.bind(this);

    this.onDismiss = this.onDismiss.bind(this);

    this.toggle = this.toggle.bind(this);

    this.btnAddloading = this.btnAddloading.bind(this);

    this.btnEditloading = this.btnEditloading.bind(this);

    this.toogleFloorModelClose = this.toogleFloorModelClose.bind(this);

    this.toogleBulkModelClose = this.toogleBulkModelClose.bind(this);

    this.toogleAreaModelClose = this.toogleAreaModelClose.bind(this);

    this.toogleRoomModelClose = this.toogleRoomModelClose.bind(this);

    this.AddtoogleFloorModel = this.AddtoogleFloorModel.bind(this);

    this.AddBulkData = this.AddBulkData.bind(this);

    this.onChangeHandler = this.onChangeHandler.bind(this);

    this.fileInput = React.createRef();
  }

  _testRegex(value, regex) {
    return value.toString().match(regex) !== null;
  }

  componentDidUpdate(prevProps, prevState) {
    console.log(this.props, "floordTaaaaa-----didupdate");
    if (this.props.floor_data != prevProps.floor_data) {
      this.setState({
        loading: false,
        loaderStatus: true,
      });
    }

    if (this.props.floor_details_by_id != prevProps.floor_details_by_id) {
      //console.log(this.props.floor_details_by_id);
      if (
        this.props.floor_details_by_id != null &&
        this.props.floor_details_by_id != ""
      ) {
        var name = this.props.floor_details_by_id.result[0].name;
        this.setState({
          floor_name: name,
        });
      }
    }

    if (this.props.floor_update_data != prevProps.floor_update_data) {
      if (
        this.props.floor_update_data != null &&
        this.props.floor_update_data != ""
      ) {
        this.refreshFloor();
        if (this.props.floor_update_data.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: this.props.floor_update_data.message,
            floorFlag: true,
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: "Something went wrong",
          });
        }
      }
    }

    if (this.props.floor_add_data != prevProps.floor_add_data) {
      if (
        this.props.floor_add_data != null &&
        this.props.floor_add_data != ""
      ) {
        this.refreshFloor();
        if (this.props.floor_add_data.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: this.props.floor_add_data.message,
            floorFlag: true,
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: this.props.floor_add_data.message,
            floorFlag: true,
          });
        }
      }
    }

    if (this.props.area_details_by_id != prevProps.area_details_by_id) {
      //console.log(this.props.area_details_by_id);
      if (
        this.props.area_details_by_id != null &&
        this.props.area_details_by_id != ""
      ) {
        var floor_id = this.props.area_details_by_id.result[0].floor_id;
        var area_risk_type_id = this.props.area_details_by_id.result[0]
          .area_risk_type_id;
        var name = this.props.area_details_by_id.result[0].name;

        this.props.dispatch(fetchAreaListByFloor(floor_id));

        this.setState({
          drpFloorValue: floor_id,
          drpAreaTypeValue: area_risk_type_id,
          floor_id: floor_id,
          area_type: area_risk_type_id,
          area_name: name,
        });
      }
    }

    if (this.props.area_update_data != prevProps.area_update_data) {
      if (
        this.props.area_update_data != null &&
        this.props.area_update_data != ""
      ) {
        this.refreshArea();
        if (this.props.area_update_data.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: this.props.area_update_data.message,
            areaFlag: true,
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: "Something went wrong",
          });
        }
      }
    }

    if (this.props.area_add_data != prevProps.area_add_data) {
      if (this.props.area_add_data != null && this.props.area_add_data != "") {
        this.refreshArea();
        if (this.props.area_add_data.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: this.props.area_add_data.message,
            areaFlag: true,
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: this.props.area_add_data.message,
            areaFlag: true,
          });
        }
      }
    }

    if (this.props.room_details_by_id != prevProps.room_details_by_id) {
      //console.log(this.props.room_details_by_id);
      if (
        this.props.room_details_by_id != null &&
        this.props.room_details_by_id != ""
      ) {
        var floor_id = this.props.room_details_by_id.result[0].floor_id; // change to floor_id
        var area_id = this.props.room_details_by_id.result[0].area_id;
        var name = this.props.room_details_by_id.result[0].name;
        console.log(floor_id);
        console.log(area_id);
        this.setState({
          drpFloorValue: floor_id,
          floor_id: floor_id,
          area_id: area_id,
          drpAreaValue: area_id,
          room_name: name,
        });
        //call API to get area by floor (need to add floor_id in below API)
        this.props.dispatch(fetchAreaListByFloor(floor_id));
      }
    }

    if (this.props.room_update_data != prevProps.room_update_data) {
      if (
        this.props.room_update_data != null &&
        this.props.room_update_data != ""
      ) {
        this.refreshRoom();
        if (this.props.room_update_data.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: this.props.room_update_data.message,
            roomFlag: true,
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: "Something went wrong",
          });
        }
      }
    }

    if (this.props.room_add_data != prevProps.room_add_data) {
      if (this.props.room_add_data != null && this.props.room_add_data != "") {
        this.refreshRoom();
        if (this.props.room_add_data.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: this.props.room_add_data.message,
            roomFlag: true,
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: this.props.room_add_data.message,
            roomFlag: true,
          });
        }
      }
    }

    if (this.props.floor_delete_data != prevProps.floor_delete_data) {
      if (
        this.props.floor_delete_data != null &&
        this.props.floor_delete_data != ""
      ) {
        this.refreshFloor();
        if (this.props.floor_delete_data.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: this.props.floor_delete_data.message,
            floorFlag: true,
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: "Something went wrong",
          });
        }
      }
    }

    if (this.props.area_delete_data != prevProps.area_delete_data) {
      if (
        this.props.area_delete_data != null &&
        this.props.area_delete_data != ""
      ) {
        this.refreshArea();
        if (this.props.area_delete_data.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: this.props.area_delete_data.message,
            areaFlag: true,
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: this.props.area_delete_data.message,
            areaFlag: true,
          });
        }
      }
    }

    if (this.props.room_delete_data != prevProps.room_delete_data) {
      if (
        this.props.room_delete_data != null &&
        this.props.room_delete_data != ""
      ) {
        this.refreshRoom();
        if (this.props.room_delete_data.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: this.props.room_delete_data.message,
            roomFlag: true,
          });
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: this.props.room_delete_data.message,
            roomFlag: true,
          });
        }
      }
    }

    if (this.props.bulk_upload != prevProps.bulk_upload) {
      if (this.props.bulk_upload != null && this.props.bulk_upload != "") {
        if (this.props.bulk_upload.success) {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "success",
            alertMessageText: this.props.bulk_upload.message,
            noSelectedFile: true,
          });
          document.getElementById("bulk_upload").value = "";
        } else {
          this.setState({
            alertMessageVisible: true,
            alertMessageColor: "danger",
            alertMessageText: this.props.bulk_upload.message,
          });
        }
      }
    }
  }

  componentDidMount() {
    //var id="1"
    var id = sessionStorage.getItem("role_id");
    //console.log(id);
    const { dispatch } = this.props;
    dispatch(fetchStateList(id));
    //dispatch(fetchFacilityList(id))
  }

  toggle() {
    this.setState({
      loading: !this.state.loading,
      progress: 0.5,
    });
  }
  btnAddloading() {
    this.setState({
      btnAddloading: !this.state.btnAddloading,
      progress: 0.5,
    });
  }

  btnEditloading() {
    this.setState({
      btnEditloading: !this.state.btnEditloading,
      progress: 0.5,
    });
  }
  toogleFloorModelClose() {
    this.setState({
      floorFlag: true,
    });
  }

  toogleBulkModelClose() {
    this.setState({
      showBulkTable: true,
    });
  }

  toogleAreaModelClose() {
    this.setState({
      areaFlag: true,
    });
  }
  toogleRoomModelClose() {
    this.setState({
      roomFlag: true,
    });
  }

  AddBulkData(e) {
    var get_value = e.target.value;

    if (get_value === "bulkadd")
      this.setState({
        showBulkTable: false,
      });
    this.validator.hideMessages();
  }

  onChangeHandler = (e) => {
    let file = e.target.files[0];
    let str = file.name;
    let extension = str.substr(str.indexOf("."));
    if (file) {
      if (extension === ".csv") {
        getBase64(
          file,
          (result) => {
            this.setState({
              setFileErr: false,
              selectedCSVFiles: result,
              noSelectedFile: false,
            });
          },
          true
        );
      } else {
        e.target.value = null;
        this.setState({
          setFileErr: true,
          noSelectedFile: true,
        });
      }
    }
  };

  onClickHandler = () => {
    const data = this.state.selectedCSVFiles;
    // data.append('file',this.state.selectedFiles);
    const { dispatch } = this.props;
    var post = {
      file: data,
    };
    dispatch(bulkUploadFacility(post));
  };

  AddtoogleFloorModel(e) {
    var get_value = e.target.value;

    if (get_value === "btnadd")
      this.setState({
        floor_id: "",
        floor_name: "",
        floorFlag: false,
        btnName: "Add",
        alertMessageVisible: false,
        showDefaultTable: true,
        showFloorTable: false,
        showAreaTable: true,
        showRoomTable: true,
        showFacilitySetupTable: true,
        areaFlag: true,
        roomFlag: true,
        undefined_msg: false,
      });
    this.validator.hideMessages();
  }

  changeTxtFloor = (e) => {
    var that = this;
    var val = e.target.value;
    var pattern = /^[0-9@!#\$\^%&*()+=\-\[\]\\\';,\.\/\{\}\|\":<>\? ]+$/;
    if (pattern.test(val)) {
      this.setState({
        is_valid_floor_text: false,
      });
    } else {
      this.setState({
        is_valid_floor_text: true,
      });
    }

    this.setState({
      floor_name: e.target.value,
      setFloorFocus: true,
    });
  };

  changeTxtArea = (e) => {
    var that = this;
    var val = e.target.value;
    var pattern = /^[0-9@!#\$\^%&*()+=\-\[\]\\\';,\.\/\{\}\|\":<>\? ]+$/;
    if (pattern.test(val)) {
      this.setState({
        is_valid_area_text: false,
      });
    } else {
      this.setState({
        is_valid_area_text: true,
      });
    }

    this.setState({
      area_name: e.target.value,
      setAreaFocus: true,
    });
  };

  changeTxtRoom = (e) => {
    var that = this;
    var val = e.target.value;
    var pattern = /^[0-9@!#\$\^%&*()+=\-\[\]\\\';,\.\/\{\}\|\":<>\? ]+$/;
    if (pattern.test(val)) {
      this.setState({
        is_valid_room_text: false,
      });
    } else {
      this.setState({
        is_valid_room_text: true,
      });
    }

    this.setState({
      room_name: e.target.value,
      setRoomFocus: true,
    });
  };

  toggleFloorModal = (e) => {
    var that = this;

    var floor_id = e.target.value;
    if (floor_id !== "" && floor_id !== undefined) {
      that.setState({
        floor_id: floor_id,
        btnName: "Update",
        floorFlag: false,
        setQusFocus: true,
        is_valid_floor_text: true,
      });
      {
        /* that.setState({
                        btnAddloading: !that.state.btnAddloading,
                        progress: 0.5,
                    }); */
      }

      // call API to get floor details
      this.props.dispatch(getFloorById(floor_id));

      that.setState({
        btnAddloading: false,
        alertMessageVisible: false,
        undefined_msg: false,
      });
    } else {
      that.setState({
        modal: true,
        errorEmail: true,
        alertMessageVisible: false,
        undefined_msg: true,
      });
    }
  };

  toggleAreaModal = (e) => {
    var that = this;

    var area_id = e.target.value;
    if (area_id !== "" && area_id !== undefined) {
      that.setState({
        area_id: area_id,
        btnName: "Update",
        areaFlag: false,
        setQusFocus: true,
        is_valid_area_text: true,
      });
      {
        /* that.setState({
                        btnAddloading: !that.state.btnAddloading,
                        progress: 0.5,
                    }); */
      }

      // call API to get area details
      this.props.dispatch(getAreaById(area_id));

      that.setState({
        btnAddloading: false,
        alertMessageVisible: false,
        undefined_msg: false,
      });
    } else {
      that.setState({
        modal: true,
        errorEmail: true,
        alertMessageVisible: false,
        undefined_msg: true,
      });
    }

    {
      /*this.setState({
                     floorFlag: true,
                     areaFlag: false,
                     roomFlag: true,
                     btnName:"Update",
                     showDefaultTable: true
                 });*/
    }
  };

  toggleRoomModal = (e) => {
    var that = this;

    var room_id = e.target.value;
    console.log("edit" + room_id);
    if (room_id !== "" && room_id !== undefined) {
      that.setState({
        room_id: room_id,
        btnName: "Update",
        roomFlag: false,
        setQusFocus: true,
        is_valid_room_text: true,
      });
      {
        /* that.setState({
                        btnAddloading: !that.state.btnAddloading,
                        progress: 0.5,
                    }); */
      }

      // call API to get room details
      this.props.dispatch(getRoomById(room_id));

      that.setState({
        btnAddloading: false,
        alertMessageVisible: false,
        undefined_msg: false,
      });
    } else {
      that.setState({
        modal: true,
        errorEmail: true,
        alertMessageVisible: false,
        undefined_msg: true,
      });
    }

    {
      /*this.setState({
                     floorFlag: true,
                     areaFlag: true,
                     roomFlag: false,
                     btnName:"Update",
                     showDefaultTable: true
                 });*/
    }
  };

  // for open delete modal
  toggleFloorDeleteModal = (e, str) => {
    var floor_id = e.target.value;
    this.setState({
      deleteModal: !this.state.deleteModal,
      del_floor_id: floor_id,
      del_modal_name: "floor",
      undefined_msg: false,
    });
  };
  toggleAreaDeleteModal = (e, str) => {
    var area_id = e.target.value;
    this.setState({
      deleteModal: !this.state.deleteModal,
      del_area_id: area_id,
      del_modal_name: "area",
      undefined_msg: false,
    });
  };
  toggleRoomDeleteModal = (e, str) => {
    var room_id = e.target.value;
    this.setState({
      deleteModal: !this.state.deleteModal,
      del_room_id: room_id,
      del_modal_name: "room",
      undefined_msg: false,
    });
  };

  // for close modal
  toogleDeleteModelClose = (e) => {
    this.setState({
      deleteModal: false,
    });
  };

  clickBtnDelete = (e) => {
    var del_modal_name = this.state.del_modal_name;
    if (del_modal_name == "floor") {
      // for delete floor
      var del_floor_id = this.state.del_floor_id;
      this.props.dispatch(removeFloor(del_floor_id));
    } else if (del_modal_name == "area") {
      // for delete area
      var del_area_id = this.state.del_area_id;
      this.props.dispatch(removeArea(del_area_id));
    } else if (del_modal_name == "room") {
      // for delete room
      var del_room_id = this.state.del_room_id;
      this.props.dispatch(removeRoom(del_room_id));
    } else {
    }
    this.setState({
      deleteModal: false,
    });
  };

  toggleFacilitySetupModal = (e) => {
    this.setState({
      floorFlag: true,
      areaFlag: true,
      roomFlag: false,
      showFacilitySetupTable: true,
      undefined_msg: false,
    });
  };

  showFloorTable = (e) => {
    var facility_id = this.state.drpFacilityValue;
    var state_id = this.state.drpStateValue;
    if (facility_id !== "" && state_id !== "") {
      this.setState({
        loading: !this.state.loading,
        progress: 0.5,
        loaderStatus: false,
        globalFacId: facility_id,
        globalStateId: state_id,
      });
      // fetch all floors, areas, rooms by facilityId
      this.props.dispatch(fetchFloorList(facility_id));
      this.props.dispatch(fetchAreaList(facility_id));
      this.props.dispatch(fetchRoomList(facility_id));
      this.props.dispatch(fetchAreaRiskTypeList(state_id));

      this.setState({
        showFloorTable: false,
        showBtn: false,
        fac_err: true,
      });
    } else {
      this.setState({
        showFloorTable: true,
        showBtn: true,
        fac_err: false,
        showDefaultTable: false,
        floorFlag: true,
        areaFlag: true,
        roomFlag: true,
      });
      this.validator.showMessages();
      //console.log("error");
    }
    this.setState({
      showAreaTable: true,
      showRoomTable: true,
      floorFlag: true,
      areaFlag: true,
      roomFlag: true,
      btnName: "Add",
      showDefaultTable: true,
      showFacilitySetupTable: true,
      alertMessageVisible: false,
      undefined_msg: false,
    });
  };

  showAreaTable = (e) => {
    //console.log("area calling");
    //var floor_id = e.target.value;
    //this.props.dispatch(fetchAreaList(floor_id));
    //var facility_id = this.state.facilityId;
    //this.props.dispatch(fetchAreaList(facility_id));
    //this.props.dispatch(fetchAreaRiskTypeList());
    var that = this;
    var floor_id = e.target.value;
    //console.log("floor "+floor_id);
    if (floor_id !== "") {
      that.setState({
        drpFloorValue: floor_id,
        floor_id: floor_id,
      });
    } else {
      that.setState({
        drpFloorValue: "",
        floor_id: "",
      });
    }
    //console.log(that.state.drpFloorValue);
    //console.log(that.state.floor_id);

    that.setState({
      showFloorTable: true,
      showAreaTable: false,
      showRoomTable: true,
      floorFlag: true,
      areaFlag: false,
      roomFlag: true,
      btnName: "Add",
      showDefaultTable: true,
      showFacilitySetupTable: true,
      alertMessageVisible: false,
      area_id: "",
      area_type: "",
      area_name: "",
      drpAreaTypeValue: "",
      undefined_msg: false,
    });
    that.validator.hideMessages();
  };

  showRoomTable = (e) => {
    //console.log("room calling");
    //var facility_id = this.state.facilityId;
    //this.props.dispatch(fetchAreaList(facility_id));
    //this.props.dispatch(fetchRoomList(facility_id));
    var that = this;
    var area_id = e.target.value;
    //console.log("area "+area_id);
    if (area_id !== "") {
      //var floor_id = that.state.drpFloorValue;
      //console.log("floor "+floor_id);
      //this.props.dispatch(fetchAreaListByFloor(floor_id));
      this.props.dispatch(getAreaById(area_id));
      that.setState({
        drpAreaValue: area_id,
        area_id: area_id,
      });
    } else {
      that.setState({
        drpAreaValue: "",
        area_id: "",
      });
    }

    that.setState({
      showFloorTable: true,
      showAreaTable: true,
      showRoomTable: false,
      floorFlag: true,
      areaFlag: true,
      roomFlag: false,
      btnName: "Add",
      showDefaultTable: true,
      showFacilitySetupTable: true,
      alertMessageVisible: false,
      floor_id: "",
      room_id: "",
      drpFloorValue: "",
      room_name: "",
      undefined_msg: false,
    });
    that.validator.hideMessages();
  };

  showFacilitySetupTable = (e) => {
    var facility_id = this.state.drpFacilityValue;
    var state_id = this.state.drpStateValue;

    if (facility_id !== "" && state_id !== "") {
      this.props.dispatch(fetchFloorList(facility_id));
      this.setState({
        showFacilitySetupTable: false,
        showBtn: false,
        fac_err: true,
      });
    } else {
      this.setState({
        showFacilitySetupTable: true,
        showBtn: true,
        fac_err: false,
        showDefaultTable: false,
        floorFlag: true,
        areaFlag: true,
        roomFlag: true,
      });
      this.validator.showMessages();
      //console.log("error");
    }
    this.setState({
      showAreaTable: true,
      showRoomTable: true,
      floorFlag: true,
      areaFlag: true,
      roomFlag: true,
      btnName: "Add",
      showDefaultTable: true,
      showFloorTable: true,
      alertMessageVisible: false,
      undefined_msg: false,
    });
  };

  drpClickFacility = (e) => {
    var facility_id = e.target.value;
    var facility_name = "";
    if (facility_id != "") {
      facility_name = e.target.options[e.target.selectedIndex].text;
    }

    //console.log(facility_name);
    this.setState({
      drpFacilityValue: facility_id,
      drpFacName: facility_name,
    });

    if (facility_id === "") {
      this.setState({
        facilityId: "",
        newQus: true,
        alertMessageVisible: false,
        data: [],
      });
    } else {
      if (facility_id > 0) {
        this.setState({
          facilityId: facility_id,
        });
      }
    }
  };

  drpClickState = (e) => {
    this.setState({
      drpStateValue: e.target.value,
      drpFacilityValue: "",
    });
    var state_id = e.target.value;
    if (state_id === "") {
      this.setState({
        stateId: "",
        newQus: true,
        alertMessageVisible: false,
        data: [],
      });
    } else {
      if (state_id > 0) {
        this.setState({
          stateId: state_id,
        });
        //console.log("state_"+state_id);
        this.props.dispatch(fetchFacilityList(state_id));
      }
    }
  };

  drpChangeFloor = (e) => {
    var floor_id = e.target.value;
    //console.log(floor_id);
    this.setState({
      drpFloorValue: floor_id,
      floor_id: floor_id,
      drpAreaValue: "",
      area_id: "",
    });
    //call API to get area by floor
    this.props.dispatch(fetchAreaListByFloor(floor_id));
  };

  drpChangeAreaType = (e) => {
    var area_id = e.target.value;
    //console.log(area_id);
    this.setState({
      drpAreaTypeValue: area_id,
      area_type: area_id,
    });
  };

  drpChangeArea = (e) => {
    var area_id = e.target.value;
    //console.log(area_id);
    this.setState({
      drpAreaValue: area_id,
      area_id: area_id,
    });
  };

  clickBtnAddFloor = (e) => {
    var that = this;
    if (that.validator.allValid()) {
    } else {
      that.validator.showMessages();
      // rerender to show messages for the first time
      that.forceUpdate();
    }
    var floor_id = that.state.floor_id;
    var state_id = that.state.stateId;
    var facility_id = that.state.facilityId;
    var floor_name = that.state.floor_name;
    if (floor_id === "") {
      // add floor
      if (
        state_id !== "" &&
        facility_id !== "" &&
        floor_name !== "" &&
        that.state.is_valid_floor_text === true
      ) {
        that.setState({
          btnAddloading: !that.state.btnAddloading,
          progress: 0.5,
        });

        // call API to add floor
        var post = {
          facility_id: facility_id,
          name: floor_name,
        };
        this.props.dispatch(addNewFloor(post));
        that.setState({
          btnAddloading: false,
        });
      } else {
        that.setState({
          modal: true,
          errorEmail: true,
        });
      }
    } // update floor
    else {
      if (
        state_id !== "" &&
        facility_id !== "" &&
        floor_name !== "" &&
        that.state.is_valid_floor_text === true
      ) {
        that.setState({
          btnAddloading: !that.state.btnAddloading,
          progress: 0.5,
        });

        var facility_id = that.state.facilityId;
        var name = that.state.floor_name;
        var post = {
          facility_id: facility_id,
          name: name,
          id: floor_id,
        };

        // call API to update floor
        this.props.dispatch(updateFloorById(post));

        that.setState({
          btnAddloading: false,
        });
      } else {
        that.setState({
          modal: true,
          errorEmail: true,
        });
      }
    }
  };

  clickBtnAddArea = (e) => {
    var that = this;
    if (that.validator.allValid()) {
    } else {
      that.validator.showMessages();
      // rerender to show messages for the first time
      that.forceUpdate();
    }
    var area_id = that.state.area_id;
    var floor_id = that.state.floor_id;
    var area_risk_type_id = that.state.area_type;
    var name = that.state.area_name;
    // console.log(area_id)
    // console.log(floor_id)
    // console.log(area_risk_type_id)
    // console.log(name)
    if (area_id === "") {
      // add area
      if (
        floor_id !== "" &&
        area_risk_type_id !== "" &&
        name !== "" &&
        that.state.is_valid_area_text === true
      ) {
        that.setState({
          btnAddloading: !that.state.btnAddloading,
          progress: 0.5,
        });

        // call API to add area
        var post = {
          floor_id: floor_id,
          area_risk_type_id: area_risk_type_id,
          name: name,
        };
        this.props.dispatch(addNewArea(post));
        that.setState({
          btnAddloading: false,
        });
      } else {
        that.setState({
          modal: true,
          errorEmail: true,
        });
      }
    } // update area
    else {
      if (
        floor_id !== "" &&
        area_risk_type_id !== "" &&
        name !== "" &&
        that.state.is_valid_area_text === true
      ) {
        that.setState({
          btnAddloading: !that.state.btnAddloading,
          progress: 0.5,
        });
        //console.log(floor_id);
        //console.log(area_risk_type_id);
        //console.log(name);
        var post = {
          id: area_id,
          floor_id: floor_id,
          area_risk_type_id: area_risk_type_id,
          name: name,
        };

        // call API to update area
        this.props.dispatch(updateAreaById(post));

        that.setState({
          btnAddloading: false,
        });
      } else {
        that.setState({
          modal: true,
          errorEmail: true,
        });
      }
    }
  };

  clickBtnAddRoom = (e) => {
    var that = this;
    if (that.validator.allValid()) {
    } else {
      that.validator.showMessages();
      // rerender to show messages for the first time
      that.forceUpdate();
    }
    var room_id = that.state.room_id;
    var area_id = that.state.area_id;
    var name = that.state.room_name;
    var floor_id = that.state.drpFloorValue;
    //console.log(room_id);
    //console.log(area_id);
    //console.log(name);
    if (room_id === "") {
      // add room
      //console.log("add room");
      if (
        floor_id !== "" &&
        area_id !== "" &&
        name !== "" &&
        that.state.is_valid_room_text === true
      ) {
        that.setState({
          btnAddloading: !that.state.btnAddloading,
          progress: 0.5,
        });

        // call API to add room
        var post = {
          area_id: area_id,
          name: name,
        };
        this.props.dispatch(addNewRoom(post));
        that.setState({
          btnAddloading: false,
        });
      } else {
        that.setState({
          modal: true,
          errorEmail: true,
        });
      }
    } // update room
    else {
      //console.log("edit room");
      if (
        floor_id !== "" &&
        area_id !== "" &&
        name !== "" &&
        that.state.is_valid_room_text === true
      ) {
        that.setState({
          btnAddloading: !that.state.btnAddloading,
          progress: 0.5,
        });

        var post = {
          id: room_id,
          area_id: area_id,
          name: name,
        };

        // call API to update area
        this.props.dispatch(updateRoomById(post));

        that.setState({
          btnAddloading: false,
        });
      } else {
        that.setState({
          modal: true,
          errorEmail: true,
        });
      }
    }
  };

  refreshFloor() {
    var facility_id = this.state.globalFacId; // drpFacilityValue
    var state_id = this.state.globalStateId; // drpStateValue
    if (facility_id !== "" && state_id !== "") {
      // call API to refresh table
      this.props.dispatch(fetchFloorList(facility_id));
    }
  }

  refreshArea() {
    var facility_id = this.state.globalFacId; // drpFacilityValue
    var state_id = this.state.globalStateId; // drpStateValue
    if (facility_id !== "" && state_id !== "") {
      // call API to refresh table
      this.props.dispatch(fetchAreaList(facility_id));
    }
  }

  refreshRoom() {
    var facility_id = this.state.globalFacId; // drpFacilityValue
    var state_id = this.state.globalStateId; // drpStateValue
    if (facility_id !== "" && state_id !== "") {
      // call API to refresh table
      this.props.dispatch(fetchRoomList(facility_id));
    }
  }

  onDismiss() {
    var that = this;
    that.setState({
      alertMessageVisible: false,
    });
  }
  /*all event and state*/

  render() {
    const {
      dispatch,
      state_data,
      facility_data,
      floor_data,
      area_data,
      area_data_by_floor,
      area_risk_type_data,
      room_data,
    } = this.props;
    //console.log(state_data);
    //console.log(facility_data);
    //console.log(floor_data);

    const ViewHeader = () => (
      <div className="view-header">
        <header className="title text-white">
          <h1 style={{ color: "white" }} className="h4 text-uppercase">
            {" "}
            Facility Setup{" "}
          </h1>
          <p className="mb-0">
            {" "}
            Manage audit related floors, functional area & rooms here
          </p>
        </header>
      </div>
    );

    const StateFacilityDropDownList = () => (
      <div>
        {/* <div className = "row">
                        <div className = "col-md-12 text-right">
                            <button className = "btn btn-sm btn-warning pointerClass marginRight" value="bulkadd" onClick = {this.AddBulkData}><i className="fa fa-plus lg"></i> &nbsp; Add Bulk Data </button>
                        </div>
                    </div> */}
        <div className="row">
          <div className="col-md-4 ">
            <h6 className="mb-4 text-uppercase"> Select State </h6>
            <StateListClass
              stateValue={this.state.drpStateValue}
              drpChangeState={this.drpClickState}
              stateFillData={state_data}
            />
            <span className="error-text">
              {this.validator.message(
                "State",
                this.state.drpStateValue,
                "required"
              )}
            </span>
          </div>
          <div className="col-md-4 ">
            <h6 className="mb-4 text-uppercase"> Select Facility</h6>
            <FacilityListClass
              facilityValue={this.state.drpFacilityValue}
              drpChangeFac={this.drpClickFacility}
              facilityFillData={facility_data}
            />
            <span className="error-text">
              {this.validator.message(
                "Facility",
                this.state.drpFacilityValue,
                "required"
              )}
            </span>
          </div>

          <div className="col-md-4 btnmarginTop">
            <LaddaButton
              className="btn btn-warning load-fac-btn marginRight_10"
              loading={this.state.loading}
              onClick={this.showFloorTable}
              data-color="#eee"
              data-size={S}
              data-style={EXPAND_RIGHT}
              data-spinner-size={30}
              data-spinner-color="#ddd"
              data-spinner-lines={12}
            >
              Load Setup
            </LaddaButton>
            <LaddaButton
              className="btn btn-primary load-fac-btn"
              onClick={this.showFacilitySetupTable}
            >
              View Facility Setup
            </LaddaButton>
          </div>
        </div>
      </div>
    );

    const AlertMessage = () => (
      <AlertMessageClass
        alertMessageColor={this.state.alertMessageColor}
        alertMessageVisible={this.state.alertMessageVisible}
        onDismiss={this.onDismiss}
        alertMessageText={this.state.alertMessageText}
      />
    );

    const FloorTable = () => (
      <FloorTableClass
        floorFillData={floor_data}
        openModal={this.showAreaTable}
        toggleModal={this.toggleFloorModal}
        toggleDeleteModal={this.toggleFloorDeleteModal}
      />
    );

    const AreaTable = () => (
      <AreaTableClass
        areaFillData={area_data}
        openModal={this.showRoomTable}
        toggleModal={this.toggleAreaModal}
        toggleDeleteModal={this.toggleAreaDeleteModal}
      />
    );

    const RoomTable = () => (
      <RoomTableClass
        roomFillData={room_data}
        toggleModal={this.toggleRoomModal}
        toggleDeleteModal={this.toggleRoomDeleteModal}
      />
    );

    const DefaultTable = () => (
      <table className="table">
        <thead>
          <tr>
            <th> Id </th>
            <th> Floor </th>
            <th> Action </th>
          </tr>
        </thead>

        <tbody></tbody>
      </table>
    );

    const FacilitySetupTable = () => (
      <FacilitySetupTableClass
        facilityFillData={this.state.data}
        toggleModal={this.toggleFacilitySetupModal}
        fac_val={this.state.drpFacilityValue}
        fac_name={this.state.drpFacName}
        floor_data={floor_data}
      />
    );

    return (
      <div className="view">
        <ViewHeader />

        <ViewContent>
          <Card className="mb-4">
            <CardBlock className="table-responsive">
              <div className="row">
                <div className="col-md-12 text-right">
                  <button
                    className="btn btn-sm btn-warning pointerClass marginRight"
                    value="bulkadd"
                    onClick={this.AddBulkData}
                  >
                    <i className="fa fa-plus lg"></i> &nbsp; Add Bulk Data{" "}
                  </button>
                </div>
              </div>
              <StateFacilityDropDownList />
            </CardBlock>
          </Card>

          <div className="row pull-right marginBtm" hidden={this.state.showBtn}>
            <div className="col-md-12">
              <button
                className="btn btn-sm btn-warning pointerClass marginRight"
                value="btnadd"
                onClick={this.AddtoogleFloorModel}
              >
                <i className="fa fa-plus lg"></i> &nbsp; Add New Floor{" "}
              </button>
              <button
                className="btn btn-sm btn-warning pointerClass marginRight"
                value=""
                onClick={this.showAreaTable}
              >
                <i className="fa fa-plus lg"></i> &nbsp; Add New Area{" "}
              </button>
              <button
                className="btn btn-sm btn-warning pointerClass "
                value=""
                onClick={this.showRoomTable}
              >
                <i className="fa fa-plus lg"></i> &nbsp; Add New Room{" "}
              </button>
            </div>
          </div>
          <br />

          <Card className="mb-4 col-md-12" hidden={this.state.showBulkTable}>
            <div className="row">
              <CardBlock className="table-responsive">
                <h6 className="mb-4 text-uppercase text-center">
                  Add Bulk Data
                </h6>

                <div className="row">
                  <h6 className="mb-4 offset-md-6 text-uppercase text-right">
                    <a href={src} target="_blank">
                      <button className="btn btn-sm btn-success">
                        Download Sample Sheet
                      </button>
                    </a>
                  </h6>
                  <div className="col-md-12">
                    <div className="col-md-6 offset-md-3">
                      <FormGroup>
                        <Input
                          id="bulk_upload"
                          type="file"
                          accept=".csv"
                          name="file"
                          ref={this.fileInput}
                          //  files = {this.state.bulk_upload_file || ''}
                          onChange={this.onChangeHandler}
                        />
                      </FormGroup>
                      {this.state.setFileErr === true ? (
                        <Alert color="danger">
                          Please upload csv file only
                        </Alert>
                      ) : (
                        ""
                      )}

                      <div className="col-md-12 text-center">
                        <LaddaButton
                          className="btn btn-primary"
                          disabled={
                            this.state.setFileErr == true ||
                            this.state.noSelectedFile == true
                          }
                          onClick={() => this.onClickHandler()}
                          data-color="#eee"
                          data-size={S}
                          data-style={EXPAND_RIGHT}
                          data-spinner-size={30}
                          data-spinner-color="#ddd"
                          data-spinner-lines={12}
                        >
                          {" "}
                          Add{" "}
                        </LaddaButton>
                        &nbsp;
                        <Button
                          color="default"
                          className="btn pointerClass cancel_btn"
                          onClick={this.toogleBulkModelClose}
                        >
                          {" "}
                          Cancel{" "}
                        </Button>
                        <div className="bulk-upload">
                          <AlertMessage />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBlock>
            </div>
          </Card>

          <Card className="mb-4 col-md-12" hidden={this.state.floorFlag}>
            <div className="row">
              <CardBlock className="table-responsive">
                <h6 className="mb-4 text-uppercase text-center">
                  {" "}
                  {this.state.btnName} Floor
                </h6>

                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-6 offset-md-3">
                      <FormGroup>
                        <Label>Floor Name</Label>
                        <Input
                          type="textarea"
                          name="floor_name"
                          value={this.state.floor_name}
                          id="floor_name"
                          className="form-control"
                          placeholder="Floor Name"
                          onChange={this.changeTxtFloor}
                          autoFocus={this.state.setFloorFocus}
                          onBlur={this.removeFocus}
                          maxLength="200"
                        />
                        <span className="error-text">
                          {this.validator.message(
                            "floor name",
                            this.state.floor_name,
                            "required|custom_text",
                            false,
                            {
                              custom_text:
                                "This field may only contain alphabetic characters and number.",
                              default: "This field is required.",
                            }
                          )}
                        </span>
                      </FormGroup>
                    </div>

                    <div className="col-md-12 text-center">
                      <LaddaButton
                        className="btn btn-primary"
                        onClick={this.clickBtnAddFloor}
                        loading={this.state.btnAddloading}
                        data-color="#eee"
                        data-size={S}
                        data-style={EXPAND_RIGHT}
                        data-spinner-size={30}
                        data-spinner-color="#ddd"
                        data-spinner-lines={12}
                      >
                        {" "}
                        {this.state.btnName}{" "}
                      </LaddaButton>
                      &nbsp;
                      <Button
                        color="default"
                        className="btn pointerClass cancel_btn"
                        onClick={this.toogleFloorModelClose}
                      >
                        {" "}
                        Cancel{" "}
                      </Button>
                    </div>
                  </div>
                </div>
              </CardBlock>
            </div>
          </Card>

          <Card className="mb-4 col-md-12" hidden={this.state.areaFlag}>
            <div className="row">
              <CardBlock className="table-responsive">
                <h6 className="mb-4 text-uppercase text-center">
                  {" "}
                  {this.state.btnName} Area
                </h6>

                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-6 offset-md-3">
                      <FormGroup>
                        <Label>Select Floor</Label>
                        <Input
                          type="select"
                          name="drpFloorValue"
                          id="drpFloorValue"
                          value={this.state.drpFloorValue}
                          onChange={this.drpChangeFloor}
                        >
                          <option value="">--Select Floor--</option>
                          {this.props.floor_data.map((item, i) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                          {/* <option value="1">Floor1</option>
                                                <option value="2">Floor2</option>
                                                <option value="3">Floor3</option>
                                                <option value="4">Floor4</option>
                                                <option value="5">Floor5</option> */}
                        </Input>
                        <span className="error-text">
                          {this.validator.message(
                            "floor",
                            this.state.drpFloorValue,
                            "required"
                          )}
                        </span>
                        <br />

                        <Label>Functional Area Type</Label>
                        <Input
                          type="select"
                          name="drpAreaTypeValue"
                          id="drpAreaTypeValue"
                          value={this.state.drpAreaTypeValue}
                          onChange={this.drpChangeAreaType}
                        >
                          <option value="">
                            --Select Functional Area Type--
                          </option>
                          {this.props.area_risk_type_data.map((item, i) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                          {/* <option value="1">Area1</option>
                                                <option value="2">Area2</option>
                                                <option value="3">Area3</option> */}
                        </Input>
                        <span className="error-text">
                          {this.validator.message(
                            "area type",
                            this.state.drpAreaTypeValue,
                            "required"
                          )}
                        </span>
                        <br />

                        <Label>Functional Area Name</Label>
                        <Input
                          type="textarea"
                          name="area_name"
                          value={this.state.area_name}
                          id="area_name"
                          className="form-control"
                          placeholder="Area Name"
                          onChange={this.changeTxtArea}
                          autoFocus={this.state.setAreaFocus}
                          onBlur={this.removeFocus}
                          maxLength="100"
                        />
                        <span className="error-text">
                          {this.validator.message(
                            "area name",
                            this.state.area_name,
                            "required|custom_text",
                            false,
                            {
                              custom_text:
                                "This field may only contain alphabetic characters and number.",
                              default: "This field is required.",
                            }
                          )}
                        </span>
                      </FormGroup>
                    </div>

                    <div className="col-md-12 text-center">
                      <LaddaButton
                        className="btn btn-primary"
                        onClick={this.clickBtnAddArea}
                        loading={this.state.btnAddloading}
                        data-color="#eee"
                        data-size={S}
                        data-style={EXPAND_RIGHT}
                        data-spinner-size={30}
                        data-spinner-color="#ddd"
                        data-spinner-lines={12}
                      >
                        {" "}
                        {this.state.btnName}{" "}
                      </LaddaButton>
                      &nbsp;
                      <Button
                        color="default"
                        className="pointerClass cancel_btn"
                        onClick={this.toogleAreaModelClose}
                      >
                        {" "}
                        Cancel{" "}
                      </Button>
                    </div>
                  </div>
                </div>
              </CardBlock>
            </div>
          </Card>

          <Card className="mb-4 col-md-12" hidden={this.state.roomFlag}>
            <div className="row">
              <CardBlock className="table-responsive">
                <h6 className="mb-4 text-uppercase text-center">
                  {" "}
                  {this.state.btnName} Room
                </h6>

                <div className="row">
                  <div className="col-md-12">
                    <div className="col-md-6 offset-md-3">
                      <FormGroup>
                        <Label>Select Floor</Label>
                        <Input
                          type="select"
                          name="drpFloorValue"
                          id="drpFloorValue"
                          value={this.state.drpFloorValue}
                          onChange={this.drpChangeFloor}
                        >
                          <option value="">--Select Floor--</option>
                          {this.props.floor_data.map((item, i) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </Input>
                        <span className="error-text">
                          {this.validator.message(
                            "floor",
                            this.state.drpFloorValue,
                            "required"
                          )}
                        </span>
                        <br />

                        <Label>Select Functional Area</Label>
                        <Input
                          type="select"
                          name="drpAreaValue"
                          id="drpAreaValue"
                          value={this.state.drpAreaValue}
                          onChange={this.drpChangeArea}
                        >
                          <option value="">--Select Functional Area--</option>
                          {this.props.area_data_by_floor.map((item, i) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                          {/* this.props.area_data_by_floor */}
                          {/*<option value="1">Area1</option> 
                                                <option value="2">Area2</option>
                                                <option value="3">Area3</option>
                                                <option value="4">Area4</option>
                                                <option value="5">Area5</option>*/}
                        </Input>
                        <span className="error-text">
                          {this.validator.message(
                            "area",
                            this.state.drpAreaValue,
                            "required"
                          )}
                        </span>
                        <br />

                        <Label>Room Name</Label>
                        <Input
                          type="textarea"
                          name="room_name"
                          value={this.state.room_name}
                          id="room_name"
                          className="form-control"
                          placeholder="Room Name"
                          onChange={this.changeTxtRoom}
                          autoFocus={this.state.setRoomFocus}
                          onBlur={this.removeFocus}
                          maxLength="100"
                        />
                        <span className="error-text">
                          {this.validator.message(
                            "room name",
                            this.state.room_name,
                            "required|custom_text",
                            false,
                            {
                              custom_text:
                                "This field may only contain alphabetic characters and number.",
                              default: "This field is required.",
                            }
                          )}
                        </span>
                      </FormGroup>
                    </div>

                    <div className="col-md-12 text-center">
                      <LaddaButton
                        className="btn btn-primary"
                        onClick={this.clickBtnAddRoom}
                        loading={this.state.btnAddloading}
                        data-color="#eee"
                        data-size={S}
                        data-style={EXPAND_RIGHT}
                        data-spinner-size={30}
                        data-spinner-color="#ddd"
                        data-spinner-lines={12}
                      >
                        {" "}
                        {this.state.btnName}{" "}
                      </LaddaButton>
                      &nbsp;
                      <Button
                        color="default"
                        className="btn pointerClass cancel_btn"
                        onClick={this.toogleRoomModelClose}
                      >
                        {" "}
                        Cancel{" "}
                      </Button>
                    </div>
                  </div>
                </div>
              </CardBlock>
            </div>
          </Card>

          <Card className="mb-4 col-md-12" hidden={this.state.showFloorTable}>
            <CardBlock className="table-responsive">
              <AlertMessage />{" "}
              {/* <Alert color="success">This is a success alert — check it out!</Alert> */}
              <AlertMessageClass
                alertMessageColor="danger"
                alertMessageVisible={this.state.undefined_msg}
                onDismiss={this.onDismiss}
                alertMessageText="Something went wrong. Please try again."
              />
              <h6 className="mb-4 text-uppercase"> Manage Floors </h6>
              <LoaderClass visibleStatus={this.state.loaderStatus} />
              <div>
                <FloorTable hidden={this.state.floorDisFlag} />
              </div>
            </CardBlock>
          </Card>

          <Card className="mb-4 col-md-12" hidden={this.state.showAreaTable}>
            <CardBlock className="table-responsive">
              <AlertMessage />{" "}
              {/* <Alert color="success">This is a success alert — check it out!</Alert> */}
              <AlertMessageClass
                alertMessageColor="danger"
                alertMessageVisible={this.state.undefined_msg}
                onDismiss={this.onDismiss}
                alertMessageText="Something went wrong. Please try again."
              />
              <h6 className="mb-4 text-uppercase"> Manage Area </h6>
              <div>
                <AreaTable />
              </div>
              <LoaderClass visibleStatus={this.state.loaderStatus} />
            </CardBlock>
          </Card>

          <Card className="mb-4 col-md-12" hidden={this.state.showRoomTable}>
            <CardBlock className="table-responsive">
              <AlertMessage />{" "}
              {/* <Alert color="success">This is a success alert — check it out!</Alert> */}
              <AlertMessageClass
                alertMessageColor="danger"
                alertMessageVisible={this.state.undefined_msg}
                onDismiss={this.onDismiss}
                alertMessageText="Something went wrong. Please try again."
              />
              <h6 className="mb-4 text-uppercase"> Manage Rooms </h6>
              <div>
                <RoomTable />
              </div>
              <LoaderClass visibleStatus={this.state.loaderStatus} />
            </CardBlock>
          </Card>

          <Card className="mb-4 col-md-12" hidden={this.state.showDefaultTable}>
            <CardBlock className="table-responsive">
              <h6 className="mb-4 text-uppercase"> Manage Floors </h6>
              <DefaultTable />
            </CardBlock>
          </Card>

          <div hidden={this.state.showFacilitySetupTable}>
            <h6 className="mb-4 text-uppercase"> View Facility Setup </h6>
            <Card className="mb-4 col-md-12">
              <CardBlock className="table-responsive">
                <AlertMessage />{" "}
                {/* <Alert color="success">This is a success alert — check it out!</Alert> */}
                <div>
                  <FacilitySetupTable />
                </div>
                <LoaderClass visibleStatus={this.state.loaderStatus} />
              </CardBlock>
            </Card>
          </div>

          <Modal
            isOpen={this.state.deleteModal}
            toggle={this.toggleDeleteModal}
          >
            <ModalHeader toggle={this.toggleDeleteModal}>
              Confirmation Alert
            </ModalHeader>
            <ModalBody>Are you sure you want to delete ?</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.clickBtnDelete}>
                Yes
              </Button>{" "}
              <Button
                color=""
                className="pointerClass btn btn-warning"
                onClick={this.toogleDeleteModelClose}
              >
                No
              </Button>
            </ModalFooter>
          </Modal>
        </ViewContent>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { postsBySubreddit } = state;
  const {
    state_data: state_data,
    facility_data: facility_data,
    floor_data: floor_data,
    area_data: area_data,
    area_data_by_floor: area_data_by_floor,
    area_risk_type_data: area_risk_type_data,
    room_data: room_data,
    floor_details_by_id: floor_details_by_id,
    floor_add_data: floor_add_data,
    floor_update_data: floor_update_data,
    area_details_by_id: area_details_by_id,
    area_add_data: area_add_data,
    area_update_data: area_update_data,
    room_details_by_id: room_details_by_id,
    room_add_data: room_add_data,
    room_update_data: room_update_data,
    floor_delete_data: floor_delete_data,
    area_delete_data: area_delete_data,
    room_delete_data: room_delete_data,
    add_multiple_floor: add_multiple_floor,
    bulk_upload: bulk_upload,
  } = postsBySubreddit;
  return {
    state_data,
    facility_data,
    floor_data,
    area_data,
    area_data_by_floor,
    area_risk_type_data,
    room_data,
    floor_details_by_id,
    floor_add_data,
    floor_update_data,
    area_details_by_id,
    area_add_data,
    area_update_data,
    room_details_by_id,
    room_add_data,
    room_update_data,
    floor_delete_data,
    area_delete_data,
    room_delete_data,
    add_multiple_floor,
    bulk_upload,
  };
}

const AppContainer = connect(mapStateToProps)(FacilitySetup);

export default AppContainer;
