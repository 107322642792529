import Axios from "axios";
import { browserHistory } from "react-router";
import { deleteRequest, getRequest, postRequest, putRequest } from "../../utils/apiUtils";
var request = require("request");

// for question
export const RECEIVE_QUESTION = "RECEIVE_QUESTION";
export const ADD_QUESTION = "ADD_QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const GET_QUESTION = "GET_QUESTION";
export const UPDATE_QUESTION = "UPDATE_QUESTION";

// for question where bank
export const RECEIVE_QUESTION_WHERE = "RECEIVE_QUESTION_WHERE";
export const ADD_QUESTION_WHERE = "ADD_QUESTION_WHERE";
export const DELETE_WHERE_COMMENT = "DELETE_WHERE_COMMENT";
export const GET_WHERE_COMMENT = "GET_WHERE_COMMENT";
export const UPDATE_WHERE_COMMENT = "UPDATE_WHERE_COMMENT";

// for question why bank
export const RECEIVE_QUESTION_WHY = "RECEIVE_QUESTION_WHY";
export const ADD_QUESTION_WHY = "ADD_QUESTION_WHY";
export const DELETE_WHY_COMMENT = "DELETE_WHY_COMMENT";
export const GET_WHY_COMMENT = "GET_WHY_COMMENT";
export const UPDATE_WHY_COMMENT = "UPDATE_WHY_COMMENT";

// for question categories
export const RECEIVE_QUESTION_CATEGORIES = "RECEIVE_QUESTION_CATEGORIES";

// for question configuration
export const ADD_QUESTION_CONFIG = "ADD_QUESTION_CONFIG";
export const GET_QUESTION_CONFIG = "GET_QUESTION_CONFIG";
export const ADD_MULTIPLE_FLOOR = "ADD_MULTIPLE_FLOOR";
export const UPDATE_QUESTION_CONFIG = "UPDATE_QUESTION_CONFIG";

/* start fetch all question by state */
function fetch_questions_by_state(state_id, facility_id) {
  return async (dispatch) => {
    //var id=1;
    if (facility_id == "") {
      facility_id = "0";
    }
    return getRequest({ url: "api/managed_questions/" + state_id + "/" + facility_id})
      .then((response) => {
        if (response.data.result.length > 0) {
          dispatch(requestQuestion(response.data.result));
        } else {
          dispatch(requestQuestion([]));
        }
        //console.log(response.data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // browserHistory.push('/');
            // window.location.reload();
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestQuestion(response) {
  return {
    type: RECEIVE_QUESTION,
    question_data: response,
  };
}

export function fetchQuestionList(state_id, facility_id) {
  return (dispatch) => {
    return dispatch(fetch_questions_by_state(state_id, facility_id));
  };
}
/* end fetch all question by state */

/* start add question */

function add_question(post) {
  return async (dispatch) => {
    return await postRequest({ url: "api/managed_questions", payload: post})
      .then((response) => {
        if (response.data != "") {
          dispatch(addQuestion(response.data));
        } else {
          dispatch(addQuestion([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // browserHistory.push('/');
            // window.location.reload();
          } else {
            console.log(error);
          }
        }
      });
  };
}

function addQuestion(response) {
  return {
    type: ADD_QUESTION,
    question_add_data: response,
  };
}

export function addNewQuestion(post) {
  return (dispatch) => {
    return dispatch(add_question(post));
  };
}

/*end add question */

/* start delete question */
function deleteQuestion(id) {
  return async (dispatch) => {
    return await deleteRequest({ url: "/api/managed_questions/" + id})
      .then((response) => {
        dispatch(removeQuestionData(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // browserHistory.push('/');
            // window.location.reload();
          } else {
            console.log(error);
          }
        }
      });
  };
}

function removeQuestionData(response) {
  return {
    type: DELETE_QUESTION,
    question_delete_data: response,
  };
}

export function removeQuestion(id) {
  return (dispatch) => {
    return dispatch(deleteQuestion(id));
  };
}

/* end delete question */

/* start get question details by id */

function fetch_question_by_id(que_id) {
  return async (dispatch) => {
    return await getRequest({ url: "api/managed_questions/" + que_id})
      .then((response) => {
        //console.log(response);
        if (response.data != "") {
          dispatch(getQuestionDetails(response.data));
        } else {
          dispatch(getQuestionDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // browserHistory.push('/');
            // window.location.reload();
          } else {
            console.log(error);
          }
        }
      });
  };
}

function getQuestionDetails(response) {
  return {
    type: GET_QUESTION,
    fetch_question_by_id: response,
  };
}

export function getQuestionById(que_id) {
  return (dispatch) => {
    return dispatch(fetch_question_by_id(que_id));
  };
}

/*end get question details by id */

/* start update question */
function update_question(post) {
  return async (dispatch) => {
    return await putRequest({ url: "api/managed_questions", payload: post})
      .then((response) => {
        //console.log(response);
        if (response.data != "") {
          dispatch(updateQuestion(response.data));
        } else {
          dispatch(updateQuestion([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // browserHistory.push('/');
            // window.location.reload();
          } else {
            console.log(error);
          }
        }
      });
  };
}

function updateQuestion(response) {
  return {
    type: UPDATE_QUESTION,
    question_update_data: response,
  };
}

export function updateQuestionById(post) {
  return (dispatch) => {
    return dispatch(update_question(post));
  };
}

/*end update question */

/* start fetch all question where bank by state */
function fetch_que_where_bank(state_id, que_id) {
  return async (dispatch) => {
    return await getRequest({ url: "api/managed_que_wheres_bank/" + state_id + "/" + que_id})
      .then((response) => {
        if (response.data.result.length > 0) {
          dispatch(requestQuestionWhere(response.data.result));
        } else {
          dispatch(requestQuestionWhere([]));
        }
        //console.log(response.data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // browserHistory.push('/');
            // window.location.reload();
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestQuestionWhere(response) {
  return {
    type: RECEIVE_QUESTION_WHERE,
    question_where_data: response,
  };
}

export function fetchQuestionWhereList(state_id, que_id) {
  return (dispatch) => {
    return dispatch(fetch_que_where_bank(state_id, que_id));
  };
}
/* end fetch all question where bank by state */

/* start fetch all question why bank by state */
function fetch_que_why_bank(state_id, que_id) {
  return async (dispatch) => {
    return await getRequest({ url: "api/managed_que_whys_bank/" + state_id + "/" + que_id})
      .then((response) => {
        if (response.data.result.length > 0) {
          dispatch(requestQuestionWhy(response.data.result));
        } else {
          dispatch(requestQuestionWhy([]));
        }
        //console.log(response.data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // browserHistory.push('/');
            // window.location.reload();
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestQuestionWhy(response) {
  return {
    type: RECEIVE_QUESTION_WHY,
    question_why_data: response,
  };
}

export function fetchQuestionWhyList(state_id, que_id) {
  return (dispatch) => {
    return dispatch(fetch_que_why_bank(state_id, que_id));
  };
}
/* end fetch all question why bank by state */

/* start add question where bank */

function add_question_where(post) {
  return async (dispatch) => {
    return await postRequest({ url: "api/managed_que_wheres_bank", payload: post})
      .then((response) => {
        if (response.data != "") {
          dispatch(addQuestionWhere(response.data));
        } else {
          dispatch(addQuestionWhere([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // browserHistory.push('/');
            // window.location.reload();
          } else {
            console.log(error);
          }
        }
      });
  };
}

function addQuestionWhere(response) {
  return {
    type: ADD_QUESTION_WHERE,
    question_add_where_data: response,
  };
}

export function addNewQuestionWhere(post) {
  return (dispatch) => {
    return dispatch(add_question_where(post));
  };
}

/*end add question where bank */

/* start add question why bank */

function add_question_why(post) {
  return async (dispatch) => {
    return await postRequest({ url: "api/managed_que_whys_bank", payload: post})
      .then((response) => {
        if (response.data != "") {
          dispatch(addQuestionWhy(response.data));
        } else {
          dispatch(addQuestionWhy([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // browserHistory.push('/');
            // window.location.reload();
          } else {
            console.log(error);
          }
        }
      });
  };
}

function addQuestionWhy(response) {
  return {
    type: ADD_QUESTION_WHY,
    question_add_why_data: response,
  };
}

export function addNewQuestionWhy(post) {
  return (dispatch) => {
    return dispatch(add_question_why(post));
  };
}

/*end add question why bank */

/* start get why details by id */
function fetch_why_by_id(why_id) {
  return async (dispatch) => {
    return await getRequest({ url: "api/managed_que_whys_bank/" + why_id})
      .then((response) => {
        //console.log(response);
        if (response.data != "") {
          dispatch(getWhyDetails(response.data));
        } else {
          dispatch(getWhyDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // browserHistory.push('/');
            // window.location.reload();
          } else {
            console.log(error);
          }
        }
      });
  };
}

function getWhyDetails(response) {
  return {
    type: GET_WHY_COMMENT,
    fetch_why_by_id: response,
  };
}

export function getWhyById(why_id) {
  return (dispatch) => {
    return dispatch(fetch_why_by_id(why_id));
  };
}

/*end get why details by id */

/* start update why */

function update_why(post) {
  return async (dispatch) => {
    return await putRequest({ url: "api/managed_que_whys_bank_api_1", payload: post})
      .then((response) => {
        //console.log(response);
        if (response.data != "") {
          dispatch(updateWhy(response.data));
        } else {
          dispatch(updateWhy([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // browserHistory.push('/');
            // window.location.reload();
          } else {
            console.log(error);
          }
        }
      });
  };
}

function updateWhy(response) {
  return {
    type: UPDATE_WHY_COMMENT,
    why_update_data: response,
  };
}

export function updateWhyById(post) {
  return (dispatch) => {
    return dispatch(update_why(post));
  };
}

/*end update why */

/* start get where details by id */

function fetch_where_by_id(where_id) {
  return async (dispatch) => {
    return await getRequest({ url: "api/managed_que_wheres_bank/" + where_id})
      .then((response) => {
        //console.log(response);
        if (response.data != "") {
          dispatch(getWhereDetails(response.data));
        } else {
          dispatch(getWhereDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // browserHistory.push('/');
            // window.location.reload();
          } else {
            console.log(error);
          }
        }
      });
  };
}

function getWhereDetails(response) {
  return {
    type: GET_WHERE_COMMENT,
    fetch_where_by_id: response,
  };
}

export function getWhereById(where_id) {
  return (dispatch) => {
    return dispatch(fetch_where_by_id(where_id));
  };
}

/*end get where details by id */

/* start update where */

function update_where(post) {
  return async (dispatch) => {
    return await putRequest({ url: "api/managed_que_wheres_bank_api_1", payload: post})
      .then((response) => {
        //console.log(response);
        if (response.data != "") {
          dispatch(updateWhere(response.data));
        } else {
          dispatch(updateWhere([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // browserHistory.push('/');
            // window.location.reload();
          } else {
            console.log(error);
          }
        }
      });
  };
}

function updateWhere(response) {
  return {
    type: UPDATE_WHERE_COMMENT,
    where_update_data: response,
  };
}

export function updateWhereById(post) {
  return (dispatch) => {
    return dispatch(update_where(post));
  };
}

/*end update where */

/* start delete why comment */
function deleteWhyComment(id) {
  return async (dispatch) => {
    return await deleteRequest({ url: "/api/managed_que_whys_bank/" + id})
      .then((response) => {
        dispatch(removeWhyData(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // browserHistory.push('/');
            // window.location.reload();
          } else {
            console.log(error);
          }
        }
      });
  };
}

function removeWhyData(response) {
  return {
    type: DELETE_WHY_COMMENT,
    why_delete_data: response,
  };
}

export function removeWhyComment(id) {
  return (dispatch) => {
    return dispatch(deleteWhyComment(id));
  };
}

/* end delete why comment */

/* start delete where comment */
function deleteWhereComment(id) {
  return async (dispatch) => {
    return await deleteRequest({ url: "/api/managed_que_wheres_bank/" + id})
      .then((response) => {
        dispatch(removeWhereData(response.data));
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // browserHistory.push('/');
            // window.location.reload();
          } else {
            console.log(error);
          }
        }
      });
  };
}

function removeWhereData(response) {
  return {
    type: DELETE_WHERE_COMMENT,
    where_delete_data: response,
  };
}

export function removeWhereComment(id) {
  return (dispatch) => {
    return dispatch(deleteWhereComment(id));
  };
}

/* end delete where comment */

/* start fetch all question categories */
function fetch_question_categories() {
  return async (dispatch) => {
    return await getRequest({ url: "api/managed_que_cats/"})
      .then((response) => {
        if (response.data.result.length > 0) {
          dispatch(requestQuestionCat(response.data.result));
        } else {
          dispatch(requestQuestionCat([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // browserHistory.push('/');
            // window.location.reload();
          } else {
            console.log(error);
          }
        }
      });
  };
}

function requestQuestionCat(response) {
  return {
    type: RECEIVE_QUESTION_CATEGORIES,
    question_category_data: response,
  };
}

export function fetchQuestionCategoryList() {
  return (dispatch) => {
    return dispatch(fetch_question_categories());
  };
}
/* end fetch all question categories */

/* start add question config data */
function add_question_config(post) {
  return async (dispatch) => {
    return await({ url: "api/managed_area_risk_que", payload: post})
      .then((response) => {
        if (response.data != "") {
          dispatch(addQuestionConfig(response.data));
        } else {
          dispatch(addQuestionConfig([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // browserHistory.push('/');
            // window.location.reload();
          } else {
            console.log(error);
          }
        }
      });
  };
}

function addQuestionConfig(response) {
  return {
    type: ADD_QUESTION_CONFIG,
    question_add_config_data: response,
  };
}

export function addQuestionConfigData(post) {
  return (dispatch) => {
    return dispatch(add_question_config(post));
  };
}

/*end add question config data */

/* start get question config details by id */
function fetch_question_config_by_id(id) {
  return async (dispatch) => {
    return await getRequest({ url: "api/managed_area_risk_que/" + id})
      .then((response) => {
        //console.log(response);
        if (response.data != "") {
          dispatch(getQuestionConfigDetails(response.data));
        } else {
          dispatch(getQuestionConfigDetails([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // browserHistory.push('/');
            // window.location.reload();
          } else {
            console.log(error);
          }
        }
      });
  };
}

function getQuestionConfigDetails(response) {
  return {
    type: GET_QUESTION_CONFIG,
    fetch_question_config_by_id: response,
  };
}

export function getQuestionConfigById(id) {
  return (dispatch) => {
    return dispatch(fetch_question_config_by_id(id));
  };
}

/*end get question config details by id */

/* start update question config data */
function update_question_config(post) {
  return async (dispatch) => {
    return await putRequest({ url: "api/managed_area_risk_que", payload: post})
      .then((response) => {
        //console.log(response);
        if (response.data != "") {
          dispatch(updateQuestionConfig(response.data));
        } else {
          dispatch(updateQuestionConfig([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // browserHistory.push('/');
            // window.location.reload();
          } else {
            console.log(error);
          }
        }
      });
  };
}

function updateQuestionConfig(response) {
  return {
    type: UPDATE_QUESTION_CONFIG,
    question_update_config_data: response,
  };
}

export function updateQuestionConfigById(post) {
  return (dispatch) => {
    return dispatch(update_question_config(post));
  };
}

export function addMultipleFloor(post) {
  return (dispatch) => {
    return dispatch(addMultipleFloorData(post));
  };
}

function addMultipleFloorData(post) {
  return async (dispatch) => {
    return await postRequest({ url: "api/add_mutliple_floor", payload: post})
      .then((response) => {
        if (response.data != "") {
          dispatch(addMultipleFloorList(response.data));
        } else {
          dispatch(addMultipleFloorList([]));
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            // window.location.href = "/";
            // window.location.reload();
          } else {
            console.log(error);
          }
        }
      });
  };
}

function addMultipleFloorList(response) {
  return {
    type: ADD_MULTIPLE_FLOOR,
    add_multiple_floor: response,
  };
}

/*end update question config data */
