import { combineReducers } from "redux";
import {
    RECEIVE_LOGIN,
    LOGOUT,
    RECEIVE_STATE,
    RECEIVE_FACILITY,
    RECEIVE_FLOOR,
    RECEIVE_AREA,
    RECEIVE_AREA_BY_FLOOR,
    RECEIVE_AREA_RISK_TYPE,
    RECEIVE_ROOM,
    ADD_FLOOR,
    GET_FLOOR,
    UPDATE_FLOOR,
    ADD_AREA,
    GET_AREA,
    UPDATE_AREA,
    ADD_ROOM,
    GET_ROOM,
    UPDATE_ROOM,
    DELETE_FLOOR,
    DELETE_AREA,
    DELETE_ROOM,
    LATEST_FIVE_AUDITS,
    GET_AUDIT_COUNT,
    GET_AUDIT_GRAPH_DATA,
    RECEIVE_REPORT1,
    RECEIVE_REPORT2,
    RECEIVE_REPORT3,
    RECEIVE_REPORT4,
    RECEIVE_REPORT5,
    RECEIVE_REPORT6,
    RECEIVE_REPORT7,
    RECEIVE_REPORT8,
    BULK_UPLOAD,
    RECTIFICATION_REPORT_DATA,
    AUDIT_ANSWER_DATA,
    FACILITY_USER_LIST,
    COMMENT_BY_AUDITOR,
    FINAL_REPORT,
    AUTO_LOGIN,
    AUDIT_REPORT,
    REPORT_TYPE,
    CLEANING_AUDIT_REPORT_DATA,
    CORRECTIVE_RECTIFICATION_REPORT_DATA,
    AUDIT_TRACKING_REPORT_DATA,
} from "../actions/actions";
import observationQuestionsReducer from './observationQuestion_reducer'
import {
    RECEIVE_QUESTION,
    ADD_QUESTION,
    DELETE_QUESTION,
    GET_QUESTION,
    UPDATE_QUESTION,
    RECEIVE_QUESTION_WHERE,
    RECEIVE_QUESTION_WHY,
    ADD_QUESTION_WHERE,
    ADD_QUESTION_WHY,
    DELETE_WHERE_COMMENT,
    GET_WHERE_COMMENT,
    UPDATE_WHERE_COMMENT,
    DELETE_WHY_COMMENT,
    GET_WHY_COMMENT,
    UPDATE_WHY_COMMENT,
    RECEIVE_QUESTION_CATEGORIES,
    ADD_QUESTION_CONFIG,
    GET_QUESTION_CONFIG,
    UPDATE_QUESTION_CONFIG,
    ADD_MULTIPLE_FLOOR,
} from "../actions/questions_actions";

function postsBySubreddit(
    state = {
        login_user_data: [],
        logout_data: [],
        state_data: [],
        facility_data: [],
        floor_data: [],
        area_data: [],
        area_data_by_floor: [],
        area_risk_type_data: [],
        room_data: [],
        floor_add_data: [],
        floor_details_by_id: [],
        floor_update_data: [],
        area_add_data: [],
        area_details_by_id: [],
        area_update_data: [],
        room_add_data: [],
        room_details_by_id: [],
        room_update_data: [],
        floor_delete_data: [],
        area_delete_data: [],
        room_delete_data: [],
        question_data: [],
        question_add_data: [],
        question_delete_data: [],
        fetch_question_by_id: [],
        question_update_data: [],
        question_where_data: [],
        question_add_where_data: [],
        where_delete_data: [],
        fetch_where_by_id: [],
        where_update_data: [],
        question_why_data: [],
        question_add_why_data: [],
        why_delete_data: [],
        fetch_why_by_id: [],
        why_update_data: [],
        question_category_data: [],
        question_add_config_data: [],
        fetch_question_config_by_id: [],
        question_update_config_data: [],
        latest_five_audit_data: [],
        get_audit_count: [],
        fetch_audit_graph_data: [],
        report1_data: [],
        report2_data: [],
        report3_data: [],
        report4_data: [],
        report5_data: [],
        report6_data: [],
        report7_data: [],
        report8_data: [],
        add_multiple_floor: [],
        bulk_upload: [],
        rectification_data: [],
        audit_ans_data: [],
        facility_wise_user_list: [],
        comment_by_auditor: [],
        final_report: [],
        audit_report_detail: [],
        report_type: [],
        cleaning_audit_reports: [],
        rectifications_data: [],
        audit_tracking_reports: [],
    },
    action
) {
    switch (action.type) {
        case RECEIVE_LOGIN:
            return Object.assign({}, state, {
                login_user_data: action.login_user_data,
            });

        case LOGOUT:
            return Object.assign({}, state, {
                logout_data: action.logout_data,
            });

        case RECEIVE_STATE:
            return Object.assign({}, state, {
                state_data: action.state_data,
            });

        case RECEIVE_FACILITY:
            return Object.assign({}, state, {
                facility_data: action.facility_data,
            });

        case RECEIVE_FLOOR:
            return Object.assign({}, state, {
                floor_data: action.floor_data,
            });

        case RECEIVE_AREA:
            return Object.assign({}, state, {
                area_data: action.area_data,
            });

        case RECEIVE_AREA_BY_FLOOR:
            return Object.assign({}, state, {
                area_data_by_floor: action.area_data_by_floor,
            });

        case RECEIVE_AREA_RISK_TYPE:
            return Object.assign({}, state, {
                area_risk_type_data: action.area_risk_type_data,
            });

        case RECEIVE_ROOM:
            return Object.assign({}, state, {
                room_data: action.room_data,
            });

        case ADD_FLOOR:
            return Object.assign({}, state, {
                floor_add_data: action.floor_add_data,
            });

        case GET_FLOOR:
            return Object.assign({}, state, {
                floor_details_by_id: action.floor_details_by_id,
            });

        case UPDATE_FLOOR:
            return Object.assign({}, state, {
                floor_update_data: action.floor_update_data,
            });

        case ADD_AREA:
            return Object.assign({}, state, {
                area_add_data: action.area_add_data,
            });

        case GET_AREA:
            return Object.assign({}, state, {
                area_details_by_id: action.area_details_by_id,
            });

        case UPDATE_AREA:
            return Object.assign({}, state, {
                area_update_data: action.area_update_data,
            });

        case ADD_ROOM:
            return Object.assign({}, state, {
                room_add_data: action.room_add_data,
            });

        case GET_ROOM:
            return Object.assign({}, state, {
                room_details_by_id: action.room_details_by_id,
            });

        case UPDATE_ROOM:
            return Object.assign({}, state, {
                room_update_data: action.room_update_data,
            });

        case DELETE_FLOOR:
            return Object.assign({}, state, {
                floor_delete_data: action.floor_delete_data,
            });

        case DELETE_AREA:
            return Object.assign({}, state, {
                area_delete_data: action.area_delete_data,
            });

        case DELETE_ROOM:
            return Object.assign({}, state, {
                room_delete_data: action.room_delete_data,
            });

        // for question
        case RECEIVE_QUESTION:
            return Object.assign({}, state, {
                question_data: action.question_data,
            });

        case ADD_QUESTION:
            return Object.assign({}, state, {
                question_add_data: action.question_add_data,
            });

        case DELETE_QUESTION:
            return Object.assign({}, state, {
                question_delete_data: action.question_delete_data,
            });

        case GET_QUESTION:
            return Object.assign({}, state, {
                fetch_question_by_id: action.fetch_question_by_id,
            });

        case UPDATE_QUESTION:
            return Object.assign({}, state, {
                question_update_data: action.question_update_data,
            });

        // for question where bank
        case RECEIVE_QUESTION_WHERE:
            return Object.assign({}, state, {
                question_where_data: action.question_where_data,
            });

        case ADD_QUESTION_WHERE:
            return Object.assign({}, state, {
                question_add_where_data: action.question_add_where_data,
            });

        case DELETE_WHERE_COMMENT:
            return Object.assign({}, state, {
                where_delete_data: action.where_delete_data,
            });

        case GET_WHERE_COMMENT:
            return Object.assign({}, state, {
                fetch_where_by_id: action.fetch_where_by_id,
            });

        case UPDATE_WHERE_COMMENT:
            return Object.assign({}, state, {
                where_update_data: action.where_update_data,
            });

        // for question why bank
        case RECEIVE_QUESTION_WHY:
            return Object.assign({}, state, {
                question_why_data: action.question_why_data,
            });

        case ADD_QUESTION_WHY:
            return Object.assign({}, state, {
                question_add_why_data: action.question_add_why_data,
            });

        case DELETE_WHY_COMMENT:
            return Object.assign({}, state, {
                why_delete_data: action.why_delete_data,
            });

        case GET_WHY_COMMENT:
            return Object.assign({}, state, {
                fetch_why_by_id: action.fetch_why_by_id,
            });

        case UPDATE_WHY_COMMENT:
            return Object.assign({}, state, {
                why_update_data: action.why_update_data,
            });

        // for question categories
        case RECEIVE_QUESTION_CATEGORIES:
            return Object.assign({}, state, {
                question_category_data: action.question_category_data,
            });

        // for question config data
        case ADD_QUESTION_CONFIG:
            return Object.assign({}, state, {
                question_add_config_data: action.question_add_config_data,
            });

        case GET_QUESTION_CONFIG:
            return Object.assign({}, state, {
                fetch_question_config_by_id: action.fetch_question_config_by_id,
            });

        case UPDATE_QUESTION_CONFIG:
            return Object.assign({}, state, {
                question_update_config_data: action.question_update_config_data,
            });

        case LATEST_FIVE_AUDITS:
            return Object.assign({}, state, {
                latest_five_audit_data: action.latest_five_audit_data,
            });

        case GET_AUDIT_COUNT:
            return Object.assign({}, state, {
                get_audit_count: action.get_audit_count,
            });

        case GET_AUDIT_GRAPH_DATA:
            return Object.assign({}, state, {
                fetch_audit_graph_data: action.fetch_audit_graph_data,
            });

        case RECEIVE_REPORT1:
            return Object.assign({}, state, {
                report1_data: action.report1_data,
            });

        case RECEIVE_REPORT2:
            return Object.assign({}, state, {
                report2_data: action.report2_data,
            });

        case RECEIVE_REPORT3:
            return Object.assign({}, state, {
                report3_data: action.report3_data,
            });

        case RECEIVE_REPORT4:
            return Object.assign({}, state, {
                report4_data: action.report4_data,
            });

        case RECEIVE_REPORT5:
            return Object.assign({}, state, {
                report5_data: action.report5_data,
            });

        case RECEIVE_REPORT6:
            return Object.assign({}, state, {
                report6_data: action.report6_data,
            });

        case RECEIVE_REPORT7:
            return Object.assign({}, state, {
                report7_data: action.report7_data,
            });

        case RECEIVE_REPORT8:
            return Object.assign({}, state, {
                report8_data: action.report8_data,
            });

        case ADD_MULTIPLE_FLOOR:
            return Object.assign({}, state, {
                add_multiple_floor: action.add_multiple_floor,
            });

        case BULK_UPLOAD:
            return Object.assign({}, state, {
                bulk_upload: action.bulk_upload,
            });

        case RECTIFICATION_REPORT_DATA:
            return Object.assign({}, state, {
                rectification_data: action.rectification_data,
            });

        case AUDIT_ANSWER_DATA:
            return Object.assign({}, state, {
                audit_ans_data: action.audit_ans_data,
            });

        case FACILITY_USER_LIST:
            return Object.assign({}, state, {
                facility_wise_user_list: action.facility_wise_user_list,
            });

        case COMMENT_BY_AUDITOR:
            return Object.assign({}, state, {
                comment_by_auditor: action.comment_by_auditor,
            });

        case FINAL_REPORT:
            return Object.assign({}, state, {
                final_report: action.final_report,
            });

        case AUTO_LOGIN:
            return Object.assign({}, state, {
                auto_login_detail: action.auto_login_detail,
            });

        case AUDIT_REPORT:
            return Object.assign({}, state, {
                audit_report_detail: action.audit_report_detail,
            });

        case REPORT_TYPE:
            return Object.assign({}, state, {
                report_type: action.report_type,
            });
        case CLEANING_AUDIT_REPORT_DATA:
            return Object.assign({}, state, {
                cleaning_audit_reports: action.cleaning_audit_reports,
            });
        case CORRECTIVE_RECTIFICATION_REPORT_DATA:
            return Object.assign({}, state, {
                rectifications_data: action.rectifications_data,
            });

        case AUDIT_TRACKING_REPORT_DATA:
            return Object.assign({}, state, {
                audit_tracking_reports: action.audit_tracking_reports,
            });

        default:
            return state;
    }
}
const rootReducer = combineReducers({
    postsBySubreddit,
    observationQuestionsReducer
});
export default rootReducer;
