import Axios from "axios";
import { browserHistory } from "react-router";
import { deleteRequest, getRequest, postRequest, putRequest } from "../../utils/apiUtils";
import { TOKEN } from "../../utils/constants/localstoregeConstants";
import { CLIENT_PORTAL } from "../../utils/constants/rbuConstants";
const apiUrl = process.env.REACT_APP_PORTAL_URL;

var request = require("request");

export const RECEIVE_LOGIN = "RECEIVE_LOGIN";
export const AUTO_LOGIN = "AUTO_LOGIN";
export const RECEIVE_STATE = "RECEIVE_STATE";
export const RECEIVE_FACILITY = "RECEIVE_FACILITY";
export const RECEIVE_FLOOR = "RECEIVE_FLOOR";
export const RECEIVE_AREA = "RECEIVE_AREA";
export const RECEIVE_AREA_BY_FLOOR = "RECEIVE_AREA_BY_FLOOR";
export const RECEIVE_AREA_RISK_TYPE = "RECEIVE_AREA_RISK_TYPE";
export const FINAL_REPORT = "FINAL_REPORT";

export const RECEIVE_ROOM = "RECEIVE_ROOM";
export const AUDIT_ANSWER_DATA = "AUDIT_ANSWER_DATA";
export const FACILITY_USER_LIST = "FACILITY_USER_LIST";
export const COMMENT_BY_AUDITOR = "COMMENT_BY_AUDITOR";

export const ADD_FLOOR = "ADD_FLOOR";
export const GET_FLOOR = "GET_FLOOR";
export const UPDATE_FLOOR = "UPDATE_FLOOR";

export const ADD_AREA = "ADD_AREA";
export const GET_AREA = "GET_AREA";
export const UPDATE_AREA = "UPDATE_AREA";

export const ADD_ROOM = "ADD_ROOM";
export const GET_ROOM = "GET_ROOM";
export const UPDATE_ROOM = "UPDATE_ROOM";

export const DELETE_FLOOR = "DELETE_FLOOR";
export const DELETE_AREA = "DELETE_AREA";
export const DELETE_ROOM = "DELETE_ROOM";

export const LOGOUT = "LOGOUT";

export const LATEST_FIVE_AUDITS = "LATEST_FIVE_AUDITS";
export const GET_AUDIT_COUNT = "GET_AUDIT_COUNT";
export const GET_AUDIT_GRAPH_DATA = "GET_AUDIT_GRAPH_DATA";

export const RECEIVE_REPORT1 = "RECEIVE_REPORT1";
export const RECEIVE_REPORT2 = "RECEIVE_REPORT2";
export const RECEIVE_REPORT3 = "RECEIVE_REPORT3";
export const RECEIVE_REPORT4 = "RECEIVE_REPORT4";
export const RECEIVE_REPORT5 = "RECEIVE_REPORT5";
export const RECEIVE_REPORT6 = "RECEIVE_REPORT6";
export const RECEIVE_REPORT7 = "RECEIVE_REPORT7";
export const RECEIVE_REPORT8 = "RECEIVE_REPORT8";
export const ADD_MULTIPLE_FLOOR = "ADD_MULTIPLE_FLOOR";
export const BULK_UPLOAD = "BULK_UPLOAD";
export const RECTIFICATION_REPORT_DATA = "RECTIFICATION_REPORT_DATA";
export const AUDIT_REPORT = "AUDIT_REPORT";
export const REPORT_TYPE = "REPORT_TYPE";
export const CLEANING_AUDIT_REPORT_DATA = "CLEANING_AUDIT_REPORT_DATA";
export const CORRECTIVE_RECTIFICATION_REPORT_DATA = "CORRECTIVE_RECTIFICATION_REPORT_DATA";
export const AUDIT_TRACKING_REPORT_DATA = "AUDIT_TRACKING_REPORT_DATA";

/* start login */

function login(post) {
    return async (dispatch) => {
        return await postRequest({ url: "api/managed_cleaning_users", payload: post})
            .then((response) => {
                localStorage.setItem(TOKEN, response.data.result.token_id)
                if (response.data != "") {
                    dispatch(requestLogin(response.data));
                } else {
                    dispatch(requestLogin([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestLogin(response) {
    return {
        type: RECEIVE_LOGIN,
        login_user_data: response,
    };
}

export function userLogin(post) {
    return (dispatch) => {
        return dispatch(login(post));
    };
}

/*end login */

/* start logout */
function logout() {
    return async (dispatch) => {
        return await getRequest({ url: "api/logout"})
            .then((response) => {
                if (response.data != "") {
                    localStorage.removeItem("ck_name");
                    dispatch(requestLogout(response.data));
                } else {
                    dispatch(requestLogout([]));
                }
            })
            .catch((error) => {
                //throw(error);
                if (error.response) {
                    if (error.response.status === 403) {
                        //browserHistory.push('/');
                        //  window.location.reload();
                    } else {
                        //console.log(error);
                    }
                }
            });
    };
}

function requestLogout(response) {
    return {
        type: LOGOUT,
        logout_data: response,
    };
}

export function userLogout() {
    return (dispatch) => {
        return dispatch(logout());
    };
}

/* end logout */

/* start fetch all state based on role */
function fetch_states_by_role(id) {
    return async (dispatch) => {
        //var id=1;
        return await getRequest({ url: "api/managed_states_by_role/" + id})
            .then((response) => {
                if (response.data.result.length > 0) {
                    dispatch(requestState(response.data.result));
                } else {
                    dispatch(requestState([]));
                }
                //console.log(response.data);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestState(response) {
    return {
        type: RECEIVE_STATE,
        state_data: response,
    };
}

export function fetchStateList(id) {
    return (dispatch) => {
        return dispatch(fetch_states_by_role(id));
    };
}
/* end fetch all state */

/* start fetch all facilities by state */
function fetch_facilities_by_state(id) {
    return async (dispatch) => {
        //var id=1;
        return await getRequest({ url: "api/managed_facilities_by_state/" + id})
            .then((response) => {
                if (response.data.result.length > 0) {
                    dispatch(requestFacility(response.data.result));
                } else {
                    dispatch(requestFacility([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestFacility(response) {
    return {
        type: RECEIVE_FACILITY,
        facility_data: response,
    };
}

export function fetchFacilityList(id) {
    return (dispatch) => {
        return dispatch(fetch_facilities_by_state(id));
    };
}
/* end fetch all facilities by state */

/* start fetch all floors by facility */
function fetch_floors_by_facility(id) {
    return async (dispatch) => {
        //var id=1;
        return await getRequest({ url:"api/managed_floors_by_facility/" + id})
            .then((response) => {
                if (response.data.result.length > 0) {
                    dispatch(requestFloors(response.data.result));
                } else {
                    dispatch(requestFloors([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestFloors(response) {
    return {
        type: RECEIVE_FLOOR,
        floor_data: response,
    };
}

export function fetchFloorList(id) {
    return (dispatch) => {
        return dispatch(fetch_floors_by_facility(id));
    };
}
/* end fetch all floors by facility */

/* start fetch all area by facility */
function fetch_areas_by_facility(id) {
    return async (dispatch) => {
        return await getRequest({ url: "api/managed_areas_by_facility/" + id})
            .then((response) => {
                if (response.data.result.length > 0) {
                    dispatch(requestAreas(response.data.result));
                } else {
                    dispatch(requestAreas([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestAreas(response) {
    return {
        type: RECEIVE_AREA,
        area_data: response,
    };
}

export function fetchAreaList(id) {
    return (dispatch) => {
        return dispatch(fetch_areas_by_facility(id));
    };
}
/* end fetch all area */

/* start fetch all area by floor */
function fetch_area_by_floor(id) {
    return async (dispatch) => {
        return await getRequest({ url: "api/managed_areas_by_floor/" + id})
            .then((response) => {
                if (response.data.result.length > 0) {
                    dispatch(requestAreaByFloor(response.data.result));
                } else {
                    dispatch(requestAreaByFloor([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestAreaByFloor(response) {
    return {
        type: RECEIVE_AREA_BY_FLOOR,
        area_data_by_floor: response,
    };
}

export function fetchAreaListByFloor(id) {
    return (dispatch) => {
        return dispatch(fetch_area_by_floor(id));
    };
}
/* end fetch all area by floor */

/* start fetch all area risk type */
function fetch_areas_risk_type(id) {
    return async (dispatch) => {
        return await getRequest({ url: "api/managed_areas_risk_types/" + id})
            .then((response) => {
                if (response.data.result.length > 0) {
                    dispatch(requestAreaRiskType(response.data.result));
                } else {
                    dispatch(requestAreaRiskType([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestAreaRiskType(response) {
    return {
        type: RECEIVE_AREA_RISK_TYPE,
        area_risk_type_data: response,
    };
}

export function fetchAreaRiskTypeList(id) {
    return (dispatch) => {
        return dispatch(fetch_areas_risk_type(id));
    };
}
/* end fetch all area risk type */

/* start fetch all rooms */
function fetch_rooms_by_facility(id) {
    return async (dispatch) => {
        return await getRequest({ url: "api/managed_rooms_by_facility/" + id})
            .then((response) => {
                if (response.data.result.length > 0) {
                    dispatch(requestRooms(response.data.result));
                } else {
                    dispatch(requestRooms([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestRooms(response) {
    return {
        type: RECEIVE_ROOM,
        room_data: response,
    };
}

export function fetchRoomList(id) {
    return (dispatch) => {
        return dispatch(fetch_rooms_by_facility(id));
    };
}
/* end fetch all rooms */

/* start add floor */

function add_floor(post) {
    return async (dispatch) => {
        return await postRequest({ url: "api/managed_floors", payload: post})
            .then((response) => {
                //console.log(response);
                if (response.data != "") {
                    dispatch(addFloor(response.data));
                } else {
                    dispatch(addFloor([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function addFloor(response) {
    return {
        type: ADD_FLOOR,
        floor_add_data: response,
    };
}

export function addNewFloor(post) {
    return (dispatch) => {
        return dispatch(add_floor(post));
    };
}

/*end add floor */

/* start get floor details by id */

function fetch_floor_by_id(floor_id) {
    return async (dispatch) => {
        return await getRequest({ url: "api/managed_floors/" + floor_id})
            .then((response) => {
                //console.log(response);
                if (response.data != "") {
                    dispatch(getFloorDetails(response.data));
                } else {
                    dispatch(getFloorDetails([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function getFloorDetails(response) {
    return {
        type: GET_FLOOR,
        floor_details_by_id: response,
    };
}

export function getFloorById(floor_id) {
    return (dispatch) => {
        return dispatch(fetch_floor_by_id(floor_id));
    };
}

/*end get floor details by id */

/* start update floor */

function update_floor(post) {
    return async (dispatch) => {
        return await putRequest({ url: "api/managed_floors", payload: post})
            .then((response) => {
                //console.log(response);
                if (response.data != "") {
                    dispatch(updateFloor(response.data));
                } else {
                    dispatch(updateFloor([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function updateFloor(response) {
    return {
        type: UPDATE_FLOOR,
        floor_update_data: response,
    };
}

export function updateFloorById(post) {
    return (dispatch) => {
        return dispatch(update_floor(post));
    };
}

/*end update floor */

/* start add area */

function add_area(post) {
    return async (dispatch) => {
        return await postRequest({ url: "api/managed_areas", payload: post})
            .then((response) => {
                //console.log(response);
                if (response.data != "") {
                    dispatch(addArea(response.data));
                } else {
                    dispatch(addArea([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function addArea(response) {
    return {
        type: ADD_AREA,
        area_add_data: response,
    };
}

export function addNewArea(post) {
    return (dispatch) => {
        return dispatch(add_area(post));
    };
}

/*end add area */

/* start get area details by id */

function fetch_area_by_id(area_id) {
    return async (dispatch) => {
        return await getRequest({ url: "api/managed_areas/" + area_id})
            .then((response) => {
                //console.log(response);
                if (response.data != "") {
                    dispatch(getAreaDetails(response.data));
                } else {
                    dispatch(getAreaDetails([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function getAreaDetails(response) {
    return {
        type: GET_AREA,
        area_details_by_id: response,
    };
}

export function getAreaById(area_id) {
    return (dispatch) => {
        return dispatch(fetch_area_by_id(area_id));
    };
}

/*end get area details by id */

/* start update area */

function update_area(post) {
    return async (dispatch) => {
        return await putRequest({ url: "api/managed_areas", payload: post})
            .then((response) => {
                //console.log(response);
                if (response.data != "") {
                    dispatch(updateArea(response.data));
                } else {
                    dispatch(updateArea([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function updateArea(response) {
    return {
        type: UPDATE_AREA,
        area_update_data: response,
    };
}

export function updateAreaById(post) {
    return (dispatch) => {
        return dispatch(update_area(post));
    };
}

/*end update area */

/* start add room */

function add_room(post) {
    return async (dispatch) => {
        return await postRequest({ url: "api/managed_rooms", payload: post})
            .then((response) => {
                //console.log(response);
                if (response.data != "") {
                    dispatch(addRoom(response.data));
                } else {
                    dispatch(addRoom([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function addRoom(response) {
    return {
        type: ADD_ROOM,
        room_add_data: response,
    };
}

export function addNewRoom(post) {
    return (dispatch) => {
        return dispatch(add_room(post));
    };
}

/*end add room */

/* start get room details by id */

function fetch_room_by_id(room_id) {
    return async (dispatch) => {
        return await getRequest({ url: "api/managed_rooms/" + room_id})
            .then((response) => {
                //console.log(response);
                if (response.data != "") {
                    dispatch(getRoomDetails(response.data));
                } else {
                    dispatch(getRoomDetails([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function getRoomDetails(response) {
    return {
        type: GET_ROOM,
        room_details_by_id: response,
    };
}

export function getRoomById(room_id) {
    return (dispatch) => {
        return dispatch(fetch_room_by_id(room_id));
    };
}

/*end get room details by id */

/* start update room */

function update_room(post) {
    return async (dispatch) => {
        return await putRequest({ url: "api/managed_rooms", payload: post})
            .then((response) => {
                //console.log(response);
                if (response.data != "") {
                    dispatch(updateRoom(response.data));
                } else {
                    dispatch(updateRoom([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function updateRoom(response) {
    return {
        type: UPDATE_ROOM,
        room_update_data: response,
    };
}

export function updateRoomById(post) {
    return (dispatch) => {
        return dispatch(update_room(post));
    };
}

/*end update room */

/* start delete floor */
function deleteFloor(id) {
    return async (dispatch) => {
        return await deleteRequest({ url: "/api/managed_floors/" + id})
            .then((response) => {
                dispatch(removeFloorData(response.data));
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function removeFloorData(response) {
    return {
        type: DELETE_FLOOR,
        floor_delete_data: response,
    };
}

export function removeFloor(id) {
    return (dispatch) => {
        return dispatch(deleteFloor(id));
    };
}

/* end delete floor */

/* start delete area */
function deleteArea(id) {
    return async (dispatch) => {
        return await deleteRequest({ url: "/api/managed_areas/" + id})
            .then((response) => {
                dispatch(removeAreaData(response.data));
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function removeAreaData(response) {
    return {
        type: DELETE_AREA,
        area_delete_data: response,
    };
}

export function removeArea(id) {
    return (dispatch) => {
        return dispatch(deleteArea(id));
    };
}

/* end delete area */

/* start delete room */
function deleteRoom(id) {
    return async (dispatch) => {
        return await deleteRequest({ url: "/api/managed_rooms/" + id})
            .then((response) => {
                dispatch(removeRoomData(response.data));
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function removeRoomData(response) {
    return {
        type: DELETE_ROOM,
        room_delete_data: response,
    };
}

export function removeRoom(id) {
    return (dispatch) => {
        return dispatch(deleteRoom(id));
    };
}

/* end delete room */

/* start fetch latest five audits */
function fetch_latest_five_audits() {
    return async (dispatch) => {
        return await getRequest({ url: "api/managed_latest_five_audits/"})
            .then((response) => {
                if (response.data.result.length > 0) {
                    dispatch(requestLatestAudits(response.data.result));
                } else {
                    dispatch(requestLatestAudits([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestLatestAudits(response) {
    return {
        type: LATEST_FIVE_AUDITS,
        latest_five_audit_data: response,
    };
}

export function fetchLatestFiveAudits() {
    return (dispatch) => {
        return dispatch(fetch_latest_five_audits());
    };
}
/* end fetch latest five audits */

/* start fetch audit count */
function fetch_audit_count() {
    return async (dispatch) => {
        return await getRequest({ url: "api/managed_audits_count/"})
            .then((response) => {
                if (response.data.result.length > 0) {
                    dispatch(requestAuditCount(response.data.result));
                } else {
                    dispatch(requestAuditCount([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestAuditCount(response) {
    return {
        type: GET_AUDIT_COUNT,
        get_audit_count: response,
    };
}

export function getAuditCount() {
    return (dispatch) => {
        return dispatch(fetch_audit_count());
    };
}
/* end fetch audit count */

/* start fetch audit graph data */
function fetch_audit_graph_data() {
    return async (dispatch) => {
        return await getRequest({ url: "api/managed_audits_graph_data/"})
            .then((response) => {
                if (response.data.result.length > 0) {
                    dispatch(requestAuditGraphData(response.data.result));
                } else {
                    dispatch(requestAuditGraphData([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestAuditGraphData(response) {
    return {
        type: GET_AUDIT_GRAPH_DATA,
        fetch_audit_graph_data: response,
    };
}

export function getAuditGraphData() {
    return (dispatch) => {
        return dispatch(fetch_audit_graph_data());
    };
}
/* end fetch audit graph data */

/* start report1 */
function facility_monthly_report(facility_id, today_date, state_id) {
    return async (dispatch) => {
        return await getRequest(
            { url: "api/managed_facility_monthly_report/" +
                facility_id +
                "/" +
                today_date +
                "/" +
                state_id
        })
            .then((response) => {
                if (response.data.result.length > 0) {
                    dispatch(requestReport1(response.data.result));
                } else {
                    dispatch(requestReport1([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestReport1(response) {
    return {
        type: RECEIVE_REPORT1,
        report1_data: response,
    };
}

export function fetchReport1(facility_id, today_date, state_id) {
    return (dispatch) => {
        return dispatch(
            facility_monthly_report(facility_id, today_date, state_id)
        );
    };
}
/* end report1 */

/* start report2 */
function area_monthly_avg_score_report(
    facility_id,
    date_to,
    date_from,
    state_id
) {
    return async (dispatch) => {
        return await getRequest(
            { url: "api/area_monthly_avg_score_report/" +
                facility_id +
                "/" +
                date_to +
                "/" +
                date_from +
                "/" +
                state_id
        })
            .then((response) => {
                if (response.data.result.length > 0) {
                    dispatch(requestReport2(response.data.result));
                } else {
                    dispatch(requestReport2([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestReport2(response) {
    return {
        type: RECEIVE_REPORT2,
        report2_data: response,
    };
}

export function fetchReport2(facility_id, date_to, date_from, state_id) {
    return (dispatch) => {
        return dispatch(
            area_monthly_avg_score_report(
                facility_id,
                date_to,
                date_from,
                state_id
            )
        );
    };
}
/* end report2 */

/* start report3 */
function floor_monthly_avg_score_report(
    facility_id,
    date_to,
    date_from,
    state_id
) {
    return async (dispatch) => {
        return await getRequest(
            { url: "api/floor_monthly_avg_score_report/" +
                facility_id +
                "/" +
                date_to +
                "/" +
                date_from +
                "/" +
                state_id
        })
            .then((response) => {
                if (response.data.result.length > 0) {
                    dispatch(requestReport3(response.data.result));
                } else {
                    dispatch(requestReport3([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestReport3(response) {
    return {
        type: RECEIVE_REPORT3,
        report3_data: response,
    };
}

export function fetchReport3(facility_id, date_to, date_from, state_id) {
    return (dispatch) => {
        return dispatch(
            floor_monthly_avg_score_report(
                facility_id,
                date_to,
                date_from,
                state_id
            )
        );
    };
}
/* end report3 */

/* start report4 */
function audit_element_avg_graph_report(
    facility_id,
    date_to,
    date_from,
    state_id
) {
    return async (dispatch) => {
        return await getRequest(
            { url: "api/managed_audit_element_avg_graph_report/" +
                facility_id +
                "/" +
                date_to +
                "/" +
                date_from +
                "/" +
                state_id
        })
            .then((response) => {
                if (response.data.result.length > 0) {
                    dispatch(requestReport4(response.data.result));
                } else {
                    dispatch(requestReport4([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestReport4(response) {
    return {
        type: RECEIVE_REPORT4,
        report4_data: response,
    };
}

export function fetchReport4(facility_id, date_to, date_from, state_id) {
    return (dispatch) => {
        return dispatch(
            audit_element_avg_graph_report(
                facility_id,
                date_to,
                date_from,
                state_id
            )
        );
    };
}
/* end report4 */

/* start report5 */
function audit_rectification_report(facility_id, date_to, date_from, state_id) {
    return async (dispatch) => {
        return await getRequest(
            { url: "api/managed_audit_rectification_report/" +
                facility_id +
                "/" +
                date_to +
                "/" +
                date_from +
                "/" +
                state_id
        })
            .then((response) => {
                if (response.data.result.length > 0) {
                    dispatch(requestReport5(response.data.result));
                } else {
                    dispatch(requestReport5([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestReport5(response) {
    return {
        type: RECEIVE_REPORT5,
        report5_data: response,
    };
}

export function fetchReport5(facility_id, date_to, date_from, state_id) {
    return (dispatch) => {
        return dispatch(
            audit_rectification_report(
                facility_id,
                date_to,
                date_from,
                state_id
            )
        );
    };
}
/* end report5 */

/* start report6 */
function score_summary_graph_report(facility_id, date_to, date_from, state_id) {
    return async (dispatch) => {
        return await getRequest(
            { url: "api/score_summary_graph_report/" +
                facility_id +
                "/" +
                date_to +
                "/" +
                date_from +
                "/" +
                state_id
        })
            .then((response) => {
                if (response.data.result.length > 0) {
                    dispatch(requestReport6(response.data.result));
                } else {
                    dispatch(requestReport6([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestReport6(response) {
    return {
        type: RECEIVE_REPORT6,
        report6_data: response,
    };
}

export function fetchReport6(facility_id, date_to, date_from, state_id) {
    return (dispatch) => {
        return dispatch(
            score_summary_graph_report(
                facility_id,
                date_to,
                date_from,
                state_id
            )
        );
    };
}
/* end report6 */

/* start report7 */
function score_summary_report(facility_id, date_to, date_from, state_id) {
    return async (dispatch) => {
        return await getRequest(
            { url: "api/score_summary_report/" +
                facility_id +
                "/" +
                date_to +
                "/" +
                date_from +
                "/" +
                state_id
        })
            .then((response) => {
                if (response.data.result.length > 0) {
                    dispatch(requestReport7(response.data.result));
                } else {
                    dispatch(requestReport7([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestReport7(response) {
    return {
        type: RECEIVE_REPORT7,
        report7_data: response,
    };
}

export function fetchReport7(facility_id, date_to, date_from, state_id) {
    return (dispatch) => {
        return dispatch(
            score_summary_report(facility_id, date_to, date_from, state_id)
        );
    };
}
/* end report7 */

/* start report8 */
function managed_all_reports(facility_id, date_to, date_from, state_id) {
    return async (dispatch) => {
        return await getRequest(
            { url: "api/managed_all_reports/" +
                facility_id +
                "/" +
                date_to +
                "/" +
                date_from +
                "/" +
                state_id
        })
            .then((response) => {
                if (response.data.result.length > 0) {
                    dispatch(requestReport8(response.data.result));
                } else {
                    dispatch(requestReport8([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function requestReport8(response) {
    return {
        type: RECEIVE_REPORT8,
        report8_data: response,
    };
}

export function fetchReport8(facility_id, date_to, date_from, state_id) {
    return (dispatch) => {
        return dispatch(
            managed_all_reports(facility_id, date_to, date_from, state_id)
        );
    };
}
/* end report8 */

export function addMultipleFloor(post) {
    return (dispatch) => {
        return dispatch(addMultipleFloorData(post));
    };
}

function addMultipleFloorData(post) {
    return async (dispatch) => {
        return await postRequest({ url: "api/add_mutliple_floor", payload: post})
            .then((response) => {
                console.log(
                    response,
                    "========= response of action===================="
                );
                if (response.data != "") {
                    dispatch(addMultipleFloorList(response.data));
                } else {
                    dispatch(addMultipleFloorList([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // window.location.href = "/";
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function addMultipleFloorList(response) {
    return {
        type: ADD_MULTIPLE_FLOOR,
        add_multiple_floor: response,
    };
}

export function bulkUploadFacility(post) {
    return (dispatch) => {
        return dispatch(bulkUploadFacilityData(post));
    };
}

function bulkUploadFacilityData(post) {
    return async (dispatch) => {
        return await postRequest({ url: "api/managed_bulk_upload", payload: post})
            .then((response) => {
                if (response.data != "") {
                    dispatch(bulkUploadFacilityDataList(response.data));
                } else {
                    dispatch(bulkUploadFacilityDataList([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // window.location.href = "/";
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function bulkUploadFacilityDataList(response) {
    return {
        type: BULK_UPLOAD,
        bulk_upload: response,
    };
}

/* start managed rectifications Report */
function fetch_managed_rectifications(id) {
    return async (dispatch) => {
        return await getRequest({ url: "api/managed_rectification_api/" + id})
            .then((response) => {
                if (response.data.result.length > 0) {
                    dispatch(rectifications(response.data.result));
                } else {
                    dispatch(rectifications([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function rectifications(response) {
    return {
        type: RECTIFICATION_REPORT_DATA,
        rectification_data: response,
    };
}

export function fetchRectificationsReport(id) {
    return (dispatch) => {
        return dispatch(fetch_managed_rectifications(id));
    };
}
/* end managed rectifications Report */

/* start managed Audit Answer */
function fetch_managed_audit(id) {
    return async (dispatch) => {
        return await getRequest({ url: "api/managed_audit_ans/" + id})
            .then((response) => {
                if (response.data.result.length > 0) {
                    dispatch(auditAns(response.data.result));
                } else {
                    dispatch(auditAns([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function auditAns(response) {
    return {
        type: AUDIT_ANSWER_DATA,
        audit_ans_data: response,
    };
}

export function fetchAuditAns(id) {
    return (dispatch) => {
        return dispatch(fetch_managed_audit(id));
    };
}
/* end managed Audit Answer */

/* start Facility wise user */
function fetch_Users(id) {
    return async (dispatch) => {
        return await getRequest({ url: "api/user_details_of_cleaning_audit/" + id})
            .then((response) => {
                if (response.data.result.length > 0) {
                    dispatch(facilityWiseUser(response.data.result));
                } else {
                    dispatch(facilityWiseUser([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function facilityWiseUser(response) {
    return {
        type: FACILITY_USER_LIST,
        facility_wise_user_list: response,
    };
}

export function featchUserData(id) {
    return (dispatch) => {
        return dispatch(fetch_Users(id));
    };
}
/* end Facility wise user */

/* start comment by Auditor */

function comment_by_auditor(post) {
    return async (dispatch) => {
        return await putRequest({ url: "api/managed_rectifications", payload: post})
            .then((response) => {
                //console.log(response);
                if (response.data != "") {
                    dispatch(comments(response.data));
                } else {
                    dispatch(comments([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function comments(response) {
    return {
        type: COMMENT_BY_AUDITOR,
        comment_by_auditor: response,
    };
}

export function commentByAuditor(post) {
    return (dispatch) => {
        return dispatch(comment_by_auditor(post));
    };
}

/*end comment by Auditor */

/* start Final submit rectification report */

function final_submit_report(post) {
    return async (dispatch) => {
        return await putRequest({ url: "api/managed_rectifications_notify", payload: post})
            .then((response) => {
                //console.log(response);
                if (response.data != "") {
                    dispatch(finalReport(response.data));
                } else {
                    dispatch(finalReport([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function finalReport(response) {
    return {
        type: FINAL_REPORT,
        final_report: response,
    };
}

export function FinalRectification(post) {
    return (dispatch) => {
        return dispatch(final_submit_report(post));
    };
}

/*end Final submit rectification report */

/* start Auto login */

function loginAuthentication(post) {
    return (dispatch) => {
        return postRequest({ url: "/api/cleaning_auto_login", payload: post})
            .then((response) => {
                if (response.data != "") {
                    dispatch(loginProcess(response.data));
                } else {
                    dispatch(loginProcess([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                    }
                }
            });
    };
}

function loginProcess(response) {
    return {
        type: AUTO_LOGIN,
        auto_login_detail: response,
    };
}

export function AutoLoginProcess(post) {
    return (dispatch) => {
        return dispatch(loginAuthentication(post));
    };
}

/*end Auto login */

/* start Audit Report login */

function auditReportData(post) {
    return (dispatch) => {
        return postRequest({ url: `api/audit_report`, payload: post, rbu: CLIENT_PORTAL})
            .then((response) => {
                if (Array.isArray(response.data.result)) {
                    dispatch(audit(response.data.result));
                } else {
                    dispatch(audit([]));
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 403) {
                        dispatch(audit([]));
                        // browserHistory.push("/");
                        // window.location.reload();
                    } else {
                        console.log(error);
                        dispatch(audit([]));
                    }
                }
            });
    };
}

function audit(response) {
    return {
        type: AUDIT_REPORT,
        audit_report_detail: response,
    };
}

export function AuditReportList(post) {
    return (dispatch) => {
        return dispatch(auditReportData(post));
    };
}

/*end Audit Report login */

export const fetchReportTypeAction = () => {
    return async (dispatch) => {
        return dispatch(fetchReportType());
    };
};

const fetchReportType = () => {
    return async (dispatch) => {
        return await getRequest({ url: `api/get_cleaning_report_type_data`}).then(
            (response) => {
                if (Array.isArray(response.data.result)) {
                    dispatch(fetchReportTypeReducer(response.data.result));
                } else {
                    dispatch(fetchReportTypeReducer([]));
                }
            }
        );
    };
};

const fetchReportTypeReducer = (response) => {
    return {
        type: REPORT_TYPE,
        report_type: response,
    };
};

export const getCleaningAuditReportAction = (body) => {
    return (dispatch) => {
        return dispatch(getCleaningAuditReport(body));
    };
};

const getCleaningAuditReport = (body) => {
    return (dispatch) => {
        return postRequest({ url: `api/get_cleaning_audit_report_data`, payload: body}).then(
            (response) => {
                if (Array.isArray(response.data.result)) {
                    dispatch(
                        getCleaningAuditReportReducer(response.data.result)
                    );
                } else {
                    dispatch(getCleaningAuditReportReducer([]));
                }
            }
        );
    };
};

const getCleaningAuditReportReducer = (data) => {
    return {
        type: CLEANING_AUDIT_REPORT_DATA,
        cleaning_audit_reports: data,
    };
};

// Get Rectification Report 

export const getRectificationReportAction = (body) => {
    return (dispatch) => {
        return dispatch(getRectificationReport(body));
    };
};

const getRectificationReport = (body) => {
    return (dispatch) => {
        return postRequest({ url: `api/get_staff_corrective_action_data`, payload: body}).then(
            (response) => {
                if (Array.isArray(response.data.result)) {
                    dispatch(
                        getRectificationReportReducer(response.data.result)
                    );
                } else {
                    dispatch(getRectificationReportReducer([]));
                }
            }
        );
    };
};

const getRectificationReportReducer = (data) => {
    return {
        type: CORRECTIVE_RECTIFICATION_REPORT_DATA,
        rectifications_data: data,
    };
};

// Audit Tracking Reports

export const getAuditTrackingReportAction = (body) => {
    return (dispatch) => {
        return dispatch(getAuditTrackingReport(body));
    };
};

const getAuditTrackingReport = (body) => {
    return (dispatch) => {
        return postRequest({ url: `api/get_cleaning_audit_traking_details`, payload: body}).then(
            (response) => {
                if (Array.isArray(response.data.result)) {
                    dispatch(
                        getAuditTrackingReportReducer(response.data.result)
                    );
                } else {
                    dispatch(getAuditTrackingReportReducer([]));
                }
            }
        );
    };
};

const getAuditTrackingReportReducer = (data) => {
    return {
        type: AUDIT_TRACKING_REPORT_DATA,
        audit_tracking_reports: data,
    };
};