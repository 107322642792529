import React from "react";
import { Container } from "reactstrap";
import { connect } from "react-redux";
//import {Link} from 'react-router';
import simpleReactValidator from "simple-react-validator";
import { browserHistory } from "react-router"; // 13-3-18
//import AlertMessageClass from '../../../shared/components/alert_message'
import AlertSuccess from "../../../shared/components/Alerts/alert_success";

import { Input, Label, FormGroup, Card, CardBlock } from "reactstrap";
import LaddaButton, { S, EXPAND_RIGHT } from "react-ladda";
import appicon from "../../../assets/images/caLogo.png";
import "./style.css";
import axios from "axios";
import { userLogin, userLogout, AutoLoginProcess } from "../../actions/actions";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      email: "",
      password: "",
      login_fail: false,
      visible: true,
    };

    this.validator = new simpleReactValidator();
    this.onSubmitClick = this.onSubmitClick.bind(this);

    this.emailChange = this.emailChange.bind(this);

    this.passChange = this.passChange.bind(this);

    this.onDismiss = this.onDismiss.bind(this);
  }
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(userLogout());
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.login_user_data != this.props.login_user_data) {
      if (this.props.login_user_data != "") {
        if (this.props.login_user_data.success == true) {
          this.setState({
            visible: true,
            loading: !this.state.loading,
            progress: 0.1,
          });
          browserHistory.push("/dashboard");
          window.location.reload();
        } else {
          this.setState({
            login_fail: true,
            visible: true,
          });
        }
      }
    }
  }

  emailChange = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  passChange = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  onDismiss() {
    var that = this;
    that.setState({
      visible: false,
    });
  }

  onSubmitClick(e) {
    var that = this;

    var email = that.state.email;
    var password = that.state.password;
    //if( that.validator.fieldValid('email') && password!=="")
    //if(email!=="" && password!=="")
    if (this.validator.allValid()) {
      var post = {
        email: that.state.email,
        password: that.state.password,
      };
      this.setState({
        //loading: !this.state.loading,
        //progress: 0.10
      });
      that.props.dispatch(userLogin(post)); // for calling method of API
    } else {
      that.validator.showMessages();
      that.forceUpdate();
      this.setState({
        //loading: this.state.loading
      });
    }
  }
  render() {
    const { login_user_data } = this.props;

    return (
      <div className="view">
        <div className="view-content view-pages view-session d-flex justify-content-center align-items-center flex-column">
          <Card className="mb-3 form-card">
            <CardBlock>
              <header className="mb-5">
                <strong className="h4 text-uppercase">
                  <img src={appicon} width="42" alt="logo" /> Majestic Admin
                </strong>
              </header>

              {/* <AlertMessage/> */}
              {/* {login_user_data != "" ? login_user_data.success == false ?  
                        <AlertMessageClass alertMessageText={login_user_data.message}  alertMessageVisible={this.state.alertMessageVisible} alertMessageColor={"danger"} onDismiss={this.onDismiss} /> : '' : ''}
                         */}
              {login_user_data !== "" && login_user_data !== null ? (
                login_user_data.success == false ? (
                  <AlertSuccess
                    visible={this.state.visible}
                    onDismiss={this.onDismiss}
                    color={"danger"}
                    alertText={login_user_data.message}
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}

              <FormGroup className="mb-4">
                <Label>Email / Username</Label>
                <Input
                  type="email"
                  placeholder="Email / Username"
                  value={this.state.email}
                  onChange={this.emailChange}
                  maxLength="50"
                />
                <span className="error-text">
                  {this.validator.message(
                    "email",
                    this.state.email,
                    "required",
                    false,
                    { default: "This field is required." }
                  )}
                </span>
              </FormGroup>
              <FormGroup className="mb-4">
                <Label>Password</Label>
                <Input
                  type="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.passChange}
                  maxLength="50"
                />
                <span className="error-text">
                  {this.validator.message(
                    "password",
                    this.state.password,
                    "required",
                    false,
                    { default: "This field is required." }
                  )}
                </span>
              </FormGroup>
              <FormGroup className="text-right">
                {/* <Button color="primary">Sign In</Button> */}
                <LaddaButton
                  className="btn btn-primary"
                  loading={this.state.loading}
                  onClick={this.onSubmitClick}
                  data-color="#eee"
                  data-size={S}
                  data-style={EXPAND_RIGHT}
                  data-spinner-size={30}
                  data-spinner-color="#ddd"
                  data-spinner-lines={12}
                >
                  Sign In
                </LaddaButton>
              </FormGroup>
            </CardBlock>
          </Card>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { postsBySubreddit } = state;
  const {
    login_user_data: login_user_data,
    auto_login_detail: auto_login_detail,
  } = postsBySubreddit;
  return { login_user_data, auto_login_detail };
}

const AppContainer = connect(mapStateToProps)(Login);

export default AppContainer;
