import Axios from "axios";
import { postRequest } from "../utils/apiUtils";
import { MYM_WEB } from "../utils/constants/rbuConstants";

export const getReportData = async (pdf) => {
    await postRequest({ url: `api/get_presigned_url`, payload: { url: pdf }}).then((response) => {
      const { data } = response;
      if(data.success){
        window.open(data.result)
      }
    }).catch((error) => {
      console.log(error, "Something went wrong")
    })
  }