import React, { Component } from 'react';
import {Input} from 'reactstrap';


class StateList extends Component {
    // constructor(props) 
    // {
    //     super(props);
    // }
    render () 
    {   
        return (
            <Input
                type="select"
                name="drpState"
                id="drpState"
                value={this.props.stateValue}
                onChange={this.props.drpChangeState}>
                <option value="">--Select State--</option>
                {this.props.stateFillData
                    .map((item, i) => <option key={item.state_id} value={item.state_id}>{item.state_name}</option>)}
                    {/* <option value="1">State1</option>
                    <option value="2">State2</option>
                    <option value="3">State3</option>
                    <option value="4">State4</option> */}
            </Input>
        )
    }
}
StateList.defaultProps=
{

    stateFillData:[],
    stateValue:""

}

export default StateList