import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

class AreaTable extends Component {
    constructor() {
        super()
        this.cellActionButton = this.cellActionButton.bind(this);
    }
    cellActionButton(cell, row, enumObject, rowIndex) 
    {
            let theButton;
            theButton = 
            <div>
                <button className = "btn btn-sm btn-primary marginRight_10" style={{cursor:'pointer'}} value={row.id} onClick = {this.props.toggleModal}><i className="fa fa-pencil-square-o"></i>&nbsp; Edit </button>
                <button className = "btn btn-sm btn-danger marginRight_10" style={{cursor:'pointer'}} value={row.id} onClick = {this.props.toggleDeleteModal} ><i className="fa fa-trash"></i>&nbsp; Delete </button>
                <button className = "btn btn-sm btn-warning " style={{cursor:'pointer'}} value={row.id} onClick = {this.props.openModal}><i className="fa fa-plus"></i>&nbsp; Add New Room </button>
            </div>                        
            return theButton;
    };
    render () {
        var cusorPointer={cursor:'pointer'};
        var options = {
            noDataText: 'Records not found'
        };
        return (
            <div>
                        <BootstrapTable
                            ref='table'
                            data={this.props.areaFillData}
                            pagination={ true }
                            options={options}
                            search={ true }>
                            <TableHeaderColumn width='150' dataField='id' isKey={ true } hidden={true}>ID</TableHeaderColumn>
                            <TableHeaderColumn width='150' dataField='floor_name' dataSort={ true }>Floor Name</TableHeaderColumn>
                            <TableHeaderColumn width='150' dataField='area_cat_name' dataSort={ true }>Area Type</TableHeaderColumn>
                            <TableHeaderColumn width='150' dataField='name' dataSort={ true }>Area Name</TableHeaderColumn>
                            <TableHeaderColumn width='200' dataField='action' dataFormat={this.cellActionButton}>Action</TableHeaderColumn>
                        </BootstrapTable>
                {/*
                <table className = "table" >
                    <thead>
                        <tr>
                            <th hidden={true}> Id </th> 
                            <th> Floor Name </th>
                            <th> Area Type </th>
                            <th> Area Name </th>
                            <th> Action </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        this
                        .props
                        .areaFillData
                        .map((item, i) => 
                            <tr key = {item.id} >
                                <th scope = "row" hidden={true}> {item.id} </th>
                                <td>{item.floor_name}</td>
                                <td>{item.area_cat_name}</td>
                                <td>{item.name}</td>
                                <td>
                                <button className = "btn btn-sm btn-primary marginRight_10" style={{cursor:'pointer'}} value={item.id} onClick = {this.props.toggleModal}><i className="fa fa-pencil-square-o"></i>&nbsp; Edit </button>
                                <button className = "btn btn-sm btn-danger marginRight_10" style={{cursor:'pointer'}} value={item.id} onClick = {this.props.toggleDeleteModal} ><i className="fa fa-trash"></i>&nbsp; Delete </button>
                                <button className = "btn btn-sm btn-warning " style={{cursor:'pointer'}} value={item.id} onClick = {this.props.openModal}><i className="fa fa-plus"></i>&nbsp; Add New Room </button>
                                </td>
                            </tr>
                            )} 
                    </tbody>
                 </table>
                 */}
                 {/*
                <p className="text-center row alert alert-danger" style={{margin:10}} hidden={this.props.areaFillData==""?false:true} >
                    <span className="col-md-12">Records not available for this facility.</span>
                </p>
                */}
            </div>
        )
    }
}


export default AreaTable