import React, { Component } from 'react';
import {
    Card,
    CardBlock,
        Button,
    Input,
    FormGroup,
    Label,
} from 'reactstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

class MasterTable extends Component {
    constructor() {
        super()
        this.cellWhyActionButton = this.cellWhyActionButton.bind(this);
        this.cellWhereActionButton = this.cellWhereActionButton.bind(this);
    }
    cellWhyActionButton(cell, row, enumObject, rowIndex) 
    {
            let theButton;
            theButton = 
            <div>
                <button className = "btn btn-sm btn-primary marginRight_10" style={{cursor:'pointer'}} value={row.id} onClick = {this.props.toggleEditWhyModal}><i className="fa fa-pencil-square-o"></i>&nbsp; Edit </button>
                <button className = "btn btn-sm btn-danger" style={{cursor:'pointer'}} value={row.id} onClick = {this.props.toggleWhyDeleteModal}><i className="fa fa-trash"></i>&nbsp; Delete </button>
            </div>                        
            return theButton;
    };
    cellWhereActionButton(cell, row, enumObject, rowIndex) 
    {
            let theButton;
            theButton = 
            <div>
                <button className = "btn btn-sm btn-primary marginRight_10" style={{cursor:'pointer'}} value={row.id} onClick = {this.props.toggleEditWhereModal}><i className="fa fa-pencil-square-o"></i>&nbsp; Edit </button>
                <button className = "btn btn-sm btn-danger" style={{cursor:'pointer'}} value={row.id} onClick = {this.props.toggleWhereDeleteModal}><i className="fa fa-trash"></i>&nbsp; Delete </button>
            </div>                        
            return theButton;
    };

    render () {
        var cusorPointer={cursor:'pointer'};
        var options = {
            noDataText: 'Records not found'
        };
        return (
            <span>
            <h6 className="text-center">{this.props.master_que_text}</h6>
            
            <div className="row marTop_20">
            <div className="col-md-6">
            <Card className="mb-4">
            <CardBlock className="">
            <span className="row noMar">
                <h6 className="mb-4 col-md-5 noPad padTop_5">Why Comment</h6>
                <span className="col-md-7 ">
                    <button className = "btn btn-sm btn-warning pointerClass pull-right" value="btnadd" onClick = {this.props.toggleWhyModal} ><i className="fa fa-plus lg"></i> &nbsp; Add New Why Comment </button> 
                </span>
            </span>
            <div>
                    <div className="row">
                        <div className="col-md-12 ">
                        <div className="" >
                            <BootstrapTable
                                ref='table'
                                data={this.props.whyFillData}
                                pagination={ true }
                                options={options}
                                search={ true }>
                                <TableHeaderColumn dataField='id' isKey={ true } hidden={true}>ID</TableHeaderColumn>
                                <TableHeaderColumn dataField='why_comment' dataSort={ true }>Why Comment Text</TableHeaderColumn>
                                <TableHeaderColumn dataField='action' dataFormat={this.cellWhyActionButton}>Action</TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                        </div>
                    </div>
            {/*
            <table className = "table" >
                  <tbody>
                        {
                        this
                        .props
                        .whyFillData
                        .map((item, i) => <tr key = {item.id} >
                                <th scope = "row" hidden={true} > {item.id} </th>
                                <td>{item.why_comment}</td>
                                <td className="noPadRight min_width">
                                <span className="pull-right">
                                    <button className = "btn btn-sm btn-primary marginRight_10" style={{cursor:'pointer'}} value={item.id} onClick = {this.props.toggleEditWhyModal}><i className="fa fa-pencil-square-o"></i>&nbsp; Edit </button>
                                    <button className = "btn btn-sm btn-danger" style={{cursor:'pointer'}} value={item.id} onClick = {this.props.toggleWhyDeleteModal}><i className="fa fa-trash"></i>&nbsp; Delete </button>
                                </span>
                                </td>
                            </tr>)}
                        </tbody>
                </table>
                */}
                {/*
                <p className="text-center row alert alert-danger" style={{margin:10}} hidden={this.props.whyFillData==""?false:true} >
                         <span className="col-md-12">Records not available.</span>
                </p>
                */}
                </div> 
                </CardBlock>
                </Card>
                </div>
                <div className="col-md-6">
                <Card className="mb-4 ">
                <CardBlock className="">
                <span className="row noMar">
                    <h6 className="mb-4 col-md-5 noPad padTop_5">Where Comment</h6>
                    <span className="col-md-7 ">
                        <button className = "btn btn-sm btn-warning pointerClass pull-right" value="btnadd" onClick = {this.props.toggleWhereModal}><i className="fa fa-plus lg"></i> &nbsp; Add New Where Comment </button> 
                    </span>
                </span>
                <div>
                    <div className="row">
                        <div className="col-md-12 ">
                        <div className="" >
                            <BootstrapTable
                                ref='table'
                                data={this.props.whereFillData}
                                pagination={ true }
                                options={options}
                                search={ true }>
                                <TableHeaderColumn dataField='id' isKey={ true } hidden={true}>ID</TableHeaderColumn>
                                <TableHeaderColumn dataField='where_comment' dataSort={ true }>Where Comment Text</TableHeaderColumn>
                                <TableHeaderColumn dataField='action' dataFormat={this.cellWhereActionButton}>Action</TableHeaderColumn>
                            </BootstrapTable>
                        </div>
                        </div>
                    </div>
                    {/*
                    <table className = "table" >
                        <tbody>
                            {
                            this
                            .props
                            .whereFillData
                            .map((item, i) => <tr key = {item.id} >
                                <th scope = "row" hidden={true} > {item.id} </th>
                                    <td>{item.where_comment}</td>
                                    <td className="noPadRight min_width">
                                    <span className="pull-right">
                                        <button className = "btn btn-sm btn-primary marginRight_10" style={{cursor:'pointer'}} value={item.id} onClick = {this.props.toggleEditWhereModal}><i className="fa fa-pencil-square-o"></i>&nbsp; Edit </button>
                                        <button className = "btn btn-sm btn-danger" style={{cursor:'pointer'}} value={item.id} onClick = {this.props.toggleWhereDeleteModal}><i className="fa fa-trash"></i>&nbsp; Delete </button>
                                    </span>
                                    </td>
                                </tr>)}
                            </tbody>
                    </table>
                    */}
                    {/*
                    <p className="text-center row alert alert-danger" style={{margin:10}} hidden={this.props.whereFillData==""?false:true} >
                         <span className="col-md-12">Records not available.</span>
                    </p>
                    */}
                    </div> 
                    </CardBlock>
                    </Card>
                    </div>
            </div>
            </span>
        )
    }
}


export default MasterTable