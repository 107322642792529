import React, { Component } from 'react';
import {
    Input,
     
} from 'reactstrap';

class AreaList extends Component {
    render () {
        return (
            <Input
                type="select"
                name="drpArea"
                id="drpArea"
                value={this.props.areaValue}
                onChange={this.props.drpChangeArea}>
                <option value="">--Select Area--</option>
                {this
                    .props.areaFillData
                    .map((item, i) => <option key={item.id} value={item.id}>
                        {item.name}</option>)}
            </Input>
        )
    }
}
AreaList.defaultProps=
{

    areaFillData:[],
    areaValue:""

}

export default AreaList