import React, { Component } from 'react';
import IconDot from 'react-icons/lib/md/fiber-manual-record';
import { getReportData } from '../../utilities';
var dateFormat = require('dateformat');
class ReportTable extends Component {
    // constructor(props) {
    // super(props);
    // }
    render () {
        var cusorPointer={cursor:'pointer'};
        return (
            
            <div>
                 <table className="table">
                 <thead>
                   <tr>
                      <th>Report Name</th>
                      <th>PDF Reports</th>
                   </tr>
                 </thead>
                 <tbody hidden={this.props.reportFillData==""?true:false}>
                        <tr>
                            <th scope="row">
                            {this.props.reportId === '1' ? 'Facility monthly report'
                                : [
                                (this.props.reportId === '2'
                                    ? 'Functional area monthly average score report'
                                    : (this.props.reportId === '3'
                                            ? 'Floor monthly average score report'
                                            : (this.props.reportId === '4'
                                                    ? 'Element graph report'
                                                    : (this.props.reportId === '5'
                                                            ? 'Rectification report'
                                                            : (this.props.reportId === '6'
                                                                    ? 'Score summary graph report'
                                                                    : (this.props.reportId === '7'
                                                                            ? 'Score summary report'
                                                                            : (this.props.reportId === '8'
                                                                                    ? 'All combined report'
                                                                                    : null
                                                                                )
                                                                        )
                                                                )
                                                        )
                                                )
                                        )
                                )
                                ] 
                            }
                            </th>
                            <td><div onClick={() => getReportData(this.props.reportFillData)} className="btn btn-sm btn-primary marginRight_10" href={this.props.reportFillData} >Download Report</div>
                            </td>
                         </tr>
                    {/*}
                     {this.props.reportFillData.map((item, i) => <tr key={i}>
                             <th scope="row">{i + 1}</th>
                             <td>
                            {dateFormat(item.audit_start_datetime, "mediumDate")}
                            </td>
                            <td>{item.audit_score}</td>
                                     <td>
                                    <IconDot size="12" color={item.audit_status === 1 ? '#4CAF50' : '#F44336'}/>&nbsp;{item.audit_status === 1?'done':'pending'}
                              </td>

                         <td>
                         {item.first_name===null ? "---":<span>{item.first_name} {item.last_name}</span>}
                            {/* {item.audit_by} */}
                            {/*} </td>
                            <td>
                           {dateFormat(item.rectification_date, "mediumDate")}
                            </td>
                            <td>
                
                                     <a className="btn btn-sm btn-primary" style={cusorPointer} href={item.full_report_link}>Full</a>
                                     <a className="btn btn-sm btn-info" style={cusorPointer} href={item.graph_report_link} >Graph</a>
                                    <a className="btn btn-sm btn-warning" style={cusorPointer} href={item.rectification_report_link}>Rectification</a>
                             </td>
                         </tr>)} */}
                </tbody>
            </table>
            <p className="text-center row alert alert-danger cust_alert" hidden={this.props.reportFillData==""?false:true}>
                                <span className="col-md-12">Records not available.</span>
                          </p>
            </div>
       
        )
    }
}


ReportTable.defaultProps=
{

    reportFillData:[],

}

export default ReportTable;