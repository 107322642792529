import { LOADING_OBSERVATION, STATE_WISE_OBS_QUESTIONS } from "../actions/observationQuestions_action";

const initialState = {
    observationQuestions: [],
    loadingObservation: false,
};

const observationQuestionsReducer = (state = initialState, action) => {
    console.log('action', action)
    switch (action.type) {
        case STATE_WISE_OBS_QUESTIONS:
            return Object.assign({}, state, {
                observationQuestions: action.obsQuestions,
            });
        case LOADING_OBSERVATION:
            return Object.assign({}, state, {
                loadingObservation: action.loading,
            });
        default:
            return state;
    }
};

export default observationQuestionsReducer;
