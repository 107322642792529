import React, { Component, memo } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import LaddaButton, { S, EXPAND_RIGHT } from "react-ladda";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  fetchAuditAns,
  FinalRectification,
  fetchRectificationsReport,
} from "../../actions/actions";
import { Label } from "reactstrap";

import { connect } from "react-redux";
import AntDatepicker from "antd/es/date-picker";
import moment from "moment";
const { MonthPicker } = AntDatepicker;

class FTable extends Component {
  constructor() {
    super();
    this.state = {
      audit_ans_data: [],

      fData: [],
      alertMessageText: "",
      alertMessageVisible: false,
      alertMessageColor: "",
      selectedDate: null,
    };
    this.cellActionButton = this.cellActionButton.bind(this);
    this.AuditViewData = this.AuditViewData.bind(this);
    this.toMonthChange = this.toMonthChange.bind(this);
  }

  AuditViewData = (row) => {
    this.props.openModal(row);
    var audit_id = row.audit_id;
    this.props.dispatch(fetchAuditAns(audit_id));
  };

  cellActionButton(cell, row, enumObject, rowIndex) {
    let theButton;
    const { isFilter, originalFData, filterReportData } = this.props;

    if (isFilter) {
      theButton = (
        <div>
          {filterReportData
            .filter((item) => row.audit_id == item.audit_id)
            .map((item) => {
              return item.audit_status == 1 ? (
                <button
                  className="btn btn-sm btn-warning "
                  style={{ cursor: "not-allowed", backgroundColor: "green" }}
                  value={row.id}
                  disabled="true"
                  onClick={() => this.AuditViewData(row)}
                >
                  <i className="fa fa-plus"></i>
                  &nbsp; Complete Rectification{" "}
                </button>
              ) : (
                <button
                  className="btn btn-sm btn-warning "
                  style={{ cursor: "pointer" }}
                  value={row.id}
                  onClick={() => this.AuditViewData(row)}
                >
                  <i className="fa fa-plus"></i>&nbsp; Complete Rectification{" "}
                </button>
              );
            })}
        </div>
      );
    } else {
      theButton = (
        <div>
          {originalFData
            .filter((item) => row.audit_id == item.audit_id)
            .map((item) => {
              return item.audit_status == 1 ? (
                <button
                  className="btn btn-sm btn-warning "
                  style={{ cursor: "not-allowed", backgroundColor: "green" }}
                  value={row.id}
                  disabled="true"
                  onClick={() => this.AuditViewData(row)}
                >
                  <i className="fa fa-plus"></i>
                  &nbsp; Complete Rectification{" "}
                </button>
              ) : (
                <button
                  className="btn btn-sm btn-warning "
                  style={{ cursor: "pointer" }}
                  value={row.id}
                  onClick={() => this.AuditViewData(row)}
                >
                  <i className="fa fa-plus"></i>&nbsp; Complete Rectification{" "}
                </button>
              );
            })}
        </div>
      );
    }

    console.log(filterReportData, "filterReportData");
    console.log(originalFData, "originalFData");
    return theButton;
  }

  toMonthChange(date, validDate = true) {
    let date1 = null;
    if (validDate) {
      date1 = moment(date).format("MM YYYY");
      localStorage.setItem("f_date", moment(date));
    }

    if (date === null) {
      localStorage.removeItem("f_date");
    }

    const valid = date1 || date;
    if (valid) {
      const selData = localStorage.getItem("f_date");
      let filterData = this.props.originalFData.filter((item) => {
        return (
          moment(item.audit_date).format("MM YYYY") === selData ||
          moment(item.audit_date).format("MM YYYY") === date1 ||
          moment(item.audit_date).format("MM YYYY") === date
        );
      });
      let added_ids = localStorage.getItem("added_id");
      added_ids = JSON.parse(added_ids);
      if (Array.isArray(added_ids) && added_ids.length > 0) {
        filterData = [
          ...filterData.map((d) => {
            if (added_ids.includes(d.audit_id)) {
              return { ...d };
            }
            return { ...d };
          }),
        ];
      }
      console.log(filterData, "filterData11");
      this.props.handleFilterReportData(filterData, true, false);
    } else {
      this.props.handleIsFilter(false);
      localStorage.removeItem("added_id");
    }

    this.setState((prevState) => {
      return { ...prevState, selectedDate: valid ? moment(valid) : null };
    });
  }

  clickBtnReport(e) {
    var audit_id = this.props.filterReportData.map((item) => {
      return item.audit_id;
    });
    const date = localStorage.getItem("f_date");
    if (date != null) {
      var myData = this.props.originalFData.filter((item) => {
        return (
          moment(item.audit_date).format("MM YYYY") ===
          moment(date).format("MM YYYY")
        );
      });
      var passData = myData.filter((data) => {
        return data.audit_status === 1;
      });
      if (passData.length == myData.length) {
        var post = {
          audit_id: audit_id,
          facility_id: this.props.facilityId,
          state_id: this.props.stateId,
        };
        this.props.dispatch(FinalRectification(post)).then(() => {
          this.props.dispatch(fetchRectificationsReport(this.props.facilityId));
        });
      } else {
        this.props.handleUpdateMessage();
      }
    } else {
      this.props.handleUpdateMessage();
    }
  }

  render() {
    const {
      isFilter,

      filterReportData,
      originalFData,
    } = this.props;
    console.log("in table", this.props.originalFData);

    const options = {
      noDataText: "Records not found",
    };

    return (
      <div>
        <div>
          <div>
            <div style={{ float: "right", marginLeft: "15px" }}>
              <Label>
                <b>MONTH: </b>
              </Label>
              &nbsp;&nbsp;&nbsp;
              <MonthPicker
                withPortal
                onChange={this.toMonthChange}
                placeholder="Select Month"
                defaultValue={this.state.selectedDate}
                value={this.state.selectedDate}
              />
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-md-12 ">
                <div>
                  <BootstrapTable
                    ref="table"
                    data={isFilter ? filterReportData : originalFData}
                    pagination={true}
                    options={options}
                  >
                    <TableHeaderColumn
                      dataField="id"
                      isKey={true}
                      hidden={true}
                    >
                      ID
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="audit_by" dataSort={true}>
                      Audit By
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="area_name" dataSort={true}>
                      Area
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="floor_name" dataSort={true}>
                      Floor
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="room_name" dataSort={true}>
                      Rooom
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField="audit_date" dataSort={true}>
                      Audit Date
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="action"
                      dataFormat={this.cellActionButton}
                    >
                      Action
                    </TableHeaderColumn>
                  </BootstrapTable>
                </div>
              </div>
            </div>
            <div style={{ float: "right", marginRight: "15px" }}>
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.clickBtnReport();
                }}
                data-color="#eee"
                data-size={S}
                data-style={EXPAND_RIGHT}
                data-spinner-size={30}
                data-spinner-color="#ddd"
                data-spinner-lines={12}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { postsBySubreddit } = state;
  const {
    audit_ans_data: audit_ans_data,
    final_report: final_report,
  } = postsBySubreddit;
  return {
    audit_ans_data,
    final_report,
  };
}

const AppContainer = connect(mapStateToProps)(FTable);

export default memo(AppContainer);
