import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Input } from "reactstrap";

class ReportListData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            report_type: [],
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.report_type !== prevProps.report_type) {
            this.setState({
                report_type: this.props.report_type,
            });
        }
    }

    render() {
        return (
            <Input
                ref={this.props.refs}
                type="select"
                name="drpReport"
                id="drpReport"
                value={this.props.reportValue}
                onChange={this.props.drpChangeReport}
            >
                <option value="">--Select Report Type--</option>
                {this.props.report_type.map(({ report_type_id, name }, i) => {
                    return (
                        <Fragment key={i}>
                            <option value={report_type_id}>{name}</option>
                        </Fragment>
                    );
                })}
                {/* <option key="1" value="1">
                    Facility monthly report
                </option>
                <option key="2" value="2">
                    Functional area monthly average score report
                </option>
                <option key="3" value="3">
                    Floor monthly average score report
                </option>
                <option key="4" value="4">
                    Element graph report
                </option>
                <option key="5" value="5">
                    Rectification report
                </option>
                <option key="6" value="6">
                    Score summary graph report
                </option>
                <option key="7" value="7">
                    Score summary report
                </option> */}
            </Input>
        );
    }
}
ReportListData.defaultProps = {
    reportFillData: [],
    reportValue: "",
};

function mapStateToProps(state) {
    const { postsBySubreddit } = state;
    const { report_type } = postsBySubreddit;

    return { report_type };
}

const ReportList = connect(mapStateToProps)(ReportListData);

export default ReportList;
