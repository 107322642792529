import React, { Component } from "react";
import { connect } from "react-redux";
import {
    Button,
    Card,
    CardBlock,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import { fetchStateList } from "../../actions/actions";
import { fetchQuestionCategoryList } from "../../actions/questions_actions";
import simpleReactValidatorMin from "simple-react-validator";
import "./style.css";
import {
    addOBSQuestions,
    deleteOBSQuestions,
    fetchStateWiseOBSQuestions,
    setObservationLoadingState,
    updateOBSQuestions,
} from "../../actions/observationQuestions_action";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import LoadingImage from "../../../assets/images/Eclipse.gif";

class ObservationQuestions extends Component {
    constructor(props) {
        super(props);
        this.validator = new simpleReactValidatorMin(
            (this.rules = {
                custom_text: {
                    message: "The :attribute must be a valid text.",
                    rule: (val) =>
                        !this._testRegex(
                            val,
                            /^[0-9@!#\$\^%&*()+=\-\[\]\\\';,\.\/\{\}\|\":<>\? ]+$/
                        ),
                },
            })
        );
        this.state = {
            loading: false,
            confirmDeleteLoader: false,
            addQuestionLoader: false,
            editQuestionLoader: false,
            selectedState: "",
            stateError: false,
            state_data: [],
            deleteModalState: false,
            selectedQuestion: {},
            addQuestionsModalState: false,
            editQuestionsModalState: false,

            addQuestionsErrors: {
                stateId: false,
                questionText: false,
                questionInfo: false,
            },
        };

        this.stateRef = null;
        this.questionRef = null;
        this.questionInfoRef = null;
        this.addQuestionStateRef = null;

        this.editQuestionInfoRef = null;
        this.editQuestionTextRef = null;
        this.editQuestionStateRef = null;

        this.handleLoadQuestions = this.handleLoadQuestions.bind(this);

        this.updateRefs = this.updateRefs.bind(this);

        this.handleQuestionsModalState =
            this.handleQuestionsModalState.bind(this);

        this.handleStateChange = this.handleStateChange.bind(this);

        this.changeDeleteMOdalState = this.changeDeleteMOdalState.bind(this);

        this.changeEditMOdalState = this.changeEditMOdalState.bind(this);

        this.confirmDelete = this.confirmDelete.bind(this);

        this.handleAddQuestion = this.handleAddQuestion.bind(this);

        this.handleEditQuestion = this.handleEditQuestion.bind(this);

        this.handleFieldChange = this.handleFieldChange.bind(this);
    }

    componentDidMount() {
        var id = sessionStorage.getItem("role_id");
        console.log(id);
        const { dispatch } = this.props;
        dispatch(fetchStateList(id));
        dispatch(fetchQuestionCategoryList());
    }

    updateRefs = (element, type) => {
        switch (type) {
            case "state":
                this.stateRef = element;
                return;
            case "questionText":
                this.questionRef = element;
                return;
            case "questionInfo":
                this.questionInfoRef = element;
                return;
            case "add_question_state":
                this.addQuestionStateRef = element;
                return;
            case "editQuestionText":
                this.editQuestionTextRef = element;
                return;
            case "editQuestionInfo":
                this.editQuestionInfoRef = element;
                return;
            case "edit_question_state":
                this.editQuestionStateRef = element;
                return;
        }
    };

    handleStateChange = (event) => {
        let targetValue = event.target.value;
        this.setState((prevState) => {
            return {
                ...prevState,
                selectedState: targetValue,
                stateError: false,
            };
        });
    };

    handleLoadQuestions = (event) => {
        event.preventDefault();

        const { dispatch } = this.props;

        if (this.stateRef.value !== "") {
            dispatch(setObservationLoadingState(true));
            dispatch(fetchStateWiseOBSQuestions(this.stateRef.value)).then(() =>
                dispatch(setObservationLoadingState())
            );
        } else {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    stateError: true,
                };
            });
        }
    };

    changeDeleteMOdalState = (question = {}) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                deleteModalState: !this.state.deleteModalState,
                selectedQuestion: question,
            };
        });
    };

    changeEditMOdalState = (question = {}) => {
        this.setState((prevState) => {
            return {
                ...prevState,
                editQuestionsModalState: !this.state.editQuestionsModalState,
                selectedQuestion: question,
            };
        });
    };

    confirmDelete = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                confirmDeleteLoader: true,
            };
        });
        this.props
            .dispatch(deleteOBSQuestions(this.state.selectedQuestion.id))
            .then(({ success }) => {
                if (success) {
                    this.props.dispatch(
                        fetchStateWiseOBSQuestions(this.stateRef.value)
                    );
                }
            })
            .finally(() => {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        deleteModalState: false,
                        confirmDeleteLoader: false,
                    };
                });
            });
    };

    handleQuestionsModalState = (value = false) => {
        this.setState((prevState) => ({
            ...prevState,
            addQuestionsModalState: value,
        }));
    };

    handleAddQuestion = () => {
        const { dispatch } = this.props;
        let state_id = this.addQuestionStateRef.value.trim();
        let que_text = this.questionRef.value.trim();
        let que_info = this.questionInfoRef.value.trim();

        if (state_id === "" || que_text === "" || que_info === "") {
            if (state_id === "") {
                this.setState((prevState) => ({
                    ...prevState,
                    addQuestionsErrors: {
                        ...prevState.addQuestionsErrors,
                        stateId: true,
                    },
                }));
            }

            if (que_text === "") {
                this.setState((prevState) => ({
                    ...prevState,
                    addQuestionsErrors: {
                        ...prevState.addQuestionsErrors,
                        questionText: true,
                    },
                }));
            }

            if (que_info === "") {
                this.setState((prevState) => ({
                    ...prevState,
                    addQuestionsErrors: {
                        ...prevState.addQuestionsErrors,
                        questionInfo: true,
                    },
                }));
            }
        } else {
            const body = {
                state_id: +state_id,
                que_text,
                que_info,
            };
            this.setState((prevState) => ({
                ...prevState,
                addQuestionLoader: true,
            }));

            dispatch(addOBSQuestions(body))
                .then(({ success }) => {
                    if (success && this.state.selectedState.trim() !== "") {
                        dispatch(
                            fetchStateWiseOBSQuestions(this.state.selectedState)
                        );
                    }
                })
                .finally(() => {
                    this.setState((prevState) => ({
                        ...prevState,
                        addQuestionLoader: false,
                        addQuestionsModalState: false,
                    }));
                });
        }
    };

    handleEditQuestion = () => {
        const { dispatch } = this.props;
        let state_id = this.editQuestionStateRef.value.trim();
        let que_text = this.editQuestionTextRef.value.trim();
        let que_info = this.editQuestionInfoRef.value.trim();

        if (que_text === "" || que_info === "") {
            if (que_text === "") {
                this.setState((prevState) => ({
                    ...prevState,
                    addQuestionsErrors: {
                        ...prevState.addQuestionsErrors,
                        questionText: true,
                    },
                }));
            }

            if (que_info === "") {
                this.setState((prevState) => ({
                    ...prevState,
                    addQuestionsErrors: {
                        ...prevState.addQuestionsErrors,
                        questionInfo: true,
                    },
                }));
            }
        } else {
            const body = {
                id: +state_id,
                que_text,
                que_info,
            };

            this.setState((prevState) => ({
                ...prevState,
                editQuestionLoader: true,
            }));
            dispatch(updateOBSQuestions(body))
                .then(({ success }) => {
                    if (success && this.state.selectedState.trim() !== "") {
                        dispatch(
                            fetchStateWiseOBSQuestions(this.state.selectedState)
                        );
                    }
                })
                .finally(() => {
                    this.setState((prevState) => ({
                        ...prevState,
                        editQuestionLoader: false,
                        editQuestionsModalState: false,
                    }));
                });
        }
    };

    handleFieldChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;

        switch (name) {
            case "add_question_state":
                if (value.trim()) {
                    this.setState((prevState) => ({
                        ...prevState,
                        addQuestionsErrors: {
                            ...prevState.addQuestionsErrors,
                            stateId: false,
                        },
                    }));
                }
                return;
            case "questionText":
                if (value.trim()) {
                    this.setState((prevState) => ({
                        ...prevState,
                        addQuestionsErrors: {
                            ...prevState.addQuestionsErrors,
                            questionText: false,
                        },
                    }));
                }
                return;
            case "questionInfo":
                if (value.trim()) {
                    this.setState((prevState) => ({
                        ...prevState,
                        addQuestionsErrors: {
                            ...prevState.addQuestionsErrors,
                            questionInfo: false,
                        },
                    }));
                }
                return;

            case "editQuestionText":
                if (value.trim()) {
                    this.setState((prevState) => ({
                        ...prevState,
                        addQuestionsErrors: {
                            ...prevState.addQuestionsErrors,
                            questionText: false,
                        },
                    }));
                }
                return;
            case "editQuestionInfo":
                if (value.trim()) {
                    this.setState((prevState) => ({
                        ...prevState,
                        addQuestionsErrors: {
                            ...prevState.addQuestionsErrors,
                            questionInfo: false,
                        },
                    }));
                }
                return;
        }
    };

    render() {
        const { state_data, observationQuestions, loadingObservation } =
            this.props;

        const {
            deleteModalState,
            confirmDeleteLoader,
            addQuestionsModalState,
            addQuestionsErrors,
            addQuestionLoader,
            editQuestionsModalState,
            editQuestionLoader,
            selectedQuestion,
        } = this.state;

        const ViewHeader = () => (
            <div className="view-header">
                <header className="title text-white">
                    <h1
                        style={{ color: "white" }}
                        className="h4 text-uppercase"
                    >
                        {" "}
                        Questions{" "}
                    </h1>
                    <p className="mb-0">
                        {" "}
                        Manage audit related questions here{" "}
                    </p>
                </header>
            </div>
        );

        const ViewContent = ({ children }) => (
            <div className="view-content view-components">{children}</div>
        );

        const StateFacilityDropDownList = () => (
            <div className="observation__question">
                <form onSubmit={this.handleLoadQuestions}>
                    <div className="row ">
                        <div className="col-md-4 marBtm_20">
                            <h6 className="mb-4 text-uppercase">
                                {" "}
                                Select State{" "}
                            </h6>
                            <select
                                className="form-control"
                                type="text"
                                name="state"
                                id="state"
                                ref={(element) =>
                                    this.updateRefs(element, "state")
                                }
                                value={this.state.selectedState}
                                onChange={this.handleStateChange}
                            >
                                <option value="">--Select State--</option>
                                {state_data.map(({ state_id, state_name }) => {
                                    return (
                                        <option key={state_id} value={state_id}>
                                            {state_name}
                                        </option>
                                    );
                                })}
                            </select>
                            {this.state.stateError && (
                                <span className="error-text">
                                    <div className="validation-message">
                                        The State field is required.
                                    </div>
                                </span>
                            )}
                        </div>
                        <div className="col-md-4 observation_btn ">
                            <button className="btn btn-warning" type="submit">
                                Load Question
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );

        return (
            <div>
                <div className="view">
                    <ViewHeader />

                    <ViewContent>
                        <Card className="mb-4">
                            <CardBlock className="table-responsive">
                                <StateFacilityDropDownList />
                            </CardBlock>
                        </Card>

                        {/* Questions Table */}
                        <Card className="mb-4 col-md-12">
                            <CardBlock className="table-responsive">
                                <div className="observation__table__header">
                                    <h6
                                        className="text-uppercase"
                                        ref="scroll_div"
                                    >
                                        Manage Audit Questions
                                    </h6>
                                    <div>
                                        <button
                                            className="btn btn-warning"
                                            onClick={() =>
                                                this.handleQuestionsModalState(
                                                    true
                                                )
                                            }
                                        >
                                            Add New Questions
                                        </button>
                                    </div>
                                </div>
                                {loadingObservation && (
                                    <div style={{ textAlign: "center" }}>
                                        <img src={LoadingImage} alt="loader" />
                                    </div>
                                )}
                                <BootstrapTable
                                    ref="table"
                                    data={observationQuestions}
                                    pagination={true}
                                    options={{
                                        noDataText: "Records not found",
                                    }}
                                    search={true}
                                >
                                    <TableHeaderColumn
                                        dataField="id"
                                        isKey={true}
                                        hidden={true}
                                    >
                                        ID
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="que_text"
                                        dataSort={true}
                                    >
                                        Question Text
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="que_info"
                                        dataSort={true}
                                    >
                                        Help Text
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="action"
                                        dataFormat={(
                                            cell,
                                            row,
                                            enumObject,
                                            rowIndex
                                        ) => (
                                            <div>
                                                <button
                                                    className="btn btn-sm btn-primary marginRight_10"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    value={row.id}
                                                    onClick={() =>
                                                        this.changeEditMOdalState(
                                                            row
                                                        )
                                                    }
                                                >
                                                    <i className="fa fa-pencil-square-o"></i>
                                                    &nbsp; Edit{" "}
                                                </button>
                                                <button
                                                    className="btn btn-sm btn-danger marginRight_10"
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    value={row.id}
                                                    onClick={() =>
                                                        this.changeDeleteMOdalState(
                                                            row
                                                        )
                                                    }
                                                >
                                                    <i className="fa fa-trash"></i>
                                                    &nbsp; Delete{" "}
                                                </button>
                                            </div>
                                        )}
                                    >
                                        Action
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </CardBlock>
                        </Card>
                    </ViewContent>
                </div>

                {/* Add Questions Modal */}

                <Modal
                    isOpen={addQuestionsModalState}
                    toggle={() => this.handleQuestionsModalState()}
                    backdrop={"static"}
                    keyboard={true}
                >
                    <ModalHeader
                        toggle={() => this.handleQuestionsModalState()}
                    >
                        Add New Question
                    </ModalHeader>
                    <ModalBody>
                        <div className="add__question__form">
                            <div>
                                <label>Select State</label>
                                <select
                                    className="form-control"
                                    type="text"
                                    name="add_question_state"
                                    id="add_question_state"
                                    onChange={this.handleFieldChange}
                                    ref={(element) =>
                                        this.updateRefs(
                                            element,
                                            "add_question_state"
                                        )
                                    }
                                >
                                    <option value="">--Select State--</option>
                                    {state_data.map(
                                        ({ state_id, state_name }) => {
                                            return (
                                                <option
                                                    key={state_id}
                                                    value={state_id}
                                                >
                                                    {state_name}
                                                </option>
                                            );
                                        }
                                    )}
                                </select>
                                {addQuestionsErrors.stateId && (
                                    <span className="error-text">
                                        <div className="validation-message">
                                            The State field is required.
                                        </div>
                                    </span>
                                )}
                            </div>
                            <div>
                                <label>Question Text</label>
                                <textarea
                                    name="questionText"
                                    className="form-control"
                                    id="questionText"
                                    rows={5}
                                    onChange={this.handleFieldChange}
                                    ref={(element) =>
                                        this.updateRefs(element, "questionText")
                                    }
                                ></textarea>
                                {addQuestionsErrors.questionText && (
                                    <span className="error-text">
                                        <div className="validation-message">
                                            This field is required.
                                        </div>
                                    </span>
                                )}
                            </div>
                            <div>
                                <label>Question Help Text</label>
                                <textarea
                                    name="questionInfo"
                                    className="form-control"
                                    id="questionInfo"
                                    rows={3}
                                    onChange={this.handleFieldChange}
                                    ref={(element) =>
                                        this.updateRefs(element, "questionInfo")
                                    }
                                ></textarea>
                                {addQuestionsErrors.questionInfo && (
                                    <span className="error-text">
                                        <div className="validation-message">
                                            This field is required.
                                        </div>
                                    </span>
                                )}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={this.handleAddQuestion}
                        >
                            {addQuestionLoader ? (
                                <div
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                >
                                    <span className="visually-hidden">
                                        Loading...
                                    </span>
                                </div>
                            ) : (
                                "Add"
                            )}
                        </Button>
                        <Button
                            color=""
                            className="pointerClass btn btn-warning"
                            onClick={() => this.handleQuestionsModalState()}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>

                {/* Edit Question Modal */}

                <Modal
                    isOpen={editQuestionsModalState}
                    toggle={() => this.changeEditMOdalState()}
                    backdrop={"static"}
                    keyboard={true}
                >
                    <ModalHeader toggle={() => this.changeEditMOdalState()}>
                        Edit Question
                    </ModalHeader>
                    <ModalBody>
                        <div className="add__question__form">
                            <input
                                className="form-control"
                                type="hidden"
                                name="edit_question_state"
                                id="edit_question_state"
                                defaultValue={selectedQuestion.id}
                                ref={(element) =>
                                    this.updateRefs(
                                        element,
                                        "edit_question_state"
                                    )
                                }
                            />
                            <div>
                                <label>Question Text</label>
                                <textarea
                                    name="editQuestionText"
                                    className="form-control"
                                    id="editQuestionText"
                                    rows={5}
                                    defaultValue={selectedQuestion.que_text}
                                    onChange={this.handleFieldChange}
                                    ref={(element) =>
                                        this.updateRefs(
                                            element,
                                            "editQuestionText"
                                        )
                                    }
                                ></textarea>
                                {addQuestionsErrors.questionText && (
                                    <span className="error-text">
                                        <div className="validation-message">
                                            This field is required.
                                        </div>
                                    </span>
                                )}
                            </div>
                            <div>
                                <label>Question Help Text</label>
                                <textarea
                                    name="editQuestionInfo"
                                    className="form-control"
                                    id="editQuestionInfo"
                                    rows={3}
                                    defaultValue={selectedQuestion.que_info}
                                    onChange={this.handleFieldChange}
                                    ref={(element) =>
                                        this.updateRefs(
                                            element,
                                            "editQuestionInfo"
                                        )
                                    }
                                ></textarea>
                                {addQuestionsErrors.questionInfo && (
                                    <span className="error-text">
                                        <div className="validation-message">
                                            This field is required.
                                        </div>
                                    </span>
                                )}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={this.handleEditQuestion}
                        >
                            {editQuestionLoader ? (
                                <div
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                >
                                    <span className="visually-hidden">
                                        Loading...
                                    </span>
                                </div>
                            ) : (
                                "Edit"
                            )}
                        </Button>
                        <Button
                            color=""
                            className="pointerClass btn btn-warning"
                            onClick={() => this.changeEditMOdalState()}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>

                {/* Delete Question Modal */}
                <Modal
                    isOpen={deleteModalState}
                    toggle={this.changeDeleteMOdalState}
                    backdrop={"static"}
                    keyboard={true}
                >
                    <ModalHeader toggle={this.changeDeleteMOdalState}>
                        Confirmation Alert
                    </ModalHeader>
                    <ModalBody>Are you sure you want to delete ?</ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.confirmDelete}>
                            {confirmDeleteLoader ? (
                                <div
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                >
                                    <span className="visually-hidden">
                                        Loading...
                                    </span>
                                </div>
                            ) : (
                                "Yes"
                            )}
                        </Button>
                        <Button
                            color=""
                            className="pointerClass btn btn-warning"
                            onClick={this.changeDeleteMOdalState}
                        >
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { postsBySubreddit, observationQuestionsReducer } = state;
    const { state_data } = postsBySubreddit;
    const { observationQuestions, loadingObservation } =
        observationQuestionsReducer;

    return {
        state_data,
        observationQuestions,
        loadingObservation,
    };
}

const ObservationQuestionsContainer =
    connect(mapStateToProps)(ObservationQuestions);

export default ObservationQuestionsContainer;
