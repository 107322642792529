import React from "react";
import ReactDOM from "react-dom";
import {
  Router,
  useRouterHistory,
  Route,
  IndexRoute,
  Redirect,
} from "react-router";
import createBrowserHistory from "history/lib/createBrowserHistory";
import routes from "./routes";
import { Provider } from "react-redux";
import configureStore from "./shared/store/configureStore";
import App from "./App";

import Login from "./shared/containers/login/index";
import Dashboard from "./shared/containers/dashboard";
import AutoLogin from "./shared/containers/autoLogin";
import FacilitySetup from "./shared/containers/facilitysetup";
import Questions from "./shared/containers/questions";
import ObservationQuestions from "./shared/containers/observationQuestions";
import QuestionsFac from "./shared/containers/questions_fac";
import CorrectiveReport from "./shared/containers/correctiveReport/index-backup";
import AuditReport from "./shared/containers/auditReport";

import Reports from "./shared/containers/reports";
//import Reports from './views/reports';
import Page404 from "./views/pages/404";
import { LocaleProvider } from "antd";
import en_US from "antd/lib/locale-provider/en_US";

// import main style dependency file
import "antd/lib/date-picker/style/css";
import "./index.css";
import RectificationReportContainer from "./shared/containers/correctiveReport";
import AuditRegisterContainer from "./shared/containers/auditScheduled/AuditRegister";
import TrackingReportContainer from "./shared/containers/auditScheduled/TrackingReport";

const store = configureStore();

// const history = useRouterHistory(createBrowserHistory)({
//   basename: "/", // to serve this template from subdirectory, change the base path.

//   // basename: '/react/cleaning/'
// });

const AppMain = () => {
  const history = useRouterHistory(createBrowserHistory)({
    basename: "/", // to serve this template from subdirectory, change the base path.

    // basename: '/react/cleaning/'
  });
  return (
    <div>
      <LocaleProvider locale={en_US}>
        <Provider store={store}>
          <div>
            <Router history={history}>
              <Route>
                <Route component={Login} exact path="/" />
                <Route path="autologin" component={AutoLogin} />
                <Route component={App} path="/">
                  <IndexRoute component={Dashboard} />
                  <Route path="dashboard" component={Dashboard} />
                  <Route path="facilitysetup" component={FacilitySetup} />
                  <Route path="state_questions" component={Questions} />
                  <Route path="observation_questions" component={ObservationQuestions} />
                  <Route path="facility_questions" component={QuestionsFac} />
                  <Route path="reports" component={Reports} />
                  <Route path="correctivereport" component={RectificationReportContainer} />
                  <Route path="audit-register" component={AuditRegisterContainer} />
                  <Route path="tracking-report" component={TrackingReportContainer} />
                  {/* <Route path="auditreport" component={AuditReport} /> */}
                </Route>

                <Route component={Page404} path="404" />
                <Redirect from="*" to="404" />
              </Route>
            </Router>
          </div>
        </Provider>
      </LocaleProvider>
    </div>
  );
};

ReactDOM.render(<AppMain />, document.getElementById("root"));
