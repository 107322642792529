import { Component } from "react"
import { connect } from "react-redux";
import { Card, CardBody, FormGroup } from "reactstrap";
import simpleReactValidatorMin from "simple-react-validator";
import { fetchFacilityList, fetchStateList, getRectificationReportAction } from "../../actions/actions";
import StateListClass from "../../components/state_list";
import FacilityListClass from "../../components/facility_list";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './react-date-picker.css'
import moment from "moment";
import { getReportData } from "../../utilities";

class RectificationReport extends Component {
    constructor(props) {
        super(props);
        this.validator = new simpleReactValidatorMin(
            (this.rules = {
                custom_text: {
                    message: "The :attribute must be a valid text.",
                    rule: (val) =>
                        !this._testRegex(
                            val,
                            /^[0-9@!#\$\^%&*()+=\-\[\]\\\';,\.\/\{\}\|\":<>\? ]+$/
                        ),
                },
            })
        );

        // State
        this.state = {
            selectedState: "",
            selectedFacility: "",
            selectedYear: new Date(),
            loading: false,

        }

        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleFacilityChange = this.handleFacilityChange.bind(this);
        this.handleYearChange = this.handleYearChange.bind(this);
        this.searchRectificationReports = this.searchRectificationReports.bind(this);


        this.updateAppState = this.updateAppState.bind(this);
    }

    componentDidMount() {
        const id = sessionStorage.getItem("role_id");

        const { dispatch } = this.props;
        dispatch(fetchStateList(id));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedState !== this.state.selectedState) {
            this.updateAppState('selectedFacility', "")
            if (this.state.selectedState) {
                this.props.dispatch(fetchFacilityList(this.state.selectedState));
            }
        }
    }

    updateAppState(key, value) {
        this.setState((prevState) => {
            return {
                ...prevState,
                [key]: value
            }
        })
    }

    handleStateChange(e) {
        this.updateAppState('selectedState', e.target.value)
    }

    handleFacilityChange(e) {
        this.updateAppState('selectedFacility', e.target.value)
    }

    handleYearChange(e) {
        this.updateAppState('selectedYear', e)
    }

    searchRectificationReports(e) {
        var that = this;
        if (that.validator.allValid()) {
        } else {
            that.validator.showMessages();
            // rerender to show messages for the first time
            that.forceUpdate();
            return;
        }
        this.updateAppState('loading', true)

        let newBody = {
            facility_id: parseInt(this.state.selectedFacility),
            year: parseInt(moment(this.state.selectedYear).format('yyyy'))
        }

        this.props.dispatch(getRectificationReportAction(newBody)).then(() => { }).finally(() => {
            this.updateAppState('loading', false)
        })

    }

    render() {

        const { state_data, facility_data, rectifications_data } = this.props;
        const { selectedYear, selectedState, selectedFacility, loading } = this.state

        function ViewHeader() {
            return (
                <div className="view-header">
                    <header className="title text-white">
                        <h1 style={{ color: "white" }} className="h4 text-uppercase">
                            Rectification Report
                        </h1>
                        <p className="mb-0">
                            View and export audit and rectification reports here.
                        </p>
                    </header>
                </div>
            );
        }

        const DefaultTable = () => (
            <table className="table">
                <thead>
                    <tr>
                        <th>Facility Name</th>
                        <th>Report Name</th>
                        <th>Report Date</th>
                        <th>PDF Reports</th>
                    </tr>
                </thead>
                <tbody>

                    {rectifications_data.map(({ name, pdf_url, date, facility_name }, index) => {
                        return (
                            <tr key={index}>
                                <td scope="row">
                                    <p>{facility_name}</p>
                                </td>
                                <td scope="row">
                                    <p>{name}</p>
                                </td>
                                <td scope="row">
                                    <p>{date.split('-').join(" ")}</p>
                                </td>
                                <td>
                                    <div
                                        onClick={() => getReportData(pdf_url)}
                                        className="btn btn-sm btn-primary marginRight_10"
                                    >
                                        Download Report
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );



        return (<>
            <div className="view">
                <ViewHeader />

                <div className="view-content view-components rr_search_form">
                    <Card className="mb-4">
                        <CardBody className="table-responsive">
                            <div>
                                <div className="row">

                                    {/* Year Picker Dropdown */}
                                    <div className="col-md-2 ">
                                        <div className="rr_year_picker">
                                            <FormGroup>
                                                <h6 className="text-uppercase"> Year</h6>
                                                <DatePicker
                                                    className="form-control"
                                                    selected={selectedYear}
                                                    selectsStart
                                                    withPortal
                                                    value={selectedYear}
                                                    maxDate={new Date()}
                                                    dateFormat="yyyy"
                                                    onChange={this.handleYearChange}
                                                    showYearPicker
                                                />
                                            </FormGroup>

                                        </div>
                                    </div>

                                    {/* Select State Dropdown */}
                                    <div className="col-md-4 ">
                                        <FormGroup>
                                            <h6 className="text-uppercase"> Select State </h6>
                                            <StateListClass
                                                stateValue={selectedState}
                                                drpChangeState={this.handleStateChange}
                                                stateFillData={state_data}
                                            />
                                            <span className="error-text">
                                                {this.validator.message(
                                                    "state",
                                                    selectedState,
                                                    "required"
                                                )}
                                            </span>
                                        </FormGroup>
                                    </div>

                                    {/* Facility Dropdown */}
                                    <div className="col-md-4 ">
                                        <h6 className="text-uppercase"> Select Facility</h6>
                                        <FacilityListClass
                                            facilityValue={selectedFacility}
                                            drpChangeFac={this.handleFacilityChange}
                                            facilityFillData={facility_data}
                                        />
                                        <span className="error-text">
                                            {this.validator.message(
                                                "Facility",
                                                selectedFacility,
                                                "required"
                                            )}
                                        </span>
                                    </div>

                                    {/* Search Btn */}
                                    <div className="col-md-2">
                                        <button
                                            className="btn btn-warning search_btn"
                                            onClick={this.searchRectificationReports}
                                        >
                                            {loading ?
                                                <div class="spinner-border spinner-border-sm text-light" role="status" />
                                                :
                                                'Load Item'
                                            }
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </CardBody>
                    </Card>

                    <Card className="mb-4 col-md-12" hidden={this.state.showDefaultTable}>
                        <CardBody className="table-responsive">
                            <h6 className="mb-4 text-uppercase"> Audit Report </h6>
                            <DefaultTable />
                        </CardBody>
                    </Card>
                </div>


            </div>
        </>)
    }
}

function mapStateToProps(state) {
    const { postsBySubreddit } = state;

    const { final_report, state_data, facility_data, rectifications_data } = postsBySubreddit

    return { final_report, state_data, facility_data, rectifications_data }
}

const RectificationReportContainer = connect(mapStateToProps)(RectificationReport)

export default RectificationReportContainer;