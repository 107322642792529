import React from "react";
import { Link } from "react-router";
import screenfull from "screenfull";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap";
import { browserHistory } from "react-router";

import IconFullScreen from "react-icons/lib/md/crop-free";
import IconSearch from "react-icons/lib/md/search";
import IconLogout from "react-icons/lib/md/power-settings-new";
import IconMenu from "react-icons/lib/md/menu";

// style
import "./style.css";
import { getRequest } from "../../../utils/apiUtils";
var axios = require("axios");

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_data: "",
      first_name: "",
    };
  }

  componentDidMount() {
    var that = this;
    if (localStorage.getItem("ck_name") !== "") {
      let tokenId = localStorage.getItem("ck_name");
      console.log(tokenId, "mytokn1");
      var post = {
        token_id: tokenId,
      };
      // this.props.dispatch(FinalRectification(post));
    }
    getRequest({ url: "api/get_user_data/"})
      .then(function (response) {
        if (response.data != "") {
          // const queryParams = new URLSearchParams(window.location.search);
          // const id = queryParams.get("token");
          // console.log(id, "id");
          // var ck_name = "connect.sid";
          // document.cookie = ck_name + "=" + id + "; path=/";
          that.setState({
            first_name: response.data.first_name,
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
          } else {
            console.log(error);
          }
        } else {
          console.log(error);
        }
      });
  }

  render() {
    return (
      <header className="site-head d-flex align-items-center justify-content-between">
        <div className="wrap mr-4">
          <IconMenu
            size="24"
            color="#fff"
            onClick={this.props.toggleNav}
            style={{
              cursor: "pointer",
            }}
          />
        </div>
        <form className="col-7 col-sm-8 col-md-7 p-0 site-search">
          <IconSearch color="#515151" size="22" />
          <input
            type="text"
            placeholder="Type your search ..."
            className="form-control"
          />
        </form>
        <div className="right-elems ml-auto d-flex">
          <div className="wrap hidden-sm-down">
            <IconFullScreen
              size="22"
              color="#fff"
              onClick={() => screenfull.toggle()}
            />
          </div>

          <div className="wrap profile">
            <UncontrolledDropdown>
              <DropdownToggle tag="div">
                <span className="cust_header">
                  WELCOME {this.state.first_name}
                </span>
                <img src="http://i.imgur.com/0rVeh4A.jpg" alt="avatar" />
                {/*<img src="/assets/images/images.jpg" alt="avatar"/>*/}
              </DropdownToggle>
              <DropdownMenu
                right
                style={{
                  minWidth: "12rem",
                  paddingTop: "0px",
                }}
              >
                <div className="text-right ml-3 mr-3 mt-2">
                  {/*<a onClick={this.props.logout} > <Button block color="success" size="sm"><IconLogout size="15"/>&emsp;Logout</Button></a>*/}
                  <Link to="/" onClick={this.props.logout}>
                    {" "}
                    <Button block color="success" size="sm">
                      <IconLogout size="15" />
                      &emsp;Logout
                    </Button>
                  </Link>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </header>
    );
  }
}
