import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Card, CardBlock, CardBody, FormGroup, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import StateListClass from "../../../shared/components/state_list";
import { fetchStateList, getAuditTrackingReportAction } from "../../actions/actions";
import './react-date-picker.css'
import * as XLSX from "xlsx";
import simpleReactValidatorMin from "simple-react-validator";
import './trackingReport.css'

const TrackingReport = (props) => {

    const { dispatch, state_data, audit_tracking_reports } = props;

    const [stateId, setStateId] = useState(null)
    const [year, setYear] = useState(new Date())
    const [loading, setLoading] = useState(false)
    const [trackingReportData, setTrackingReportData] = useState([])
    const [initialMonths, setInitialMonths] = useState(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'])
    const [totalMonths, setTotalMonths] = useState(null)
    const [error, setError] = useState({ date: false, state: false })
    const [fileName, setFileName] = useState('')

    const stateRef = useRef();
    const yearRef = useRef();
    const tableRef = useRef();
    const simpleValidator = useRef(new simpleReactValidatorMin())

    useEffect(() => {
        var id = sessionStorage.getItem("role_id");
        dispatch(fetchStateList(id));
    }, [])


    useEffect(() => {
        if (Array.isArray(audit_tracking_reports) && audit_tracking_reports.length) {

            const { audit_tracking_reports } = props

            let randomInteger = Math.floor(Math.random() * audit_tracking_reports.length)
            let numberOfMonths = Object.keys(audit_tracking_reports[randomInteger].audit_status).length

            setTotalMonths(numberOfMonths)


        }
    }, [audit_tracking_reports])

    const onStateChange = (event) => {
        let targetValue = event.target.value;
        setStateId(() => targetValue)
        setError((prevState) => ({ ...prevState, state: false }))
    };

    const onDateChange = (date) => {
        setYear(() => date)
        setError((prevState) => ({ ...prevState, date: false }))
    };

    const formatState = () => {

        let formattedState;

        let [{ state_name }] = state_data.filter(({ state_id }) => state_id == stateId)
        let splitState = state_name.split(' ')

        if (splitState.length === 1) {
            formattedState = splitState.join('')
        } else {
            formattedState = splitState.map((word) => word[0]).join('');
        }

        // 2023_VIC_Cleaning_Audit_Report_Tracking

        setFileName(() => `${moment(year).format('yyyy')}_${formattedState}_Cleaning_Audit_Report_Tracking.xlsx`)

    }

    const exportToExcel = () => {

        const wb = XLSX.utils.table_to_book(tableRef.current);

        XLSX.writeFile(wb, fileName);

    }

    const searchRecords = () => {

        if (!stateId) {
            setError((prevState) => ({ ...prevState, state: true }))
            return
        }

        if (!year) {
            setError((prevState) => ({ ...prevState, date: true }))
            return
        }

        setLoading(true);


        let body = {
            year: parseInt(moment(year).format('yyyy')),
            state_id: parseInt(stateId)
        }


        dispatch(getAuditTrackingReportAction(body)).then(() => { }).finally(() => {
            setLoading(false);
            formatState()
        })
    };

    const ViewHeader = () => (
        <div className="view-header">
            <header className="title text-white">
                <h1
                    style={{ color: "white" }}
                    className="h4 text-uppercase"
                >
                    Audit Report Tracking
                </h1>
                <p className="mb-0">
                    View and export audit tracking report.
                </p>
            </header>
        </div>
    );

    const FilterBox = () => {
        return (
            <div className="report_month_picker">
                <div className="row">
                    {/* Month */}
                    <div className="col-12 col-md-4">
                        <div className="rr_year_picker">
                            <FormGroup>
                                <Label>Month</Label>
                                <DatePicker
                                    className="form-control"
                                    selected={year}
                                    selectsStart
                                    withPortal
                                    value={year}
                                    maxDate={new Date()}
                                    dateFormat="yyyy"
                                    onChange={onDateChange}
                                    showYearPicker
                                />
                                <span className="error-text">
                                    {error.date ? "Please select a date" : null}
                                </span>
                            </FormGroup>
                        </div>
                    </div>

                    {/* State */}
                    <div className="col-12 col-md-4">
                        <FormGroup>
                            <Label>State</Label>
                            <StateListClass
                                ref={stateRef}
                                stateValue={stateId}
                                drpChangeState={onStateChange}
                                stateFillData={state_data}
                            />
                            <span className="error-text">
                                {error.state ? "Please select a state" : null}
                            </span>
                        </FormGroup>
                    </div>

                    {/* Submit BTN */}
                    <div className="col-12 col-md-4">
                        <button
                            className="btn btn-warning search_btn"
                            onClick={searchRecords}
                        >
                            {loading ?
                                <div className="spinner-border spinner-border-sm text-light" role="status" />
                                :
                                'Search'
                            }
                        </button>
                    </div>

                </div>
            </div>
        )
    };

    return (
        <div className="view">
            <ViewHeader />
            <div className="view-content view-components">
                <Card className="mb-4 comp-minHeight">
                    <CardBlock className="table-responsive">
                        <h6 className="mb-4 text-uppercase">Search</h6>
                        <FilterBox />
                    </CardBlock>
                </Card>

                {(Array.isArray(audit_tracking_reports) && audit_tracking_reports.length > 0) && (
                    <Card className="mb-4 col-md-12">
                        <CardBody className="table-responsive">
                            <div>
                                <div className="d-flex justify-content-between align-items-center mb-4 w-100" >
                                    <h6 className="text-uppercase"> Audit Tracking Report </h6>
                                    <button className="btn btn-primary" onClick={() => exportToExcel()} >Export</button>
                                </div>
                                <div className="table_container">
                                    <table className="table" ref={tableRef}>
                                        <tbody>
                                            <tr className="text-center" >
                                                <th rowSpan={2} className="text-center  align-middle border">Facility Name</th>
                                                {Array(totalMonths).fill().map((_, index) => {
                                                    return (
                                                        <th key={index} className="border-right" colSpan={2}>{initialMonths[index]}</th>
                                                    )
                                                })}
                                            </tr>
                                            <tr className="text-center" >
                                                {Array(totalMonths).fill(['A1', 'S1']).map(([a1, s1], index) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            <th className="border">{a1}</th>
                                                            <th className="border">{s1}</th>
                                                        </Fragment>
                                                    )
                                                })}
                                            </tr>
                                            {audit_tracking_reports.map(({ facility_name, facility_id, audit_status }, index) => {
                                                return (
                                                    <tr key={facility_id}>
                                                        <td className="border" >{facility_name}</td>
                                                        {Object.values(audit_status).map(([manager, staff], index) => {
                                                            return (
                                                                <Fragment key={index}>
                                                                    <td className={`text-center border-right ${manager ? "color_green" : "color_red"}`}>{manager ? "Done" : "-"}</td>
                                                                    <td className={`text-center border-right ${staff ? "color_green" : "color_red"}`}>{staff ? "Done" : "-"}</td>
                                                                </Fragment>
                                                            )
                                                        })}
                                                    </tr>

                                                )
                                            })}
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                )}

            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const { postsBySubreddit } = state;

    const {
        state_data,
        audit_tracking_reports
    } = postsBySubreddit;

    return {
        state_data,
        audit_tracking_reports
    };
}

const TrackingReportContainer = connect(mapStateToProps)(TrackingReport)

export default TrackingReportContainer;