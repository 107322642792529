import React, { Component } from "react";
import { Card, CardBlock, Label, FormGroup } from "reactstrap";
import { Container } from "reactstrap";
import { connect } from "react-redux";

import LaddaButton, { S, EXPAND_RIGHT } from "react-ladda";
//import {Table, Th, Thead} from 'reactable';
// import IconDot from 'react-icons/lib/md/fiber-manual-record';
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import StateListClass from "../../../shared/components/state_list";
import FacilityListClass from "../../../shared/components/facility_list";
import FloorListClass from "../../../shared/components/floor_list";
import AreaListClass from "../../../shared/components/area_list";
import ReportListClass from "../../../shared/components/report_list";
import { browserHistory } from "react-router";
import FTable from "./table";
import AlertMessageClass from "../../../shared/components/alert_message";
import LoaderClass from "../../../shared/components/loader";

import {
  fetchStateList,
  fetchFacilityList,
  AuditReportList,
} from "../../actions/actions";

import "./style.css";
import { getRequest } from "../../../utils/apiUtils";

var axios = require("axios");
var simpleReactValidator = require("simple-react-validator");

class AuditReport extends Component {
  constructor(props) {
    super(props);
    this.validator = new simpleReactValidator();
    this.state = {
      report_list: [],
      state_data: [],
      facility_data: [],
      floor_data: [],
      area_data_by_floor: [],
      drpStateValue: "",
      drpFacilityValue: "",
      drpReportValue: "",
      fromValue: "",
      toValue: "",
      fromDate: new Date(),
      toDate: new Date(),
      loading: false,
      alertMessageText: "",
      alertMessageVisible: false,
      alertMessageColor: "",
      date: new Date(),
      loaderStatus: true,
      showDefaultTable: false,
      showReportTable: true,
      db_state: "",
      db_facility: "",
      myData: [],
    };

    this.drpClickState = this.drpClickState.bind(this);

    this.drpClickFacility = this.drpClickFacility.bind(this);

    this.fromDateChange = this.fromDateChange.bind(this);

    this.toDateChange = this.toDateChange.bind(this);

    this.onDismiss = this.onDismiss.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.audit_report_detail != prevProps.audit_report_detail) {
      this.setState({
        loading: false,
        loaderStatus: true,
      });
    }
  }

  componentDidMount() {
    var id = sessionStorage.getItem("role_id");
    const { dispatch } = this.props;
    dispatch(fetchStateList(id));

    var that = this;
    getRequest({ url: "api/get_user_data/"})
      .then(function (response) {
        if (response.data != "") {
          if (
            response.data.states_facilities.length > 0 &&
            response.data.states_facilities[0]["facilities"].length > 0
          ) {
            var db_state_id = response.data.states_facilities[0]["state_id"];
            var db_facility_id =
              response.data.states_facilities[0]["facilities"][0][
                "facility_id"
              ];
            dispatch(fetchFacilityList(db_state_id));
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 403) {
          } else {
            console.log(error);
          }
        } else {
          console.log(error);
        }
      });
  }

  drpClickState = (e) => {
    var state_id = e.target.value;
    this.setState({
      drpStateValue: state_id,
      drpFacilityValue: "",
    });

    if (state_id === "") {
      this.setState({
        alertMessageVisible: false,
        data: [],
      });
    } else {
      if (state_id > 0) {
        this.props.dispatch(fetchFacilityList(state_id));
      }
    }
  };

  drpClickFacility = (e) => {
    var facility_id = e.target.value;
    this.setState({
      drpFacilityValue: facility_id,
    });

    if (facility_id === "") {
      this.setState({
        alertMessageVisible: false,
        data: [],
      });
    } else {
    }
  };

  onDismiss() {
    var that = this;
    that.setState({
      alertMessageVisible: false,
    });
  }
  toggleFacilitySetupModal = (e) => {
    this.setState({
      floorFlag: true,
      areaFlag: true,
      roomFlag: false,
      showFacilitySetupTable: true,
      undefined_msg: false,
    });
  };

  btnSearch = (e) => {
    var that = this;
    var state_id = that.state.drpStateValue;
    var facility_id = that.state.drpFacilityValue;
    var getdate = that.state.toDate;
    var to_date = moment(getdate).format("YYYY-MM-DD");
    var getdateForm = that.state.fromDate;
    var from_date = moment(getdateForm).format("YYYY-MM-DD");

    if (state_id !== "" && facility_id !== "") {
      this.setState({
        loading: !this.state.loading,
        progress: 0.5,
        loaderStatus: false,
      });

      var end_date = to_date;
      var start_date = from_date;
      const post = {
        facility_id,
        start_date,
        end_date,
      };

      this.props.dispatch(AuditReportList(post));
      this.setState({
        showReportTable: false,
        showBtn: false,
        fac_err: true,
      });
    } else {
      this.setState({
        showReportTable: true,
        showBtn: true,
        fac_err: false,
        showDefaultTable: false,
        floorFlag: true,
        areaFlag: true,
        roomFlag: true,
      });
      that.validator.showMessages();
    }
    that.setState({
      showDefaultTable: true,
      alertMessageVisible: false,
    });
  };

  fromDateChange(date) {
    this.setState({ fromDate: date });
  }
  toDateChange(date) {
    this.setState({ toDate: date });
  }

  render() {
    const { state_data, facility_data, audit_report_detail } = this.props;

    const ViewHeader = () => (
      <div className="view-header">
        <header className="title text-white">
          <h1 style={{ color: "white" }} className="h4 text-uppercase">
            Audit Report
          </h1>
          <p className="mb-0">View audit reports here.</p>
        </header>
      </div>
    );

    const ViewContent = ({ children }) => (
      <div className="view-content view-components">{children}</div>
    );

    const AlertMessage = () => (
      <AlertMessageClass
        alertMessageColor={this.state.alertMessageColor}
        alertMessageVisible={this.state.alertMessageVisible}
        onDismiss={this.onDismiss}
        alertMessageText={this.state.alertMessageText}
      />
    );

    const FilterBox = ({ children }) => (
      <div className="row">
        <div className="col-12 col-md-6">
          <FormGroup>
            <Label>From</Label>
            <DatePicker
              className="form-control"
              selected={this.state.fromDate}
              selectsStart
              withPortal
              maxDate={this.state.toDate}
              dateFormat="dd-MM-yyyy"
              startDate={this.state.fromDate}
              endDate={this.state.toDate}
              onChange={this.fromDateChange}
            />
            <span className="error-text">
              {this.validator.message(
                "from date",
                this.state.fromDate,
                "required"
              )}
            </span>
          </FormGroup>
          <FormGroup>
            <Label>State</Label>
            <StateListClass
              stateValue={this.state.drpStateValue}
              drpChangeState={this.drpClickState}
              stateFillData={state_data}
            />
            <span className="error-text">
              {this.validator.message(
                "state",
                this.state.drpStateValue,
                "required"
              )}
            </span>
          </FormGroup>
        </div>
        <div className="col-12 col-md-6">
          <FormGroup>
            <Label>To</Label>
            <DatePicker
              className="form-control"
              selected={this.state.toDate}
              selectsEnd
              withPortal
              minDate={this.state.fromDate}
              dateFormat="dd-MM-yyyy"
              startDate={this.state.fromDate}
              endDate={this.state.toDate}
              onChange={this.toDateChange}
            />
            <span className="error-text">
              {this.validator.message("to date", this.state.toDate, "required")}
            </span>
          </FormGroup>

          <FormGroup>
            <Label>Facility Name</Label>
            <FacilityListClass
              facilityValue={this.state.drpFacilityValue}
              drpChangeFac={this.drpClickFacility}
              facilityFillData={facility_data}
            />
            <span className="error-text">
              {this.validator.message(
                "facility",
                this.state.drpFacilityValue,
                "required"
              )}
            </span>
          </FormGroup>

          <FormGroup>
            <LaddaButton
              className="btn btn-warning btn-search search_btn"
              style={{ float: "right" }}
              loading={this.state.loading}
              onClick={this.btnSearch}
              data-color="#eee"
              data-size={S}
              data-style={EXPAND_RIGHT}
              data-spinner-size={30}
              data-spinner-color="#ddd"
              data-spinner-lines={12}
            >
              Search
            </LaddaButton>
          </FormGroup>
        </div>
      </div>
    );

    const report_var = "report" + this.state.drpReportValue + "_data";
    const FloorTable = () => (
      <FTable
        facilityFillData={audit_report_detail}
        toggleModal={this.toggleFacilitySetupModal}
        fac_val={this.state.drpFacilityValue}
        fac_name={this.state.drpFacName}
      />
    );
    const DefaultTable = () => (
      <table className="table">
        <thead>
          <tr>
            <th>Facility</th>
            <th>Month</th>
            <th>Year</th>
            <th>Report</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    );

    return (
      <div className="view">
        <ViewHeader />
        <ViewContent>
          <Card className="mb-4 comp-minHeight">
            <CardBlock className="table-responsive">
              <h6 className="mb-4 text-uppercase">Search</h6>
              <FilterBox />
            </CardBlock>
          </Card>

          <Card className="mb-4" hidden={this.state.showDefaultTable}>
            <CardBlock className="table-responsive">
              <h6 className="mb-4 text-uppercase">Audit Report History</h6>
              <DefaultTable />
            </CardBlock>
          </Card>

          <Card className="mb-4 col-md-12" hidden={this.state.showReportTable}>
            <CardBlock className="table-responsive">
              <AlertMessage />{" "}
              <AlertMessageClass
                alertMessageColor="danger"
                alertMessageVisible={this.state.undefined_msg}
                onDismiss={this.onDismiss}
                alertMessageText="Something went wrong. Please try again."
              />
              <h6 className="mb-4 text-uppercase"> Audit Report History </h6>
              <LoaderClass visibleStatus={this.state.loaderStatus} />
              <div>
                <FloorTable />
              </div>
            </CardBlock>
          </Card>
        </ViewContent>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { postsBySubreddit } = state;
  const {
    state_data: state_data,
    facility_data: facility_data,
    audit_report_detail: audit_report_detail,
  } = postsBySubreddit;

  return {
    state_data,
    facility_data,
    audit_report_detail,
  };
}

const AppContainer = connect(mapStateToProps)(AuditReport);

export default AppContainer;
