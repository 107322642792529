import { deleteRequest, getRequest, postRequest, putRequest } from "../../utils/apiUtils";

/** @alias refer OBS as Observation */

// Reducer variables
export const STATE_WISE_OBS_QUESTIONS = "STATE_WISE_OBS_QUESTIONS";
export const LOADING_OBSERVATION = "LOADING_OBSERVATION";

export const setObservationLoadingState = (payload = false) => {
    return (dispatch) => {
        dispatch({ type: LOADING_OBSERVATION, loading: payload });
    };
};

const setOBSQuestionsToReducer = (payload) => {
    return {
        type: STATE_WISE_OBS_QUESTIONS,
        obsQuestions: payload,
    };
};

export const fetchStateWiseOBSQuestions = (stateId) => {
    return async (dispatch) => {
        return await getRequest({
            url: `api/managed_observation_ques?state_id=${stateId}`,
        })
            .then(({ data: { data, error }, status }) => {
                if (!error && status === 200) {
                    return dispatch(setOBSQuestionsToReducer(data.result));
                } else {
                    return dispatch(setOBSQuestionsToReducer([]));
                }
            })
            .catch((error) => {
                console.log("error", error);
                return dispatch(setOBSQuestionsToReducer([]));
            });
    };
};

export const deleteOBSQuestions = (questionId) => {
    return async (dispatch) => {
        return await deleteRequest({
            url: `api/delete_observation_questions/${questionId}`,
        })
            .then(({ data: { data, error }, status }) => {
                if (!error && status === 200) {
                    return { success: true };
                } else {
                    return { success: false };
                }
            })
            .catch((error) => {
                return { success: false };
            });
    };
};

export const addOBSQuestions = (body) => {
    return async (dispatch) => {
        return await postRequest({
            url: `api/add_observation_question`,
            payload: body,
        })
            .then(({ data: { data, error }, status }) => {
                if (!error && status === 200) {
                    return { success: true };
                } else {
                    return { success: false };
                }
            })
            .catch((error) => {
                return { success: false };
            });
    };
};

export const updateOBSQuestions = (body) => {
    return async (dispatch) => {
        return await putRequest({
            url: `api/edit_observation_question`,
            payload: body,
        })
            .then(({ data: { data, error }, status }) => {
                if (!error && status === 200) {
                    return { success: true };
                } else {
                    return { success: false };
                }
            })
            .catch((error) => {
                return { success: false };
            });
    };
};
