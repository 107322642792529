import React, { Component } from "react";
import { connect } from "react-redux";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { CardGroup, Card, CardBlock, CardTitle, Row } from "reactstrap";
import IconDot from "react-icons/lib/md/fiber-manual-record";

import {
  fetchLatestFiveAudits,
  getAuditCount,
  getAuditGraphData,
} from "../../actions/actions";

var dateFormat = require("dateformat");

class ViewContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latest_five_audit_data: [],
      get_audit_count: [],
      GraphData: [],
    };
  }
  componentDidMount() {
    var that = this;
    const { dispatch } = this.props;
    dispatch(fetchLatestFiveAudits());
    dispatch(getAuditCount());
    dispatch(getAuditGraphData());
  }

  render() {
    const {
      dispatch,
      latest_five_audit_data,
      get_audit_count,
      fetch_audit_graph_data,
    } = this.props;
    const NewGraphData = fetch_audit_graph_data;
    function search(nameKey, myArray) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].id === nameKey) {
          return myArray[i].name;
        }
      }
    }
    const users = [
      { id: 1, name: "Jan" },
      { id: 2, name: "Feb" },
      { id: 3, name: "Mar" },
      { id: 4, name: "Apr" },
      { id: 5, name: "May" },
      { id: 6, name: "Jun" },
      { id: 7, name: "Jul" },
      { id: 8, name: "Aug" },
      { id: 9, name: "Sep" },
      { id: 10, name: "Oct" },
      { id: 11, name: "Nov" },
      { id: 12, name: "Dec" },
    ];

    for (var j = 0; j < NewGraphData.length; j++) {
      var resultObject = search(NewGraphData[j]["month"], users);
      NewGraphData[j]["month"] = resultObject;
    }

    const AUditDataChart = () => (
      <ResponsiveContainer>
        <AreaChart
          data={fetch_audit_graph_data}
          margin={{ top: 10, right: 10, left: -15, bottom: 0 }}
        >
          <XAxis
            dataKey="month"
            axisLine={false}
            fontSize={10}
            tickLine={false}
            padding={{ left: 0, right: 5 }}
          />
          <YAxis fontSize={10} axisLine={false} tickLine={false} />
          <CartesianGrid stroke="#eee" vertical={false} />
          <Tooltip wrapperStyle={{ borderColor: "#eee" }} />
          <Legend />
          <Area
            type="monotone"
            dataKey="audit completed"
            stackId="1"
            strokeWidth={2}
            stroke="#448AFF"
            fill="#448AFF"
            fillOpacity=".8"
          />
          <Area
            type="monotone"
            dataKey="rectification completed"
            stackId="1"
            strokeWidth={2}
            stroke="#69F0AE"
            fill="#69F0AE"
            fillOpacity=".8"
          />
        </AreaChart>
      </ResponsiveContainer>
    );

    var size = 5;
    const LatestAuditTable = () => (
      <table className="table">
        <thead></thead>
        <tbody>
          {latest_five_audit_data != "" && latest_five_audit_data != null
            ? latest_five_audit_data.slice(0, size).map((item, i) => (
                <tr key={i}>
                  <td className="d-flex flex-column">
                    <strong>{item.auditor_name}</strong>
                    <small>{dateFormat(item.audit_date, "mediumDate")}</small>
                  </td>
                  <td className="align-middle">
                    Audit Score {item.audit_score}
                  </td>
                  <td className="align-middle text-nowrap">
                    {/*<IconDot size="12"  color={item.audit_status === 1|2 ? '#4CAF50' :'#F44336'}/> &nbsp;
                            {item.audit_status===1?'audit completed': 
                                <span>{item.audit_status===2?'rectification completed':'audit pending'}</span>
                            }*/}
                    <IconDot
                      size="12"
                      color={
                        (item.audit_status === 0) | 1 | 2
                          ? "#4CAF50"
                          : "#F44336"
                      }
                    />{" "}
                    &nbsp;
                    {item.audit_status === 0 ? (
                      "Audit completed"
                    ) : (
                      <span>
                        {item.audit_status === 1
                          ? "Rectification completed"
                          : "Rectification notified"}
                      </span>
                    )}
                  </td>
                </tr>
              ))
            : ""}
        </tbody>
      </table>
    );

    const RecentActivitesTable = () => (
      <div>
        {latest_five_audit_data != "" && latest_five_audit_data != null
          ? latest_five_audit_data.slice(0, size).map((item, i) => (
              <div className="d-flex mb-4 align-items-center" key={i}>
                <div>
                  <h6 className="font-weight-semi-bold">
                    {item.auditor_name} &nbsp;
                    {/*{item.audit_status===1?'completed a audit': 
                        <span>{item.audit_status===2?'completed rectification':'started audit'}</span>
                    }*/}
                    {item.audit_status === 0 ? (
                      "completed a audit"
                    ) : (
                      <span>
                        {item.audit_status === 1
                          ? "completed rectification"
                          : "notified rectification"}
                      </span>
                    )}
                  </h6>
                  <div className="small">
                    {" "}
                    {dateFormat(item.audit_date, "mediumDate")}
                  </div>
                </div>
              </div>
            ))
          : ""}
      </div>
    );

    const AuditCard = () => (
      <CardGroup className="sales-card mb-4">
        <Card style={{ flex: "3 0 0" }}>
          <CardBlock>
            <CardTitle className="text-uppercase h6">
              Audit Statistics
            </CardTitle>
            <div className="small mb-4 card-subtitle">Growth over time</div>
            <div style={{ width: "100%", height: "280px" }}>
              <AUditDataChart />
            </div>
          </CardBlock>
        </Card>
        <Card>
          <CardBlock>
            <h6 className="text-uppercase title font-weight-bold small">
              Completed Audit
            </h6>
            <h4 className="font-weight-normal mb-0">
              {get_audit_count != "" && get_audit_count != null
                ? get_audit_count[0]["audit_complited"]
                : ""}
            </h4>
          </CardBlock>
          <CardBlock>
            <h6 className="text-uppercase title font-weight-bold small">
              Completed Rectification
            </h6>
            <h4 className="font-weight-normal mb-0">
              {get_audit_count != "" && get_audit_count != null
                ? get_audit_count[0]["rectification_complited"]
                : ""}
            </h4>
          </CardBlock>
          <CardBlock>
            <h6 className="text-uppercase title font-weight-bold small text-nowrap">
              Rectification Notified
            </h6>
            <h4 className="font-weight-normal mb-0">
              {get_audit_count != "" && get_audit_count != null
                ? get_audit_count[0]["rectification_notify"]
                : ""}
            </h4>
          </CardBlock>
        </Card>
      </CardGroup>
    );

    return (
      <div className="view-content view-dashboard">
        <AuditCard />
        <div></div>
        <Row>
          <div className="col-md-5 mb-4">
            <Card>
              <CardBlock>
                <CardTitle className="h6 text-uppercase">
                  Recent Activities
                </CardTitle>
                <RecentActivitesTable />
                {/* <div className="text-right">
                                <button className="btn btn-sm btn-success">View All</button>
                                </div> */}
                <br />
              </CardBlock>
            </Card>
          </div>
          <div className="col-md-7 mb-4">
            <Card>
              <CardBlock className="table-responsive">
                <CardTitle className="text-uppercase h6">
                  Latest Audits
                </CardTitle>
                {/* <div className="small mb-4 card-subtitle">
                                <Button color="primary" size="sm">View all</Button> 
                                </div> */}
                <LatestAuditTable />
              </CardBlock>
            </Card>
          </div>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { postsBySubreddit } = state;
  const {
    latest_five_audit_data: latest_five_audit_data,
    get_audit_count: get_audit_count,
    fetch_audit_graph_data: fetch_audit_graph_data,
  } = postsBySubreddit;
  return {
    latest_five_audit_data,
    get_audit_count,
    fetch_audit_graph_data,
  };
}

const AppContainer = connect(mapStateToProps)(ViewContent);

export default AppContainer;
