import React from "react";

export default () => (
  <div className="view-header d-flex align-items-center">
    <header className="text-white">
      <h1 style={{ color: "white" }} className="h5 title text-uppercase">
        Dashboard
      </h1>
      <p className="mb-0 subtitle text-nowrap">
        Latest statistics&nbsp;
        <span className="hidden-sm-down">&amp; summary</span>
      </p>
    </header>
  </div>
);
