import React, { Component } from 'react'
import LoadingImage from '../../../assets/images/Eclipse.gif'

class Loader extends Component {
    render () {
        var loderStyle={textAlign:'center'}
        return (
            <div style={loderStyle} hidden={this.props.visibleStatus}>
            <img src={LoadingImage}  alt="loader" />
            </div>
        )
    }
}
Loader.defaultProps=
{

    visibleStatus:true
}

export default Loader


                                   