import React, { Component } from "react";
import { Card, CardBlock, Label, FormGroup } from "reactstrap";
import { Container } from "reactstrap";
import { connect } from "react-redux";

import LaddaButton, { S, EXPAND_RIGHT } from "react-ladda";
//import {Table, Th, Thead} from 'reactable';
// import IconDot from 'react-icons/lib/md/fiber-manual-record';
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import './react-date-picker.css'
import ReportTableClass from "./report_table";
import StateListClass from "../../../shared/components/state_list";
import FacilityListClass from "../../../shared/components/facility_list";
import FloorListClass from "../../../shared/components/floor_list";
import AreaListClass from "../../../shared/components/area_list";
import ReportListClass from "../../../shared/components/report_list";
import { browserHistory } from "react-router";

import AlertMessageClass from "../../../shared/components/alert_message";
import LoaderClass from "../../../shared/components/loader";

import {
    fetchStateList,
    fetchFacilityList,
    fetchFloorList,
    fetchAreaListByFloor,
    fetchReport1,
    fetchReport2,
    fetchReport3,
    fetchReport4,
    fetchReport5,
    fetchReport6,
    fetchReport7,
    fetchReport8,
    fetchReportTypeAction,
    getCleaningAuditReportAction,
} from "../../actions/actions";

import "./style.css";
import { getReportData } from "../../utilities";
import { getRequest } from "../../../utils/apiUtils";

var axios = require("axios");
var simpleReactValidator = require("simple-react-validator");

class Reports extends Component {
    constructor(props) {
        super(props);
        this.validator = new simpleReactValidator();
        this.state = {
            report_list: [],
            state_data: [],
            facility_data: [],
            floor_data: [],
            area_data_by_floor: [],
            drpStateValue: "",
            drpFacilityValue: "",
            drpReportValue: "",
            fromValue: "",
            toValue: "",
            fromDate: new Date(),
            toDate: moment(),
            loading: false,
            alertMessageText: "",
            alertMessageVisible: false,
            alertMessageColor: "",
            date: new Date(),
            loaderStatus: true,
            showDefaultTable: false,
            showReportTable: true,
            db_state: "",
            db_facility: "",
            cleaning_audit_reports: [],
        };

        this.fromDateRef = React.createRef();
        this.toDateRef = React.createRef();
        this.stateRef = React.createRef();
        this.facilityRef = React.createRef();
        this.reportTypeRef = React.createRef();

        this.drpClickState = this.drpClickState.bind(this);

        this.drpClickFacility = this.drpClickFacility.bind(this);

        this.drpClickReport = this.drpClickReport.bind(this);

        this.fromDateChange = this.fromDateChange.bind(this);

        this.toDateChange = this.toDateChange.bind(this);

        this.onDismiss = this.onDismiss.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.report1_data != prevProps.report1_data ||
            this.props.report2_data != prevProps.report2_data ||
            this.props.report3_data != prevProps.report3_data ||
            this.props.report4_data != prevProps.report4_data ||
            this.props.report5_data != prevProps.report5_data ||
            this.props.report6_data != prevProps.report6_data ||
            this.props.report7_data != prevProps.report7_data ||
            this.props.report8_data != prevProps.report8_data
        ) {
            this.setState({
                loading: false,
                loaderStatus: true,
                showReportTable: false,
            });
        }

        if (
            this.props.cleaning_audit_reports !==
            prevProps.cleaning_audit_reports
        ) {
            this.setState({
                loading: false,
                loaderStatus: true,
                showReportTable: false,
                cleaning_audit_reports: this.props.cleaning_audit_reports,
            });
        }
    }

    componentDidMount() {
        var id = sessionStorage.getItem("role_id");
        console.log(id);
        const { dispatch } = this.props;
        dispatch(fetchStateList(id));
        dispatch(fetchReportTypeAction());

        var that = this;
        getRequest({ url: "api/get_user_data/"})
            .then(function (response) {
                if (response.data != "") {
                    if (
                        response.data.states_facilities.length > 0 &&
                        response.data.states_facilities[0]["facilities"]
                            .length > 0
                    ) {
                        var db_state_id =
                            response.data.states_facilities[0]["state_id"];
                        var db_facility_id =
                            response.data.states_facilities[0]["facilities"][0][
                                "facility_id"
                            ];
                        dispatch(fetchFacilityList(db_state_id));
                        that.setState({
                            drpStateValue: db_state_id,
                            drpFacilityValue: db_facility_id,
                        });
                    }
                }
                //console.log(response.data.states_facilities);
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403) {
                    } else {
                        console.log(error);
                    }
                } else {
                    console.log(error);
                }
            });
    }

    drpClickState = (e) => {
        var state_id = e.target.value;
        this.setState({
            drpStateValue: state_id,
            drpFacilityValue: "",
        });

        if (state_id === "") {
            this.setState({
                alertMessageVisible: false,
                data: [],
            });
        } else {
            if (state_id > 0) {
                //console.log("state_"+state_id);
                this.props.dispatch(fetchFacilityList(state_id));
            }
        }
    };

    drpClickFacility = (e) => {
        var facility_id = e.target.value;
        this.setState({
            drpFacilityValue: facility_id,
        });

        if (facility_id === "") {
            this.setState({
                alertMessageVisible: false,
                data: [],
            });
        } else {
        }
    };

    drpClickReport = (e) => {
        var report_id = e.target.value;
        this.setState({
            drpReportValue: report_id,
        });

        if (report_id === "") {
            this.setState({
                alertMessageVisible: false,
                data: [],
            });
        } else {
            this.setState({
                showReportTable: true,
                showDefaultTable: false,
            });
        }
    };

    onDismiss() {
        var that = this;
        that.setState({
            alertMessageVisible: false,
        });
    }

    btnSearch = (e) => {
        var that = this;
        if (that.validator.allValid()) {
        } else {
            that.validator.showMessages();
            // rerender to show messages for the first time
            that.forceUpdate();
            return;
        }
        that.setState({
            loading: true,
        });
        let body = {
            date: this.fromDateRef.current.input.value,
            facility_id: this.facilityRef.current.props.value,
            report_type: +this.reportTypeRef.current.props.value,
        };

        this.props.dispatch(getCleaningAuditReportAction(body)).then(() => {
            that.setState({
                loading: false,
            });
        });
    };

    fromDateChange(date) {
        this.setState({ fromDate: date });
    }
    toDateChange(date) {
        //console.log(date);
        this.setState({ toDate: date });
    }

    render() {
        const {
            cleaning_audit_reports,
            dispatch,
            state_data,
            facility_data,
            report1_data,
            report2_data,
            report3_data,
            report4_data,
            report5_data,
            report6_data,
            report7_data,
            report8_data,
        } = this.props;

        const ViewHeader = () => (
            <div className="view-header">
                <header className="title text-white">
                    <h1
                        style={{ color: "white" }}
                        className="h4 text-uppercase"
                    >
                        Reports
                    </h1>
                    <p className="mb-0">
                        View and export audit and rectification reports here.
                    </p>
                </header>
            </div>
        );

        const ViewContent = ({ children }) => (
            <div className="view-content view-components">{children}</div>
        );

        const AlertMessage = () => (
            <AlertMessageClass
                alertMessageColor={this.state.alertMessageColor}
                alertMessageVisible={this.state.alertMessageVisible}
                onDismiss={this.onDismiss}
                alertMessageText={this.state.alertMessageText}
            />
        );

        //console.log(TestData);
        const FilterBox = ({ children }) => {
            return (
                <div className="report_month_picker">
                    <div className="row">
                        {/* Month */}
                        <div className="col-12 col-md-6">
                            <FormGroup>
                                <Label>Month</Label>
                                <DatePicker
                                    ref={this.fromDateRef}
                                    className="form-control"
                                    selected={this.state.fromDate}
                                    selectsStart
                                    withPortal
                                    value={this.state.fromDate}
                                    dateFormat="MM-yyyy"
                                    onChange={this.fromDateChange}
                                    showMonthYearPicker
                                />
                                <span className="error-text">
                                    {this.validator.message(
                                        "from date",
                                        this.state.fromDate,
                                        "required"
                                    )}
                                </span>
                            </FormGroup>
                        </div>

                        {/* State */}
                        <div className="col-12 col-md-6">
                            <FormGroup>
                                <Label>State</Label>
                                <StateListClass
                                    ref={this.stateRef}
                                    stateValue={this.state.drpStateValue}
                                    drpChangeState={this.drpClickState}
                                    stateFillData={state_data}
                                />
                                <span className="error-text">
                                    {this.validator.message(
                                        "state",
                                        this.state.drpStateValue,
                                        "required"
                                    )}
                                </span>
                            </FormGroup>
                        </div>

                        {/* Facility Name */}
                        <div className="col-12 col-md-6">
                            <FormGroup>
                                <Label>Facility Name</Label>
                                <FacilityListClass
                                    refs={this.facilityRef}
                                    facilityValue={this.state.drpFacilityValue}
                                    drpChangeFac={this.drpClickFacility}
                                    facilityFillData={facility_data}
                                />
                                <span className="error-text">
                                    {this.validator.message(
                                        "facility",
                                        this.state.drpFacilityValue,
                                        "required"
                                    )}
                                </span>
                            </FormGroup>
                        </div>
                        
                        {/* Report Type */}
                        <div className="col-12 col-md-4">
                            <FormGroup>
                                <Label>Select Report Type</Label>
                                <ReportListClass
                                    refs={this.reportTypeRef}
                                    reportValue={this.state.drpReportValue}
                                    drpChangeReport={this.drpClickReport}
                                />
                                <span className="error-text">
                                    {this.validator.message(
                                        "report type",
                                        this.state.drpReportValue,
                                        "required"
                                    )}
                                </span>
                            </FormGroup>
                        </div>

                        {/* Submit BTN */}
                        <div className="col-12 col-md-2">
                            <FormGroup>
                                <LaddaButton
                                    className="btn btn-warning btn-search search_btn justify-content-end"
                                    loading={this.state.loading}
                                    onClick={this.btnSearch}
                                    data-color="#eee"
                                    data-size={S}
                                    data-style={EXPAND_RIGHT}
                                    data-spinner-size={30}
                                    data-spinner-color="#ddd"
                                    data-spinner-lines={12}
                                >
                                    Search
                                </LaddaButton>
                            </FormGroup>
                        </div>                    
                        
                    </div>
                </div>
            )
        };

        const report_var = "report" + this.state.drpReportValue + "_data";

        const ReportTable1 = () => (
            <ReportTableClass
                reportId={this.state.drpReportValue}
                reportFillData={this.props.report1_data}
            />
        );
        const ReportTable2 = () => (
            <ReportTableClass
                reportId={this.state.drpReportValue}
                reportFillData={this.props.cleaning_audit_reports}
            />
        );
        const ReportTable3 = () => (
            <ReportTableClass
                reportId={this.state.drpReportValue}
                reportFillData={this.props.report3_data}
            />
        );
        const ReportTable4 = () => (
            <ReportTableClass
                reportId={this.state.drpReportValue}
                reportFillData={this.props.report4_data}
            />
        );
        const ReportTable5 = () => (
            <ReportTableClass
                reportId={this.state.drpReportValue}
                reportFillData={this.props.report5_data}
            />
        );
        const ReportTable6 = () => (
            <ReportTableClass
                reportId={this.state.drpReportValue}
                reportFillData={this.props.report6_data}
            />
        );
        const ReportTable7 = () => (
            <ReportTableClass
                reportId={this.state.drpReportValue}
                reportFillData={this.props.report7_data}
            />
        );
        const ReportTable8 = () => (
            <ReportTableClass
                reportId={this.state.drpReportValue}
                reportFillData={this.props.report8_data}
            />
        );

        const DefaultTable = () => (
            <table className="table">
                <thead>
                    <tr>
                        <th>Facility Name</th>
                        <th>Report Name</th>
                        <th>Report Date</th>
                        <th>PDF Reports</th>
                    </tr>
                </thead>
                <tbody>
                    {cleaning_audit_reports.map(({ name, pdf_url, report_date, facility_name }, index) => {
                        return (
                            <tr key={index}>
                                <td scope="row">
                                    <p>{facility_name}</p>
                                </td>
                                <td scope="row">
                                    <p>{name}</p>
                                </td>
                                <td scope="row">
                                    <p>{report_date}</p>
                                </td>
                                <td>
                                    <div
                                        onClick={() => getReportData(pdf_url)}
                                        className="btn btn-sm btn-primary marginRight_10"
                                        // href={this.props.reportFillData}
                                    >
                                        Download Report
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );

        return (
            <div className="view">
                <ViewHeader />
                <ViewContent>
                    <Card className="mb-4 comp-minHeight">
                        <CardBlock className="table-responsive">
                            <h6 className="mb-4 text-uppercase">Search</h6>
                            <FilterBox />
                        </CardBlock>
                    </Card>

                    <Card className="mb-4" hidden={this.state.showDefaultTable}>
                        <CardBlock className="table-responsive">
                            <h6 className="mb-4 text-uppercase">
                                Audit Report History
                            </h6>
                            <DefaultTable />
                        </CardBlock>
                    </Card>

                    {/* <Card className="mb-4" hidden={this.state.showReportTable}>
                        <CardBlock className="table-responsive">
                            <AlertMessage />
                            <h6 className="mb-4 text-uppercase">
                                Audit Report History
                            </h6>
                            <LoaderClass
                                visibleStatus={this.state.loaderStatus}
                            />
                            <div>
                                {this.state.drpReportValue === "1" &&
                                this.props.report1_data !== "" ? (
                                    <ReportTable1 key="1" />
                                ) : (
                                    [
                                        this.state.drpReportValue === "2" ? (
                                            <ReportTable2 key="2" />
                                        ) : this.state.drpReportValue ===
                                          "3" ? (
                                            <ReportTable3 key="3" />
                                        ) : this.state.drpReportValue ===
                                          "4" ? (
                                            <ReportTable4 key="4" />
                                        ) : this.state.drpReportValue ===
                                          "5" ? (
                                            <ReportTable5 key="5" />
                                        ) : this.state.drpReportValue ===
                                          "6" ? (
                                            <ReportTable6 key="6" />
                                        ) : this.state.drpReportValue === "7" &&
                                          this.props.report7_data !== "" ? (
                                            <ReportTable7 key="7" />
                                        ) : this.state.drpReportValue ===
                                          "8" ? (
                                            <ReportTable8 key="8" />
                                        ) : null,
                                    ]
                                )}
                            </div>
                        </CardBlock>
                    </Card> */}
                </ViewContent>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { postsBySubreddit } = state;
    const {
        cleaning_audit_reports,
        state_data: state_data,
        facility_data: facility_data,
        report1_data: report1_data,
        report2_data: report2_data,
        report3_data: report3_data,
        report4_data: report4_data,
        report5_data: report5_data,
        report6_data: report6_data,
        report7_data: report7_data,
        report8_data: report8_data,
    } = postsBySubreddit;

    return {
        cleaning_audit_reports,
        state_data,
        facility_data,
        report1_data,
        report2_data,
        report3_data,
        report4_data,
        report5_data,
        report6_data,
        report7_data,
        report8_data,
    };
}

const AppContainer = connect(mapStateToProps)(Reports);

export default AppContainer;
