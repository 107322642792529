import React from "react";
import { connect } from "react-redux";
import simpleReactValidatorMin from "simple-react-validator";

class AuditRegister extends React.Component {
    constructor(props) {
        super(props);
        this.validator = new simpleReactValidatorMin(
            (this.rules = {
                custom_text: {
                    message: "The :attribute must be a valid text.",
                    rule: (val) =>
                        !this._testRegex(
                            val,
                            /^[0-9@!#\$\^%&*()+=\-\[\]\\\';,\.\/\{\}\|\":<>\? ]+$/
                        ),
                },
            })
        );

        // State
        this.state = {}
    }

    render() {

        return (
            <p>Audit Register - Under Development</p>
        )
    }
}

function mapStateToProps(state) {
    const { postsBySubreddit } = state;

    return {  }
}

const AuditRegisterContainer = connect(mapStateToProps)(AuditRegister)

export default AuditRegisterContainer;