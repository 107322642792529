import React from "react";
import { Route, IndexRoute, Redirect } from "react-router";
import App from "./App";

import Login from "./shared/containers/login/index";
import Dashboard from "./shared/containers/dashboard";
import FacilitySetup from "./shared/containers/facilitysetup";
import Questions from "./shared/containers/questions";
import QuestionsFac from "./shared/containers/questions_fac";

import Reports from "./shared/containers/reports";
//import Reports from './views/reports';
import Page404 from "./views/pages/404";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { role_name: "client" };
  }
  render() {
    return (
      <Route>
        <Route component={Login} exact path="/" />
        <Route component={App} path="/">
          <IndexRoute component={Dashboard} />
          <Route exact path="dashboard" component={Dashboard} />
          <Route path="facilitysetup" component={FacilitySetup} />
          <Route path="state_questions" component={Questions} />
          <Route path="observation_questions" component={Questions} />
          <Route path="facility_questions" component={QuestionsFac} />
          <Route path="reports" component={Reports} />
        </Route>

        <Route component={Page404} path="404" />
        <Redirect from="*" to="404" />
      </Route>
    );
  }
}
