import React, { Component } from 'react';
import {
    Input,
     
} from 'reactstrap';

class FacilityList extends Component {
    render () {
        return (
            <Input
                type="select"
                name="drpFac"
                id="drpFac"
                ref={this.props.refs}
                value={this.props.facilityValue}
                onChange={this.props.drpChangeFac}
            >
                <option value="">--Select Facility--</option>
                {this.props.facilityFillData.map((item, i) => (
                    <option key={item.facility_id} value={item.facility_id}>
                        {item.facility_name}
                    </option>
                ))}
                {/* <option value="1">Facility1</option>
                    <option value="2">Facility2</option>
                    <option value="3">Facility3</option>
                    <option value="4">Facility4</option>
                    <option value="5">Facility5</option> */}
            </Input>
        );
    }
}
FacilityList.defaultProps=
{

    facilityFillData:[],
    facilityValue:""

}

export default FacilityList