import React, { Component } from 'react'
import {Alert} from 'reactstrap';
 class AlertMessage extends Component {

    // constructor(props) {
    //     super(props);
    // }
     render () {
         return (

            <Alert color = {this.props.alertMessageColor}  
            isOpen = {this.props.alertMessageVisible}
            toggle = { this.props.onDismiss} >
            {this.props.alertMessageText}  
            </Alert>

         )
     }
 }
 AlertMessage.defaultProps=
 {
 
   alertMessageText:"",
   alertMessageVisible:false,
   alertMessageColor:"",

 }
 
 export default AlertMessage;
    
