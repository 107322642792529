import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Collapse, Button, Card } from 'reactstrap';
import {Link} from 'react-router';
import {browserHistory } from 'react-router';
import AlertMessageClass from '../../../shared/components/alert_message';
import LoaderClass from '../../../shared/components/loader';
var axios = require('axios');

class FacilitySetupTable extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            fac_val: this.props.fac_val,
            selected: 0,
            floor_selected: 0,
            area_selected: 0,
            area_data: [],
            room_data: [],
            show_floor: true,
            show_area: true,
            show_room: true,
            alertAreaMessageText: "",
            alertAreaMessageVisible: false,
            alertAreaMessageColor: "",
            loaderAreaStatus:true,
            alertRoomMessageText: "",
            alertRoomMessageVisible: false,
            alertRoomMessageColor: "",
            loaderRoomStatus:true,
        };
        
    }

    handleClick_fac = (index, e) => {
        let c = e.currentTarget.className;
        var that = this;
        that.setState({
            selected: (c.indexOf('selected') >= 0) ? '' : index
        })
        var selected = (c.indexOf('selected') >= 0) ? '' : index;
        //console.log("selected: "+selected);
        if(this.props.floor_data=="")
        {
            that.setState({
                show_floor:false
            })
        }
        else
        {
            that.setState({
                show_floor:true
            })
        }
    }

    handleOpen_fac = (index, e) => {
        this.setState({
            selected: index
        })
    }

    handleClick_floor = (index, e) => {
        var that = this;
        let c = e.currentTarget.className;
        
        this.setState({
            floor_selected: (c.indexOf('floor_selected') >= 0) ? '' : index
        })
        var floor_selected = (c.indexOf('floor_selected') >= 0) ? '' : index;
        //console.log("floor_selected: "+floor_selected);
        //this.props.dispatch(fetchAreaList());
        var area_data= [];  
        if(floor_selected != "")
        {
            that.setState({
                loaderAreaStatus:false
            });
                axios
                .get('api/managed_areas_by_floor/'+index)
                .then(function (response) 
                {
                    console.log(response.data.result);
                    {/*var area_data_res = "";
                    if(response.data.result!="" && response.data.result!=null)
                    {
                        area_data_res = response.data.result;
                    }
                    else{
                        area_data_res = "";
                    }
                    that.setState({
                        area_data: area_data_res
                    });
                    area_data = area_data_res;
                    if(area_data=="")
                    {
                        that.setState({
                            show_area:false
                        })
                    }
                    else
                    {
                        that.setState({
                            show_area:true
                        })
                    }*/}

                    if (response.data.success) 
                    {
                        that.setState({alertAreaMessageVisible:false,area_data: response.data.result,loaderAreaStatus:true})
                    }
                    else
                    {
                        that.setState({
                            alertAreaMessageVisible: true,
                            alertAreaMessageColor: "danger",
                            alertAreaMessageText: "Areas not available for this floor.",
                            area_data: [],
                            loaderAreaStatus:true
                        });
                    }

                })
                .catch(function (error) 
                {
                    if (error.response) 
                    {
                    if(error.response.status===403)
                    {
                    browserHistory.push('/');
                    window.location.reload(); 
                    }
                    else
                    {
                    console.log(error);
                    }
                    }
                    else
                    {
                    console.log(error);
                    }
                });
        }
    }

    handleOpen_floor = (index, e) => {
        this.setState({
            floor_selected: index
        })
    }

    handleClick_area = (index, e) => {
        var that = this;
        let c = e.currentTarget.className;
        this.setState({
            area_selected: (c.indexOf('area_selected') >= 0) ? '' : index
        })
        var area_selected = (c.indexOf('area_selected') >= 0) ? '' : index;
        //console.log("area_selected: "+area_selected);
        var room_data= []; 
        if(area_selected != "")
        { 
            that.setState({
                loaderRoomStatus:false
            });
                axios
                .get('api/managed_rooms_by_area/'+index)
                .then(function (response) 
                {
                    console.log(response.data.result);
                    {/*var room_data_res = "";
                    if(response.data.result!="" && response.data.result!=null)
                    {
                        room_data_res = response.data.result;
                    }
                    else{
                        room_data_res = "";
                    }
                    that.setState({
                        room_data: room_data_res
                    });
                    room_data = room_data_res;
                    if(room_data=="")
                    {
                        that.setState({
                            show_room:false
                        })
                    }
                    else
                    {
                        that.setState({
                            show_room:true
                        })
                    }*/}
                    if (response.data.success) 
                    {
                        that.setState({alertRoomMessageVisible:false,room_data: response.data.result,loaderRoomStatus:true})
                    }
                    else
                    {
                        that.setState({
                            alertRoomMessageVisible: true,
                            alertRoomMessageColor: "danger",
                            alertRoomMessageText: "Rooms not available for this area.",
                            room_data: [],
                            loaderRoomStatus:true
                        });
                    }
                })
                .catch(function (error) 
                {
                    if (error.response) 
                    {
                    if(error.response.status===403)
                    {
                    browserHistory.push('/');
                    window.location.reload(); 
                    }
                    else
                    {
                    console.log(error);
                    }
                    }
                    else
                    {
                    console.log(error);
                    }
                });
            }
    }

    handleOpen_area = (index, e) => {
        this.setState({
            area_selected: index
        })
    }
    

    render () {
        var cusorPointer={cursor:'pointer'};
        var that = this;
        const AlertAreaMessage = () =>
        (
            <AlertMessageClass  alertMessageColor={this.state.alertAreaMessageColor}  alertMessageVisible={this.state.alertAreaMessageVisible}  onDismiss={ this.onDismiss}  alertMessageText={ this.state.alertAreaMessageText}
            />
        );
        const AlertRoomMessage = () =>
        (
            <AlertMessageClass  alertMessageColor={this.state.alertRoomMessageColor}  alertMessageVisible={this.state.alertRoomMessageVisible}  onDismiss={ this.onDismiss}  alertMessageText={ this.state.alertRoomMessageText}
            />
        );

        return (
            <span>
            <div>  
                <Card className="collapse_btn_main">
                        <Button color="primary" onClick={this.handleClick_fac.bind(this, this.props.fac_val)} className={(this.state.selected === this.props.fac_val) ? 'selected': ''} value={this.props.fac_val} ><i className={(that.state.selected === this.props.fac_val) ? 'fa fa-minus lg': 'fa fa-plus lg'} ></i> &nbsp; {this.props.fac_name}</Button>
                </Card>
                <Collapse isOpen={(this.state.selected === this.props.fac_val) ? true : false} onClick={this.handleOpen_fac.bind(this, this.props.fac_val)}>                
                {
                this
                .props
                .floor_data
                .map((floor_item, i) =>
                <span key = {floor_item.id}>
                        <Card className="collapse_btn">
                            <Button color="default"  onClick={this.handleClick_floor.bind(this, floor_item.id)} className={(this.state.floor_selected === floor_item.id) ? 'floor_selected': ''} value={floor_item.id} ><i className={(that.state.floor_selected === floor_item.id) ? 'fa fa-minus lg': 'fa fa-plus lg'}></i> &nbsp; {floor_item.name}</Button>
                        </Card>
                        {/*{(this.state.area_data=="" ? <span>The area list is empty</span>  : "Hello2")}*/}
                        <Collapse isOpen={(this.state.floor_selected === floor_item.id) ? true : false} onClick={this.handleOpen_floor.bind(this, floor_item.id)}>
                        <AlertAreaMessage/>
                        <LoaderClass visibleStatus={this.state.loaderAreaStatus}/>
                        {
                        this.state.area_data
                        .map((area_item, i2) =>
                        <span key = {area_item.id}>
                            <Card className="collapse_btn">
                                <Button color="default" onClick={this.handleClick_area.bind(this, area_item.id)} className={(this.state.area_selected === area_item.id) ? 'area_selected': ''} value={area_item.id} ><i className={(that.state.area_selected === area_item.id) ? 'fa fa-minus lg': 'fa fa-plus lg'}></i> &nbsp; {area_item.name}</Button>
                            </Card>
                            <Collapse isOpen={(this.state.area_selected === area_item.id) ? true : false} onClick={this.handleOpen_area.bind(this, area_item.id)}>
                            <AlertRoomMessage/>
                            <LoaderClass visibleStatus={this.state.loaderRoomStatus}/>
                            {
                            this.state.room_data
                            .map((room_item, i3) =>
                            <span key = {room_item.id}>
                                <Card className="collapse_btn">
                                    <Button color="default" value={room_item.id} >{room_item.name}</Button>
                                </Card>
                            </span>
                            )}
                            {/*<p className="text-center row alert alert-danger cust_alert" hidden={that.state.room_data==""?false:true} >
                                <span className="col-md-12">Rooms not available for this area.</span>
                            </p>*/}
                            </Collapse>
                        </span>
                        )}
                        {/*<p className="text-center row alert alert-danger cust_alert" hidden={that.state.area_data==""?false:true} >
                            <span className="col-md-12">Areas not available for this floor.</span>
                        </p>*/}
                        </Collapse>
                        
                </span>
                )}
                <p className="text-center row alert alert-danger cust_alert" hidden={this.props.floor_data==""?false:true}>
                    <span className="col-md-12">Floors not available for this facility.</span>
                </p>
                </Collapse>
            </div>
            </span>
        );
    }
}

export default FacilitySetupTable